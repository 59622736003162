/*
  Adapted from - https://github.com/alex3165/react-mapbox-gl
*/
import * as React from "react";
import * as MapboxGL from "mapbox-gl";
import { Props as FeatureProps } from "../feature/feature";
import diff from "../../util/diff";
import { AnyLayer } from "mapbox-gl";
import equal from "deep-equal";
import { Position } from "geojson";
import { ReactNode } from "react";

export type Paint =
    | MapboxGL.BackgroundPaint
    | MapboxGL.FillPaint
    | MapboxGL.FillExtrusionPaint
    | MapboxGL.SymbolPaint
    | MapboxGL.LinePaint
    | MapboxGL.RasterPaint
    | MapboxGL.CirclePaint;

export type Layout =
    | MapboxGL.BackgroundLayout
    | MapboxGL.FillLayout
    | MapboxGL.FillExtrusionLayout
    | MapboxGL.LineLayout
    | MapboxGL.SymbolLayout
    | MapboxGL.RasterLayout
    | MapboxGL.CircleLayout;

export interface ImageOptions {
  width?: number;
  height?: number;
  pixelRatio?: number;
}
export type ImageDefinition = [string, HTMLImageElement];
export type ImageDefinitionWithOptions = [string, HTMLImageElement, ImageOptions];

export type MouseEvent = (evt: unknown) => unknown;

export interface LayerEvents {
  onMouseMove?: MouseEvent;
  onMouseEnter?: MouseEvent;
  onMouseLeave?: MouseEvent;
  onMouseDown?: MouseEvent;
  onMouseUp?: MouseEvent;
  onClick?: MouseEvent;
  onTouchStart?: MouseEvent;
  onTouchEnd?: MouseEvent;
  onTouchCancel?: MouseEvent;
}

export interface LayerCommonProps {
  type?: "symbol" | "line" | "fill" | "circle" | "raster" | "fill-extrusion" | "background" | "heatmap";
  sourceId?: string;
  images?: ImageDefinition | ImageDefinition[] | ImageDefinitionWithOptions | ImageDefinitionWithOptions[];
  before?: string;
  paint?: Paint;
  layout?: Layout;
  metadata?: unknown;
  sourceLayer?: string;
  minZoom?: number;
  maxZoom?: number;
  geoJSONSourceOptions?: MapboxGL.GeoJSONSourceOptions;
  filter?: unknown[];
  children?: JSX.Element | JSX.Element[];
}

export interface OwnProps {
  id: string;
  draggedChildren?: JSX.Element[];
  map: MapboxGL.Map;
}

export type Props = LayerCommonProps & LayerEvents & OwnProps;

type EventToHandlersType = {
  [key in keyof MapboxGL.MapLayerEventType]?: keyof LayerEvents;
};

const eventToHandler: EventToHandlersType = {
  touchstart: "onTouchStart",
  touchend: "onTouchEnd",
  touchcancel: "onTouchCancel",
  mousemove: "onMouseMove",
  mouseenter: "onMouseEnter",
  mouseleave: "onMouseLeave",
  mousedown: "onMouseDown",
  mouseup: "onMouseUp",
  click: "onClick"
};

export default class MapBoxLayer extends React.Component<Props> {
  public static defaultProps = {
    type: "symbol" as const,
    layout: {},
    paint: {}
  };

  private readonly source: MapboxGL.GeoJSONSourceRaw;

  constructor(props: Props) {
    super(props);

    this.source = {
      type: "geojson",
      ...props.geoJSONSourceOptions,
      data: {
        type: "FeatureCollection",
        features: []
      }
    };
  }

  public shouldComponentUpdate(): boolean {
    return true;
  }

  private readonly geometry = (
    coordinates: Position | Position[] | Position[][] | Position[][][]
  ): GeoJSON.Geometry => {
    const { type } = this.props;

    switch (type) {
      case "symbol":
      case "circle":
        return {
          type: "Point",
          coordinates: coordinates as Position
        };

      case "fill":
        if (Array.isArray((coordinates as Position[][][])[0][0][0])) {
          return {
            type: "MultiPolygon",
            coordinates: coordinates as Position[][][]
          };
        }
        return {
          type: "Polygon",
          coordinates: coordinates as Position[][]
        };

      case "line":
        return {
          type: "LineString",
          coordinates: coordinates as Position[]
        };

      default:
        return {
          type: "Point",
          coordinates: coordinates as Position
        };
    }
  };

  private readonly makeFeature = (
    props: FeatureProps,
    id: number
  ): GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties> => ({
    type: "Feature",
    geometry: this.geometry(props.coordinates),
    properties: { ...props.properties, id }
  });

  private readonly initialize = (): void => {
    const { type, layout, paint, sourceId, before, images, id, metadata, sourceLayer, minZoom, maxZoom, filter }
            = this.props;
    const { map } = this.props;

    const layer: MapboxGL.Layer = {
      id,
      source: sourceId ?? id,
      type: type ?? "unknown",
      layout,
      paint,
      metadata
    };

    if (sourceLayer) {
      layer["source-layer"] = sourceLayer;
    }

    if (minZoom) {
      layer.minzoom = minZoom;
    }

    if (maxZoom) {
      layer.maxzoom = maxZoom;
    }

    if (filter) {
      layer.filter = filter;
    }

    if (images) {
      const normalizedImages = !Array.isArray(images[0]) ? [images] : images;
      (normalizedImages as ImageDefinitionWithOptions[])
        .filter(image => !map.hasImage(image[0]))
        .forEach(image => {
          map.addImage(image[0], image[1], image[2]);
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!sourceId && !map.getSource(id)) {
      map.addSource(id, this.source);
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!map.getLayer(id)) {
      map.addLayer(layer as AnyLayer, before);
    }

    (Object.entries(eventToHandler) as [keyof EventToHandlersType, keyof LayerEvents][]).forEach(
      ([event, propName]) => {
        // eslint-disable-next-line react/destructuring-assignment
        const handler = this.props[propName];
        if (handler) {
          map.on(event, id, handler);
        }
      }
    );
  };

  private readonly onStyleDataChange = (): void => {
    // if the style of the map has been updated and we don't have layer anymore,
    // add it back to the map and force re-rendering to redraw it

    const { map, id } = this.props;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!map.getLayer(id)) {
      this.initialize();
      this.forceUpdate();
    }
  };

  public componentDidMount(): void {
    const { map } = this.props;

    this.initialize();

    map.on("styledata", this.onStyleDataChange);
  }

  public componentWillUnmount(): void {
    const { map, images, id, sourceId } = this.props;

    map.off("styledata", this.onStyleDataChange);

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!map || !map.isStyleLoaded()) {
      return;
    }

    (Object.entries(eventToHandler) as [keyof EventToHandlersType, keyof LayerEvents][]).forEach(
      ([event, propName]) => {
        // eslint-disable-next-line react/destructuring-assignment
        const handler = this.props[propName];
        if (handler) {
          map.off(event, id, handler);
        }
      }
    );

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (map.getLayer(id)) {
      map.removeLayer(id);
    }
    // if pointing to an existing source, don't remove
    // as other layers may be dependent upon it
    if (!sourceId) {
      map.removeSource(id);
    }

    if (images) {
      const normalizedImages = !Array.isArray(images[0]) ? [images] : images;
      (normalizedImages as ImageDefinitionWithOptions[])
        .map(([key]) => key)
        .forEach(map.removeImage.bind(map));
    }
  }

  public componentDidUpdate(prevProps: Props): void {

    const { paint, layout, before, filter, id, minZoom, maxZoom, type, map } = prevProps;
    const props = this.props;

    if (!equal(props.paint, paint) && equal(props.type, type)) {
      const paintDiff = diff(paint as Record<string, unknown>, props.paint as Record<string, unknown>);

      Object.keys(paintDiff).forEach(key => {
        map.setPaintProperty(id, key, paintDiff[key]);
      });
    }

    if (!equal(props.layout, layout)) {
      const layoutDiff = diff(layout as Record<string, unknown>, props.layout as Record<string, unknown>);

      Object.keys(layoutDiff).forEach(key => {
        map.setLayoutProperty(id, key, layoutDiff[key]);
      });
    }

    if (!equal(props.filter, filter)) {
      map.setFilter(id, props.filter);
    }

    if (before !== props.before) {
      map.moveLayer(id, props.before);
    }

    if (minZoom !== props.minZoom || maxZoom !== props.maxZoom) {
      // TODO: Fix when PR https://github.com/DefinitelyTyped/DefinitelyTyped/pull/22036 is merged
      const fallBackMin = 0;
      const fallBackMax = 30;
      map.setLayerZoomRange(id, props.minZoom ?? fallBackMin, props.maxZoom ?? fallBackMax);
    }

    (Object.entries(eventToHandler) as [keyof EventToHandlersType, keyof LayerEvents][]).forEach(
      ([event, propName]) => {
        // eslint-disable-next-line react/destructuring-assignment
        const oldHandler = this.props[propName];
        const newHandler = props[propName];

        if (oldHandler !== newHandler) {
          if (oldHandler) {
            map.off(event, id, oldHandler);
          }

          if (newHandler) {
            map.on(event, id, newHandler);
          }
        }
      }
    );
  }

  public getChildren = (): JSX.Element[] => {
    const { children } = this.props;

    if (!children) {
      return [];
    }

    if (Array.isArray(children)) {
      return (children as JSX.Element[][]).reduce((arr, next) => arr.concat(next), [] as JSX.Element[]);
    }

    return [children] as JSX.Element[];
  };

  public render(): ReactNode {
    const { map, sourceId, draggedChildren, id } = this.props;
    let children = this.getChildren();

    if (draggedChildren) {
      const draggableChildrenIds = draggedChildren.map(child => child.key);
      children = children.map(child => {
        const indexChildren = draggableChildrenIds.indexOf(child.key);
        if (indexChildren !== -1) {
          return draggedChildren[indexChildren];
        }
        return child;
      });
    }

    const features = (children as React.ReactElement<FeatureProps>[] | undefined)
      ?.map(({ props }, id) => this.makeFeature(props, id))
      .filter(Boolean) ?? [];

    const source = map.getSource(sourceId ?? id) as MapboxGL.GeoJSONSource;

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (source && !sourceId && source.setData) {
      source.setData({
        type: "FeatureCollection",
        features
      });
    }

    return null;
  }
}
