import React, { ReactElement, useCallback } from "react";
import styles from "./routeSummary.module.scss";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as ShareIcon } from "../../../assets/share_link.svg";
import { RootState } from "../../../app/store";
import { UrlProcessing } from "../../../services/UrlProcessing";
import copy from "clipboard-copy";
import { openNotification, setUrlCopyOpen } from "../../../store/map/mapSlice";
import humanizeDuration from "humanize-duration";
import { LINK_COPIED, LINK_SHARE } from "../../../constants";

interface routeSummaryWayFindingProps {
  showShareIcon?: boolean;
  routeSummaryClassName?: string;
}

export default function RouteSummaryWayFinding(props: routeSummaryWayFindingProps): ReactElement {
  const dispatch = useDispatch();
  const { showShareIcon=true, routeSummaryClassName } = props;
  const origin = useSelector((state: RootState) => state.wayFinding.origin);
  const destination = useSelector((state: RootState) => state.wayFinding.destination);
  const campusId = useSelector((state: RootState) => state.map.campusId);
  const currentRoute = useSelector((state: RootState) => state.wayFinding.currentRoute);
  const toRoute = 1000;
  function routeSummary(): ReactElement | false {
    return (
      currentRoute !== undefined && (
        <>
          {currentRoute.metrics.distanceCyclingMeters > 0 && (
            <div className={classnames(styles.body1, styles.fs16)}>
              {currentRoute.metrics.distanceCyclingMeters}{"m Cycle"}
            </div>
          )}
          {currentRoute.metrics.distanceDrivingMeters > 0 && (
            <div className={classnames(styles.body1, styles.fs16)}>
              {currentRoute.metrics.distanceDrivingMeters / toRoute}{"km Drive"}
            </div>
          )}
          {currentRoute.metrics.distanceWalkingMeters > 0 && (
            <div className={classnames(styles.body1, styles.fs16)}>
              {currentRoute.metrics.distanceWalkingMeters}{"m Walk"}
            </div>
          )}
          <div className={styles.body3}>
            {humanizeDuration(Math.round(currentRoute.metrics.durationEstimateSeconds) * toRoute)}
          </div>
        </>
      )
    );
  }

  const handleShareClicked = useCallback(() => {
    if (origin && destination && campusId) {
      const shareUrl = UrlProcessing.getRouteShareUrl(window.location, origin, destination, campusId);
      copy(shareUrl)
        .then(() => {
          dispatch(setUrlCopyOpen(true));
          dispatch(openNotification(LINK_COPIED));
        })
        .catch((e) => {
          // TODO throw an error
          console.error("clipboard copy failed", e);
        });
    }
  }, [dispatch, campusId, origin, destination]);

  function shareIconButton(): ReactElement | false {
    return showShareIcon && (
      <button
        className={styles.shareButton}
        onClick={handleShareClicked}
        aria-label={LINK_SHARE}
      >
        <span className={styles.shareIcon}>
          <ShareIcon />
        </span>
      </button>
    );
  }

  return (
    <div className={classnames(styles.routeWrap, routeSummaryClassName)}>
      <div>{routeSummary()}</div>
      {shareIconButton()}
    </div>
  );
}
