import { PathMetrics, RouteGeoJson } from "MazeMapTypes";
import { GeoJsonUtil } from "./GeoJsonUtil";
import { LatLng } from "../app/models/LatLng";
import { Feature, GeoJsonProperties } from "geojson";
import { RouteDetail, RouteDetailStep } from "../types/UQMapsTypes";

const minimumRouteAngleThreshold = 45;

export class RouteUtil {
  public static toRouteDetail(routeGeoJson: RouteGeoJson): RouteDetail {
    const metrics = routeGeoJson.properties.pathMetrics as PathMetrics;
    const firstFeature = routeGeoJson.features[0] as Feature | undefined;
    return {
      route: routeGeoJson,
      mode: firstFeature?.properties?.transitMode,
      metrics,
      instructions: [],
      steps: routeGeoJson.features
        .map((item: Feature): Feature[] => {
          if (item.geometry.type === "LineString") {
            return RouteUtil.toMultipleRouteLines(item.geometry, minimumRouteAngleThreshold).map(line => {
              return {
                geometry: line,
                type: "Feature",
                properties: { ...item.properties, m: GeoJsonUtil.getLineDistance(line) }
              };
            });
          }
          return [item];
        })
        .flat(1)
        .map((item: Feature): RouteDetailStep => {
          switch (item.geometry.type) {
            case "LineString":
              return {
                step: this.toStepLabel(item.properties),
                geometry: item.geometry,
                timeSeconds: item.properties?.timeEstimateSeconds || 0,
                routePoint: item.properties?.travelMode,
                zLevel: item.properties?.z
              };
              // m: 17.82596822083213
              // target: 1900898
              // timeEstimateSeconds: 22.638979640456807
              // transitInfo: null
              // travelMode: "PEDESTRIAN"
              // travelType: "FOOT"
            case "Point":
              return {
                step: this.toRoutePointStepLabel(item.properties),
                geometry: item.geometry,
                timeSeconds: item.properties?.timeEstimateSeconds || 0,
                routePoint: item.properties?.routepoint,
                zLevel: item.properties?.z
              };
            default:
              console.log(item.geometry.type);
              return {
                step: "unknown",
                geometry: item.geometry,
                timeSeconds: 0,
                routePoint: "item.geometry.type",
                zLevel: 0
              };
          }
        })
    };
  }

  /**
     * Converts a line string into multiple lines if the angles of each segment are above the threshold
     * @param line The original path
     * @param angleThreshold the minimum angle to be considered a new line segment
     */
  public static toMultipleRouteLines(line: GeoJSON.LineString, angleThreshold: number): GeoJSON.LineString[] {
    const allPoints = line.coordinates.map(item => GeoJsonUtil.toLatLng(GeoJsonUtil.toLngLat(item)));

    const allSegments = RouteUtil.createLineSegments(allPoints);

    let seg1 = allSegments[0];
    let currentLine: GeoJSON.LineString = {
      type: "LineString",
      coordinates: [GeoJsonUtil.toPosition(seg1.a), GeoJsonUtil.toPosition(seg1.b)]
    };
    const lines: GeoJSON.LineString[] = [];
    for (let index = 1; index < allSegments.length; index++) {
      const seg2 = allSegments[index];
      const diff = GeoJsonUtil.getDirectionChangeAbsAngle(seg1.bearing, seg2.bearing);
      if (diff > angleThreshold) {
        lines.push(currentLine);
        currentLine = {
          type: "LineString",
          coordinates: [GeoJsonUtil.toPosition(seg2.a), GeoJsonUtil.toPosition(seg2.b)]
        };
      } else {
        currentLine.coordinates.push(GeoJsonUtil.toPosition(seg2.a), GeoJsonUtil.toPosition(seg2.b));
      }
      seg1 = seg2;
    }
    lines.push(currentLine);
    return lines;
  }

  private static createLineSegments(allPoints: LatLng[]): { a: LatLng; b: LatLng; bearing: number }[] {
    let pointA = allPoints[0];
    const allSegments: { a: LatLng; b: LatLng; bearing: number }[] = [];
    for (let index = 1; index < allPoints.length; index++) {
      const pointB = allPoints[index];
      const nextBearing = GeoJsonUtil.getBearing(pointA, pointB);
      allSegments.push({ a: pointA, b: pointB, bearing: nextBearing });
      pointA = pointB;
    }
    return allSegments;
  }

  private static toStepLabel(properties: GeoJsonProperties): string {
    switch (properties?.travelMode) {
      case "PEDESTRIAN":
        return `Walk ${Math.round(properties.m)}m`;
      case "DRIVE":
        return `Drive ${Math.round(properties.m)}m`;
      case "TRANSIT":
        return `Ride transit ${Math.round(properties.m)}m`;
      default:
        return `Go ${Math.round(properties?.m ?? 0)}m`;
    }
  }

  private static toRoutePointStepLabel(properties: GeoJsonProperties): string {
    switch (properties?.routepoint) {
      case "BUILDING_EXIT":
        return "Exit Building";
      case "BUILDING_ENTER":
        return "Enter Building";
      case "STAIR-UP":
      case "STAIR-DOWN":
      case "STAIR":
        return "Stairs";
      case "ELEVATOR-DOWN":
        return `Elevator down to level ${properties.zLevel}`;
      case "ELEVATOR-UP":
        return `Elevator up to level ${properties.zLevel}`;
      default:
        return "Unknown";
    }
  }
}
