export const API_URL = process.env.REACT_APP_API_URL;
export const STAGE = process.env.REACT_APP_STAGE;
export const VERSION = process.env.REACT_APP_VERSION;
export const LIVE_PARKING = process.env.REACT_APP_LIVE_PARKING;
export const LIVE_BUILDING = process.env.REACT_APP_LIVE_BUILDING;
export const LIVE_COMPUTERS = process.env.REACT_APP_LIVE_COMPUTERS;
export const ALT_LIVE_FILL = process.env.REACT_APP_ALT_LIVE_FILL;
export const SAFETY_PATHS = process.env.REACT_APP_SAFETY_PATHS;
export const BUSYNESS = process.env.REACT_APP_BUSYNESS;
export const ALT_BUSYNESS = process.env.REACT_APP_ALT_BUSYNESS;
export const BUSYNESS_3D = process.env.REACT_APP_BUSYNESS_3D;
export const FLOOR_SELEC_NEW_UI = process.env.REACT_APP_FLOOR_SELEC_NEW_UI;
export const FEEDBACK_FORM = process.env.REACT_APP_FEEDBACK_FORM;
export const ABOUT_DIALOG = process.env.REACT_APP_ABOUT_DIALOG;
export const VERSION_LATEST = process.env.REACT_APP_VERSION_LATEST;

// You can add more mappings from your dotenv file here
// Must be prefixed with REACT_APP_
