import React, {useState, useCallback, useEffect, useRef} from 'react';
import classNames from 'classnames';
require('./_styles.scss');

export interface SiteHeaderProps {
    /**
     * Site title
     * Move to site header component
     */
    siteTitle?: string;
    /**
     * Site URL
     * Move to site header component
     * Used for UQ search function (protocol and domain, e.g. https://future-students.uq.edu.au)
     */
    siteUrl?: string;
    /**
     * Classes
     */
    className?: string
}

export function SiteHeader ({className, siteTitle, siteUrl}: SiteHeaderProps): JSX.Element {
    return (
        <></>
    );
}