import { AnalyticsOptions } from "./AnalyticsOptions";

/* eslint-disable camelcase */

declare let window: {
  dataLayer: {
    event: string;
    click_category: string;
    click_action: string;
    click_label: string;
  }[];
};

export interface AnalyticsHandler {
  handleEventAsync(options: AnalyticsOptions): Promise<boolean>;
  handleEvent(options: AnalyticsOptions): boolean;
}

class AnalyticsHandlerImpl implements AnalyticsHandler {
  public async handleEventAsync(options: AnalyticsOptions): Promise<boolean> {
    return new Promise(function (resolve, reject) {

      try {
        window.dataLayer.push({
          event: options.event,
          click_category: options.clickCategory,
          click_action: options.clickAction,
          click_label: options.clickLabel
        });

        resolve(true);
      } catch (error) {
        // TODO: do some logging here.
        reject(false);
      }
    });
  }

  public handleEvent(options: AnalyticsOptions): boolean {
    console.log("### handling GA Event: ", options);

    try {
      window.dataLayer.push({
        event: options.event,
        click_category: options.clickCategory,
        click_action: options.clickAction,
        click_label: options.clickLabel
      });

      return true;
    } catch (error) {
      // TODO: do some logging here.
      return false;
    }
  }
}

export const AnalyticsHandler = new AnalyticsHandlerImpl();
