export interface AnalyticsOptions {
  event: string;
  clickCategory: string;
  clickAction: string;
  clickLabel: string;
}

export class AnalyticsOptionsImpl implements AnalyticsOptions {
  private readonly _event: string;

  private readonly _clickCategory: string;

  private readonly _clickAction: string;

  private readonly _clickLabel: string;

  constructor(event: string, clickCategory: string, clickAction: string, clickLabel: string) {
    this._event = event;
    this._clickCategory = clickCategory;
    this._clickAction = clickAction;
    this._clickLabel = clickLabel;
  }

  public get event(): string {
    return this._event;
  }

  public get clickCategory(): string {
    return this._clickCategory;
  }

  public get clickAction(): string {
    return this._clickAction;
  }

  public get clickLabel(): string {
    return this._clickLabel;
  }
}
