import { MapEx } from "MazeMapTypes";
import React, { ReactElement } from "react";
import MapBoxSource from "../source/MapBoxSource";
import MapBoxLayer from "./MapBoxLayer";
import { GeoJSONSourceRaw } from "mapbox-gl";

/* eslint-disable @typescript-eslint/no-magic-numbers  */

const FLOOR_BASE_OFFSET = 0;
const FLOOR_HEIGHT = 5;

interface PolygonHeatmapLayerProps {
  map: MapEx;
  geojson: GeoJSON.FeatureCollection<GeoJSON.Polygon, { heatValue: number; z: number }>;
  layerName: string;
  visible: boolean;
  minZoom?: number;
  maxZoom?: number;
  mapzLevel: number;
  use3d: boolean;
  maxValue: number;
}

export class PolygonHeatmapLayer extends React.Component<PolygonHeatmapLayerProps> {
  private readonly dataSource: GeoJSONSourceRaw;

  private readonly heatmapFeatureLayerName: string;

  constructor(props: PolygonHeatmapLayerProps) {
    super(props);

    this.dataSource = {
      type: "geojson",
      data: props.geojson
    };
    this.heatmapFeatureLayerName = `${props.layerName}features`;
  }

  public shouldComponentUpdate(): boolean {
    return true;
  }

  private updateDataSource(): void {
    const { geojson } = this.props;

    this.dataSource.data = {
      type: "FeatureCollection",
      features: geojson.features.map(feature => {
        return {
          ...feature,
          properties: {
            ...feature.properties,
            height: FLOOR_BASE_OFFSET + (FLOOR_HEIGHT * (feature.properties.z + 1)),
            // eslint-disable-next-line camelcase
            base_height: FLOOR_BASE_OFFSET + (FLOOR_HEIGHT * feature.properties.z)
          }
        };
      })
    };
  }

  public componentWillUnmount(): void {
    this.clearFeatures();
  }

  private clearFeatures(): void {
    this.dataSource.data = { type: "FeatureCollection", features: [] };
  }

  public componentDidUpdate(): void {
    this.updateLayerVisibility();
  }

  public render(): ReactElement {
    const { layerName, map, visible, minZoom, maxZoom, mapzLevel, use3d, maxValue } = this.props;
    const src = `${layerName}src`;
    const visiblilityValue = visible ? "visible" : "none";

    if (use3d && visible) {
      map.setPitch(60);
    }

    this.updateDataSource();
    const interpolatedColour: mapboxgl.Expression = [
      "interpolate",
      ["linear"],
      ["get", "heatValue"],
      0,
      "#66bb6a",
      maxValue * 0.2,
      "#ffee58",
      maxValue * 0.5,
      "#ffa726",
      maxValue,
      "#ef5350"
    ];

    const paint = use3d
      ? {
        "fill-extrusion-color": ["case", ["==", ["get", "z"], mapzLevel], interpolatedColour, "#FFF"],
        "fill-extrusion-height": ["get", "height"],
        "fill-extrusion-base": ["get", "base_height"],
        "fill-extrusion-opacity": 0.8
      }
      : {
        "fill-color": interpolatedColour,
        "fill-opacity": 0.4
      };

    return (
      <MapBoxSource
        map={map}
        id={src}
        geoJsonSource={this.dataSource}
        render={(): ReactElement => <>
          <MapBoxLayer
            map={map}
            id={layerName}
            sourceId={src}
            type={use3d ? "fill-extrusion" : "fill"}
            layout={{ visibility: visiblilityValue }}
            paint={paint}
            minZoom={minZoom}
            maxZoom={maxZoom}
            filter={["any", use3d, ["==", ["get", "z"], mapzLevel]]}
          />
        </>}
      />
    );
  }

  private updateLayerVisibility(): void {
    try {
      const { map, visible, layerName } = this.props;
      const visiblilityValue = visible ? "visible" : "none";
      map.setLayoutProperty(layerName, "visibility", visiblilityValue);
    } catch (ex) {
      console.log(ex);
    }
  }
}
