import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  ABOUT_DIALOG,
  ALT_BUSYNESS,
  ALT_LIVE_FILL,
  BUSYNESS,
  BUSYNESS_3D,
  FEEDBACK_FORM,
  FLOOR_SELEC_NEW_UI,
  LIVE_BUILDING,
  LIVE_COMPUTERS,
  LIVE_PARKING,
  SAFETY_PATHS
} from "../../env";

export const initialState = {
  useMobileLayout: true,
  useLandscapeLayout: true,
  siteFeatures: {
    liveParking: LIVE_PARKING === "true",
    liveBuilding: LIVE_BUILDING === "false",
    liveComputers: LIVE_COMPUTERS === "true",
    alternateLiveMarkerFill: ALT_LIVE_FILL === "true",
    safetyPaths: SAFETY_PATHS === "true",
    busyness: BUSYNESS === "true",
    alternateBusyness: ALT_BUSYNESS === "true",
    busyness3D: BUSYNESS_3D === "true",
    floorSelectorNewUI: FLOOR_SELEC_NEW_UI === "true",
    feedbackForm: FEEDBACK_FORM === "true",
    aboutDialog: ABOUT_DIALOG === "true",

    // sets various defaults related to embedded map status.
    embed: false,
    // whether to prevent selecting another point.
    fixed: false,
    // whether the search bar is visible.
    search: true,
    // whether the sidebar can be shown or hidden.
    sidebar: true,
    // whether controls are drawn on the map (layers, floor, campus).
    controls: true,
    // whether wayfinding is enabled.
    wayfinding: true,
    // whether to show UQ header.
    header: true,
    // whether to show footer with about links and feedback.
    footer: true
  }
};

const embedSettings = {
  search: false,
  fixed: true,
  sidebar: false,
  controls: false,
  wayfinding: false,
  header: false,
  footer: false
};

export type SettingsState = typeof initialState;
export type SiteFeatures = typeof initialState.siteFeatures;
export type SiteFeatureKey = keyof SiteFeatures;

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setUseMobile: (state, action: PayloadAction<boolean>) => {
      state.useMobileLayout = action.payload;
    },
    setUseLandscape: (state, action: PayloadAction<boolean>) => {
      state.useLandscapeLayout = action.payload;
    },
    setSettings: (state, action: PayloadAction<Partial<SiteFeatures>>) => {
      let payload = action.payload;
      if (payload.embed) {
        payload = { ...embedSettings, ...payload };
      }
      state.siteFeatures = { ...state.siteFeatures, ...payload };
    },
    setSetting: (state, action: PayloadAction<{ key: SiteFeatureKey; value: boolean }>) => {
      const { key, value } = action.payload;
      state.siteFeatures[key] = value;
    }
  }
});

export const { setUseMobile, setUseLandscape, setSetting, setSettings } = settingsSlice.actions;
// export const selectFeatures = (state: RootState): typeof state.settings.siteFeatures => state.settings.siteFeatures;
export const selectUseMobileLayout = (state: RootState): boolean => state.settings.useMobileLayout;
export default settingsSlice.reducer;
