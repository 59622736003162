import { MapEx } from "MazeMapTypes";
import React, { ReactElement } from "react";
import MapBoxSource from "../source/MapBoxSource";
import MapBoxLayer from "./MapBoxLayer";
import { GeoJSONSourceRaw } from "mapbox-gl";
import colours from "../../colours";
import { routeFeatureIconPrefix } from "../wayFinding/routeFeatureIcons";
import { Feature } from "geojson";

interface RouteLayerProps {
  map: MapEx;
  geojson: GeoJSON.FeatureCollection;
  layerName: string;
  visible: boolean;
  mapzLevel: number;
}

export class RouteLayer extends React.Component<RouteLayerProps> {
  private readonly dataSource: GeoJSONSourceRaw;

  private readonly routeFeatureLayerName: string;

  private readonly routeFeaturePinContainerLayerName: string;

  private readonly routeStartLayerName: string;

  private readonly routeEndLayerName: string;

  private readonly routeOtherFloorLayerName: string;

  constructor(props: RouteLayerProps) {
    super(props);

    const { geojson, layerName } = this.props;

    this.dataSource = {
      type: "geojson",
      data: geojson
    };
    this.routeFeatureLayerName = `${layerName}features`;
    this.routeFeaturePinContainerLayerName = `${layerName}pin-container`;
    this.routeStartLayerName = `${layerName}start`;
    this.routeEndLayerName = `${layerName}end`;
    this.routeOtherFloorLayerName = `${layerName}otherfloor`;
  }

  public shouldComponentUpdate(): boolean {
    return true;
  }

  private updateDataSource(): void {
    const { geojson } = this.props;
    if (geojson.features.length > 0) {
      const first = geojson.features[0] as Feature | undefined;
      const last = geojson.features[geojson.features.length - 1] as Feature | undefined;
      const syntheticStartPt: GeoJSON.Feature = {
        type: "Feature",
        properties: { ...first?.properties, _isFirst: true },
        geometry: { type: "Point", coordinates: (first?.geometry as GeoJSON.LineString).coordinates[0] }
      };
      const lastGeometry = last?.geometry as GeoJSON.LineString | undefined;
      const syntheticEndPt: GeoJSON.Feature = {
        type: "Feature",
        properties: { ...last?.properties, _isLast: true },
        geometry: {
          type: "Point",
          coordinates: lastGeometry?.coordinates[lastGeometry.coordinates.length - 1] ?? []
        }
      };
      this.dataSource.data = { ...geojson, features: [syntheticStartPt, ...geojson.features, syntheticEndPt] };
    } else {
      this.dataSource.data = geojson;
    }
  }

  public componentWillUnmount(): void {
    this.clearFeatures();
  }

  private clearFeatures(): void {
    this.dataSource.data = { type: "FeatureCollection", features: [] };
  }

  public componentDidUpdate(): void {
    this.updateLayerVisibility();
  }

  public render(): ReactElement {
    const { layerName, map, visible, mapzLevel } = this.props;
    const src = `${layerName}src`;
    const visiblilityValue = visible ? "visible" : "none";
    this.updateDataSource();
    const routePointIconSize = 0.85;

    return (
      <>
        <MapBoxSource map={map} id={src} geoJsonSource={this.dataSource} />
        <MapBoxLayer
          map={map}
          id={layerName}
          sourceId={src}
          type={"line"}
          paint={{ "line-color": colours.secondary, "line-width": 3 }}
          layout={{ visibility: visiblilityValue }}
          filter={[
            "case",
            [
              "all",
              ["==", ["get", "buildingId"], null],
              ["any", ["==", ["get", "z"], null], ["==", ["get", "z"], -1], ["==", ["get", "z"], 0]]
            ],
            true,
            ["==", mapzLevel, ["get", "z"]],
            true,
            false
          ]}
        />
        <MapBoxLayer
          map={map}
          id={this.routeOtherFloorLayerName}
          sourceId={src}
          type={"line"}
          paint={{ "line-color": colours.secondary, "line-width": 3, "line-dasharray": [1, 1] }}
          layout={{ visibility: visiblilityValue }}
          filter={[
            "case",
            [
              "all",
              ["==", ["get", "buildingId"], null],
              ["any", ["==", ["get", "z"], null], ["==", ["get", "z"], -1], ["==", ["get", "z"], 0]]
            ],
            false,
            ["!=", ["get", "z"], mapzLevel],
            true,
            false
          ]}
        />
        <MapBoxLayer
          map={map}
          id={this.routeFeatureLayerName}
          sourceId={src}
          type={"symbol"}
          layout={{
            visibility: visiblilityValue,
            "icon-image": ["concat", routeFeatureIconPrefix, ["get", "routepoint"]],
            "icon-size": routePointIconSize,
            "icon-allow-overlap": false,
            "icon-anchor": "bottom",
            "icon-padding": 1,
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            "icon-offset": [0, -10]
          }}
          filter={["has", "routepoint"]}
        />
        <MapBoxLayer
          map={map}
          id={this.routeFeaturePinContainerLayerName}
          sourceId={src}
          type={"symbol"}
          layout={{
            visibility: visiblilityValue,
            "icon-image": ["concat", routeFeatureIconPrefix, "PIN-CONTAINER"],
            "icon-size": 1,
            "icon-allow-overlap": true,
            "icon-anchor": "bottom"
          }}
          filter={["has", "routepoint"]}
          before={this.routeFeatureLayerName}
        />
        <MapBoxLayer
          map={map}
          id={this.routeStartLayerName}
          sourceId={src}
          type={"circle"}
          paint={{
            "circle-color": colours.secondary,
            "circle-radius": 16,
            "circle-stroke-width": 0
          }}
          filter={["has", "_isFirst"]}
          layout={{ visibility: visiblilityValue }}
        />
        <MapBoxLayer
          map={map}
          id={this.routeEndLayerName}
          sourceId={src}
          type={"symbol"}
          layout={{
            visibility: visiblilityValue,
            "icon-image": ["concat", routeFeatureIconPrefix, "ROUTE-END"],
            "icon-size": 1,
            "icon-allow-overlap": true,
            "icon-anchor": "bottom"
          }}
          filter={["has", "_isLast"]}
        />
      </>
    );
  }

  private updateLayerVisibility(): void {
    const { map, visible, layerName } = this.props;
    const visiblilityValue = visible ? "visible" : "none";
    map.setLayoutProperty(layerName, "visibility", visiblilityValue);
    map.setLayoutProperty(this.routeFeatureLayerName, "visibility", visiblilityValue);
    map.setLayoutProperty(this.routeFeaturePinContainerLayerName, "visibility", visiblilityValue);
    map.setLayoutProperty(this.routeStartLayerName, "visibility", visiblilityValue);
    map.setLayoutProperty(this.routeEndLayerName, "visibility", visiblilityValue);
    map.setLayoutProperty(this.routeOtherFloorLayerName, "visibility", visiblilityValue);
  }
}
