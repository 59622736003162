import { MapEx } from "MazeMapTypes";
import React, { ReactElement, useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../app/store";
import MapBoxSource from "../source/MapBoxSource";
import MapBoxLayer from "../layers/MapBoxLayer";
import MapBoxImage from "../image/MapBoxImage";
import { gradientMarker } from "../image/gradientMarker";
import { GeoJsonUtil } from "../../services/GeoJsonUtil";
import { Feature } from "geojson";
import { LiveData } from "../../app/models/LiveData";
import { MixedPOIList, PointOfInterest, SimpleCategoryPOI } from "../../types/UQMapsTypes";
import { GeoJSONSourceRaw } from "mapbox-gl";

const MARKER_SIZE = 40;

interface LiveDataLayerProps extends PropsFromRedux {
  name: string;
  map: MapEx;
  items: MixedPOIList;
  before: string;
}


function createFeature(sourceFeature: SimpleCategoryPOI | PointOfInterest,
  liveData: Record<string, LiveData>): Feature | null {
  const id = sourceFeature.properties.id;
  const ldata = liveData[id] as LiveData | undefined;
  const hasLiveData = Boolean(ldata);
  const iconKey = hasLiveData ? `live_${id}` : undefined;

  const maxCircleRadius = 22;
  const circleRadius = ldata?.max && ldata.max > 0 ? maxCircleRadius : 0;

  return {
    id,
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: GeoJsonUtil.getGeoCentre(sourceFeature.geometry)
    },
    properties: { id, icon: iconKey, circleRadius }
  };
}


export function LiveDataLayer(props: LiveDataLayerProps): ReactElement {
  const { name, map, items, before } = props;
  const { liveData } = props;

  const src = `${name}_LiveDataSrc`;
  const gradientArcLayer = `${name}_livedata`;
  const backgroundCircleLayer = `${name}_livedatabg`;

  const collection = useMemo(() => {
    const features = items
      .map(item => createFeature(item, liveData))
      .filter((x): x is Feature => x !== null);

    return {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features
      },
      cluster: true,
      maxzoom: 21,
      clusterMaxZoom: 19,
      clusterRadius: 70
    } as GeoJSONSourceRaw;
  }, [items, liveData]);

  return <>
    <MapBoxSource
      map={map}
      id={src}
      geoJsonSource={collection}
      render={(): ReactElement => <>
        <MapBoxLayer
          map={map}
          id={gradientArcLayer}
          sourceId={src}
          type={"symbol"}
          layout={{
            "icon-image": ["get", "icon"],
            "icon-size": 1,
            "icon-allow-overlap": true
          }}
          before={before}
          filter={["all", ["!", ["has", "point_count"]], ["has", "icon"]]}
        />
        <MapBoxLayer
          map={map}
          id={backgroundCircleLayer}
          sourceId={src}
          type={"circle"}
          paint={{
            "circle-color": "#FFF",
            "circle-radius": ["get", "circleRadius"],
            "circle-stroke-width": 0
          }}
          before={gradientArcLayer}
          filter={["all", ["!", ["has", "point_count"]], ["has", "icon"]]}
        />
        {items
          .filter(item => Boolean(liveData[item.properties.id]))
          .map(feature => {
            const id = feature.properties.id;
            const dataToShow = liveData[id];

            const gradient = gradientMarker(
              map,
              MARKER_SIZE,
              dataToShow
              // alternateLiveMarkerFill,
              // feature.properties?.poiId
            );
            return (
              <MapBoxImage
                key={id}
                map={map}
                data={gradient}
                id={`live_${id}`}
              />
            );
          })}
      </>}
    />
  </>;
}

const mapStateToProps = (
  state: RootState
): {
  liveData: { [p: string]: LiveData };
} => {
  return {
    liveData: state.live.liveData
  };
};


const connector = connect(mapStateToProps);

export default connector(LiveDataLayer);

type PropsFromRedux = ConnectedProps<typeof connector>;
