import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MapsApp from "./MapsApp";
import { Header } from "@ads-ui/react-header";
import styles from "./App.module.scss";
import { setUseMobile, setUseLandscape } from "./features/settings/settingsSlice";
import { RootState } from "./app/store";
import { Category } from "./types/UQMapsTypes";

function App(): JSX.Element {
  const dispatch = useDispatch();
  const header = useSelector((state: RootState) => state.settings.siteFeatures.header);
  const isLandscape = useSelector((state: RootState) => state.settings.useLandscapeLayout);
  const campusId = useSelector((state: RootState) => state.map.campusId);
  const campuses = useSelector((state: RootState) => state.map.campuses);
  const searchTerm = useSelector((state: RootState) => state.search.searchTerm);
  const isShowingCategoryResults = useSelector((state: RootState) => state.search.isShowingCategoryResults);
  const currentLocation = campuses.find(campus => campusId === campus.properties.id);
  const currentCampusName = currentLocation?.properties.name ?? "";
  const itemDetail = useSelector((state: RootState) => state.map.itemDetail);
  const itemCategories = itemDetail?.properties?.categories;
  const itemTitle = itemDetail?.title ?? "";

  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  React.useEffect(() => {
    function handleResize(): void {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
    }

    window.addEventListener("resize", handleResize);
    return (): void => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const landscape = 760;
  const tablet = 959;
  useEffect(() => {
    if (dimensions.width < tablet) {
      dispatch(setUseMobile(true));
    } else {
      dispatch(setUseMobile(false));
    }
    if (dimensions.width < landscape) {
      dispatch(setUseLandscape(true));
    } else {
      dispatch(setUseLandscape(false));
    }
  }, [dispatch, dimensions.width]);

  // Get dynamic document title depending on what is displayed
  // Format: UQ Maps | <campus> | Search: <category> | <itemTitle>
  const getDynamicTitle = useCallback(
    (
      campusName: string,
      searchTerm: string,
      itemTitle: string,
      isShowingCategory: boolean,
      itemCategories: Category[] | undefined
    ): string => {
      let titleString = "UQ Maps";

      if (campusName !== "") {
        titleString = `${titleString} | ${campusName}`;
      }

      if (isShowingCategory) {
        titleString = `${titleString} | ${searchTerm}`;
      }

      let itemCategory;

      // If user didn't search, then get category from item details
      if (
        itemCategories !== undefined
        && itemCategories.length > 0
        && !isShowingCategory
      ) {
        itemCategory = itemCategories[0]?.title ?? "";
        if (itemCategory !== "") {
          titleString = `${titleString} | ${itemCategory}`;
        }
      }

      if (
        itemTitle !== ""
        && itemTitle !== itemCategory
        && itemTitle !== searchTerm
      ) {
        titleString = `${titleString} | ${itemTitle}`;
      }

      return titleString;
    }, []
  );

  useEffect(() => {
    document.title = getDynamicTitle(
      currentCampusName,
      searchTerm,
      itemTitle,
      isShowingCategoryResults,
      itemCategories
    );
  }, [getDynamicTitle, currentCampusName, searchTerm, itemTitle, isShowingCategoryResults, itemCategories]);

  return (
    <div className={styles.app}>
      <div className={styles.header}>
        {!isLandscape && header && <Header />}
      </div>
      <div className={styles.main}>
        <MapsApp />
      </div>
    </div>
  );
}

export default App;
