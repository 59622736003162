import React, { createContext, PropsWithChildren, ReactElement, useContext, useEffect, useState } from "react";
import { MapEx, MazeMapOptions } from "MazeMapTypes";
import { UrlProcessing } from "../../services/UrlProcessing";
import MazeMapDataService from "../../services/MazeMapDataService";
import { MapParameters } from "../../app/models/MapParameters";
import { PoiUtil } from "../../services/PoiUtil";
import { LatLng } from "../../app/models/LatLng";
import { GeoJsonUtil } from "../../services/GeoJsonUtil";
import styles from "./Map.module.scss";
import { MAZEMAP_CAMPUS_COLLECTION_ID } from "../../constants";
import { DEFAULT_CAMPUS_ID, DEFAULT_MAP_STYLE, defaultZoomLevel } from "../../store/map/mapSlice";
import { MapClickDispatcher } from "./MapClickDispatcher";
import { WayfindingParameters } from "../../app/models/WayfindingParameters";
import { Campus, Category, PointOfInterest } from "../../types/UQMapsTypes";
import { ensureRouteFeatureIconsAddedToMap } from "../wayFinding/routeFeatureIcons";
import { ensureCategoryIconsAddedToMap } from "../../services/categoryIcons";
import { ensureTypeIconsAddedToMap, missingImageLoader } from "../../services/typeIcons";

/**
 * Initial map parameters parsed from URL.
 */
export interface MapUrlParameters {
  campuses: Campus[];
  defaultCampusId: string;
  defaultCentre: LatLng | null;
  poi: PointOfInterest | null;
  category: Category | null;
  wayfindingParameters: WayfindingParameters;
  canonicalParameters: MapParameters;
}

export interface MapContextState {
  map: MapEx;
  params: MapUrlParameters;
}

export interface MapProviderProps {
  initialCampusId?: string;
}

/**
 * Provider component for the Mazemap object. Any component using the map
 * should be underneath this component and the provider should only appear
 * once in the React app.
 */
export function MapProvider(props: PropsWithChildren<MapProviderProps>): ReactElement {
  const { children } = props;
  const map = useMapInternal(props);
  return <MapContext.Provider value={map}>{children}</MapContext.Provider>;
}

/**
 * Hook to obtain a reference to the provider's Mazemap object. The Mazemap
 * instance should be globally unique. This hook must be used from a direct
 * or indirect child of MapProvider.
 */
export const useMap = (): MapContextState | null => useContext(MapContext);

// internal implementation details below.

const MapContext = createContext<MapContextState | null>(null);

const useMapInternal = (props: MapProviderProps): MapContextState | null => {
  const [mapState, setMapState] = useState<MapContextState | null>(null);
  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    initialiseMap(setMapState, props);
    // intentionally ignore async return. this is safe from race conditions because
    // the empty deps array ensures this is called exactly once then never again.

    return (): void => {
      console.log("WARNING: UNMOUNTING MAPPROVIDER");
      // forces a refresh of the mazemap instance when the component
      // is unmounted. should only ever happen when the app is being
      // reloaded.
      setMapState(null);
    };
    // we explicitly use empty dependencies despite the fact that we use the
    // props variable because this code must only be run once in the lifecycle
    // of the application. the props are used as a one-off initialisation
    // parameter and we should not run this again when they change.

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return mapState;
};

// helper functions below.

const initialiseMap = async (setMap: (m: MapContextState) => void, props: MapProviderProps): Promise<void> => {
  // get url parameters
  const params = new URLSearchParams(document.location.search) as URLSearchParams;
  const urlParameters = UrlProcessing.getMapParameters(params);

  // fetch only uq campuses from MazeMap
  const campuses: Campus[] = await MazeMapDataService.getCampuses();

  // TODO: reimplement initialCampusId from state.
  const defaultCampusId = getDefaultCampus(urlParameters, props.initialCampusId);
  const poi = await getPoiFromUrl(urlParameters);
  const category = await getCategoryFromUrl(urlParameters);
  const defaultCentre = getInitialLocation(urlParameters, poi, campuses, defaultCampusId);
  const wayfindingParameters = await UrlProcessing.getWayfindingPoints(urlParameters);

  const canonicalParameters = {
    campusId: defaultCampusId.toString(),
    ...defaultCentre,
    pitch: urlParameters.pitch,
    poiId: urlParameters.poiId,
    category: urlParameters.category,
    zLevel: urlParameters.zLevel ?? 1,
    zoom: urlParameters.zoom
  };

  //create the map
  const result = {
    map: makeMazeMapInstance(canonicalParameters, defaultCentre),
    params: {
      campuses,
      defaultCampusId,
      poi,
      category,
      defaultCentre,
      wayfindingParameters,
      canonicalParameters
    }
  };

  const map = result.map;
  // icon loading starts here and is awaited once the map has fully loaded.
  // noinspection ES6MissingAwait
  const iconPromises = [
    ensureRouteFeatureIconsAddedToMap(map),
    ensureCategoryIconsAddedToMap(map),
    ensureTypeIconsAddedToMap(map)
  ];

  const loaded = async (): Promise<void> => {
    await Promise.all(iconPromises);

    const styleImageMissing = (x: { id: string }): void => missingImageLoader(map, x);
    map.on("styleimagemissing", styleImageMissing);

    setMap(result);
  };

  result.map.once("load", loaded);
};

function getDefaultCampus(urlParameters: MapParameters, initialCampusId?: string): string {
  const allowedCampuses = ["563", "468", "562", "544", "406", "467"];
  const selectedCampus = urlParameters.campusId ?? initialCampusId;
  if (selectedCampus && allowedCampuses.includes(selectedCampus)) {
    return selectedCampus;
  }
  return DEFAULT_CAMPUS_ID;
}

async function getPoiFromUrl(urlParameters: MapParameters): Promise<PointOfInterest | null> {
  let poi: PointOfInterest | null = null;

  if (urlParameters.identifier) {
    // preferred method
    poi = await MazeMapDataService.getPoi(urlParameters.identifier);
  } else if (urlParameters.poiId) {
    // legacy method
    poi = await MazeMapDataService.getPoi(urlParameters.poiId);
  } else if (urlParameters.isSelectedPoint && urlParameters.lat && urlParameters.lng) {
    poi = PoiUtil.createSelectedPOI({
      lat: urlParameters.lat,
      lng: urlParameters.lng
    });
  }
  return poi;
}

async function getCategoryFromUrl(urlParameters: MapParameters): Promise<Category | null> {
  if (urlParameters.category) {
    return await MazeMapDataService.getCategory(urlParameters.category);
  }
  return null;
}

function getInitialLocation(
  urlParameters: MapParameters,
  poi: PointOfInterest | null,
  campuses: Campus[],
  defaultCampusId: string
): LatLng {
  const campus = campuses.find(c => c.properties.id === defaultCampusId);
  if (
    urlParameters.lat
    && urlParameters.lng
    && (!campus
      || GeoJsonUtil.isPointInPolygon(
        GeoJsonUtil.toPoint(GeoJsonUtil.toPosition({ lat: urlParameters.lat, lng: urlParameters.lng })),
        campus.geometry
      ))
  ) {
    // 1. Url parameters (but only if the latlng is inside the current campus)
    return { lat: urlParameters.lat, lng: urlParameters.lng };
  } else if (poi) {
    // 2. Poi centre
    return GeoJsonUtil.toLngLat(GeoJsonUtil.getGeoCentre(poi.geometry));
  }
  // 4. Campus centre
  if (campus) {
    const campusCentre = GeoJsonUtil.getGeoCentre(campus.geometry);
    return { lat: campusCentre[1], lng: campusCentre[0] };
  }

  // 5. Default campus centre
  const defaultCampus = campuses.find(c => c.properties.id === DEFAULT_CAMPUS_ID);
  if (defaultCampus) {
    const campusCentre = GeoJsonUtil.getGeoCentre(defaultCampus.geometry);
    return { lat: campusCentre[1], lng: campusCentre[0] };
  }

  console.error("ERROR: unable to locate initial map centre");

  // hard coded coordinates for St Lucia.
  return { lat: -27.49751300000002, lng: 153.01326649999993 };
}

function makeMazeMapInstance(parameters: MapParameters, center: LatLng): MapEx {
  const mazemapRoot = document.createElement("div");
  mazemapRoot.className = styles.mazemapRoot;
  const mapOptions: MazeMapOptions = {
    container: mazemapRoot,
    campuses: MAZEMAP_CAMPUS_COLLECTION_ID,
    zLevelControl: false,
    touchPitch: true,
    zoom: parameters.zoom ?? defaultZoomLevel,
    pitch: parameters.pitch ?? 0,
    zLevel: parameters.zLevel,
    style: DEFAULT_MAP_STYLE,
    center: [center.lng, center.lat]
  };

  const map = new Mazemap.Map(mapOptions) as MapEx;

  new MapClickDispatcher(map);
  return map;
}
