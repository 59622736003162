import React, {useState, useCallback, useEffect, useRef} from 'react';
import classNames from 'classnames';
require('./lib/slide-menu.js');
require('./_styles.scss');

declare const SlideMenu;

type PrimaryNavItemProps = {
    /**
     * Link URL
     */
    href: string;
    /**
     * Link title
     */
    title: string;
    /**
     * Enable megamenu on desktop and mobile
     */
    enableMegamenu?: boolean;
    /**
     * Megamenu contents
     */
    children?: React.ReactNode;
}

function PrimaryNavItem ({href, title, enableMegamenu = true, children}: PrimaryNavItemProps): JSX.Element {
    const [hovered, setHovered] = useState(false);
    const [mqRes, setMqRes] = useState<MediaQueryListEvent | {matches: boolean}>({
        matches: window.innerWidth >= 1024
    });
    const container = useRef(null);

    useEffect(() => {
        // Media query setup that manages decl. state automatically
        // Might be better on the header parent so we don't have superfluous listeners.
        const mql: MediaQueryList = window.matchMedia('(min-width: 1024px)');

        function saveRes (e: MediaQueryListEvent) {
            setMqRes(e);
        }

        mql.addEventListener('change', saveRes);

        return () => {
            mql.removeEventListener('change', saveRes);
        };
    }, [mqRes]);

    useEffect(() => {
        // Post-render DOM mutations
        const timer1: number = window.setTimeout(() => {
            if (hovered && mqRes.matches && enableMegamenu) {
                container.current.classList.add('uq-header__nav-primary-item--is-open');
            } else {
                container.current.classList.remove('uq-header__nav-primary-item--is-open');
            }
        }, 250);
        
        return () => {
            timer1 && clearTimeout(timer1);
        };
    }, [hovered, enableMegamenu, mqRes]);

    return (
        <li onMouseEnter={() => {setHovered(true);}}
            onMouseLeave={() => {setHovered(false);}}
            className="uq-header__nav-primary-item"
            ref={container}>
            <a className={classNames('uq-header__nav-primary-link', {
                'nav-primary-link--has-dropdown': children && enableMegamenu})}
            href={href}
            data-gtm-label={title}>{title}</a>
            {children && enableMegamenu && <div className="uq-header__megamenu">
                <div className="uq-header__megamenu-container">
                    {/* Megamenu contents */}
                    {children}
                </div>
            </div>}
        </li>
    );
}

PrimaryNavItem.displayName = 'PrimaryNavItem';

type MobilePrimaryNavItemProps = {
    /**
     * Link URL
     */
     href: string;
     /**
      * Link title
      */
     title: string;
     /**
      * Enable megamenu on desktop and mobile
      */
     enableMegamenu?: boolean;
     /**
      * Megamenu contents
      */
     children?: React.ReactNode;
};

function MobilePrimaryNavItem ({
    href, 
    title, 
    enableMegamenu = true, 
    children}: MobilePrimaryNavItemProps): JSX.Element {
    return <li className="uq-header__newglobal-nav-item" data-gtm-category="Primary header">
        <a className={classNames('uq-header__newglobal-nav-link', {
            'slide-menu__control': enableMegamenu
        })} title={title} href={enableMegamenu ? '#' : href}>{title}</a>
        {children && enableMegamenu && <>
            {children}
        </>}
    </li>;
}

export interface HeaderProps {
    /**
     * CSS class names
     */
    className?: string;
    /**
     * Enable megamenus
     */
    enableMegamenu?: boolean;
    /**
     * Enable site search
     */
    enableSiteSearch?: boolean;
    /**
     * Site URL
     * Used for UQ search function (including the protocol, e.g. "https://future-students.uq.edu.au)".
     * If your application/website isn't indexed by UQ Search, it may impact what results are provided by a restricted search.
     */
    siteUrl?: string;
    /**
     * Site title
     */
    siteTitle?: string;
}

export function Header ({
    className,
    enableMegamenu = false,
    enableSiteSearch = false,
    siteUrl,
    siteTitle}: HeaderProps): JSX.Element {
    const [searchToggleOn, setSearchToggleOn] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchSiteOn, setSearchSiteOn] = useState(false);
    const [menuToggleOn, setMenuToggleOn] = useState(false);
    const [mobileNavSlideMenu, setMobileNavSlideMenu] = useState<any>({});
    const searchInput = useRef(null);
    const mobileNav = useRef(null);

    const handleSearchToggleClick = useCallback(() => {
        // search and mobile menu toggles are mutually exclusive UIs
        setSearchToggleOn(prevState => !prevState);
        setMenuToggleOn(false);
    }, []);

    useEffect(() => {
        // Post-render DOM mutations for mobile menu feature
        
        setMobileNavSlideMenu(new SlideMenu(mobileNav.current, {
            position: 'left',
            submenuLinkAfter: ' ',
            backLinkBefore: ' '
        }));

        mobileNav.current.addEventListener('sm.open', function () {
            setSearchToggleOn(false);
            setMenuToggleOn(true);
        });

        mobileNav.current.addEventListener('sm.close', function () {
            setMenuToggleOn(false);
        });

        const slideMenuBackButtons = mobileNav.current.querySelectorAll('.slide-menu__backlink, .global-mobile-nav__audience-link');
        slideMenuBackButtons.forEach((el) => {
            el.addEventListener('click', () => {
                mobileNav.current.scrollTop = 0;
            });
        });
    }, []);

    useEffect(() => {
        // Post-render DOM mutations for search feature

        let timer1: number;

        if (searchToggleOn) {
            searchInput.current.focus();
            mobileNavSlideMenu.close();
            timer1 = window.setTimeout(() => {
                searchInput.current.setAttribute('autoComplete', 'on');
            }, 500);
        } else {
            searchInput.current.setAttribute('autoComplete', 'off');
        }

        return () => {
            timer1 && clearTimeout(timer1);
        };
    }, [searchToggleOn, mobileNavSlideMenu]);

    return (
        <header className={classNames('uq-header', className)} data-gtm-category="Header">
            <div className="uq-header__container">
                <div className="uq-header__menu-toggle" data-gtm-category="Primary header">
                    <button type="button" className={classNames('nav-primary__toggle nav-primary__menu-toggle slide-menu__control', {
                        'nav-primary__menu-toggle--is-open': menuToggleOn
                    })} data-target="global-mobile-nav" data-action="toggle" data-gtm-action="Toggle">Menu</button>
                </div>
                <div className="uq-header__logo" data-gtm-category="Primary header">
                    <a className="logo--large" href="https://www.uq.edu.au/" data-gtm-label="UQ Logo">
                        <img alt="The University of Queensland" src="https://static.uq.net.au/v15/logos/corporate/uq-logo--reversed.svg" />
                    </a>
                </div>
                <div className="uq-header__nav-primary" data-gtm-category="Primary header">
                    <nav className="uq-header__nav-primary-container" aria-label="primary navigation">
                        <ul className="uq-header__nav-primary-list">
                            <PrimaryNavItem enableMegamenu={enableMegamenu} title="Study" href="https://study.uq.edu.au/">
                                {/* Megamenu contents */}
                                <a href="https://study.uq.edu.au/" className="megamenu__overview-link" data-gtm-label="Study > Study overview">Study overview</a>
                                <div className="megamenu__row">
                                    <div className="megamenu__column">
                                        <div className="megamenu__heading">Study with us</div>
                                        <div className="megamenu__menu">
                                            <ul>
                                                <li><a href="https://study.uq.edu.au/study-options/programs" data-gtm-label="Study > Find a course or program">Find a course or program</a></li>
                                                <li><a href="https://study.uq.edu.au/study-options" data-gtm-label="Study > Browse study areas">Browse study areas</a></li>
                                                <li><a href="https://study.uq.edu.au/study-options" data-gtm-label="Study > Study options">Study options</a></li>
                                                <li><a href="https://study.uq.edu.au/admissions" data-gtm-label="Study > How to apply">How to apply</a></li>
                                                <li><a href="https://scholarships.uq.edu.au/" data-gtm-label="Study > Scholarships">Scholarships</a></li>
                                                <li><a href="https://my.uq.edu.au/starting-at-uq" data-gtm-label="Study > Starting at UQ">Starting at UQ</a></li>
                                                <li><a href="https://study.uq.edu.au/contact" data-gtm-label="Study > Contact">Contact</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="megamenu__column">
                                        <div className="megamenu__heading">Discover UQ</div>
                                        <div className="megamenu__menu">
                                            <ul>
                                                <li><a href="https://study.uq.edu.au/why-choose-uq" data-gtm-label="Study > Why choose UQ">Why choose UQ</a></li>
                                                <li><a href="https://study.uq.edu.au/enhance-your-employability" data-gtm-label="Study > Enhance your employability">Enhance your employability</a></li>
                                                <li><a href="https://study.uq.edu.au/university-life" data-gtm-label="Study > Life at UQ and accommodation">Life at UQ and accommodation</a></li>
                                                <li><a href="https://campuses.uq.edu.au/" data-gtm-label="Study > Campuses, maps and transport">Campuses, maps and transport</a></li>
                                                <li><a href="https://study.uq.edu.au/events" data-gtm-label="Study > Events for prospective students">Events for prospective students</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="megamenu__column">
                                        <div className="megamenu__heading">Information for</div>
                                        <div className="megamenu__menu">
                                            <ul>
                                                <li><a href="https://study.uq.edu.au/international-students" data-gtm-label="Study > International students">International students</a></li>
                                                <li><a href="https://study.uq.edu.au/high-school-students" data-gtm-label="Study > High school students">High school students</a></li>
                                                <li><a href="https://study.uq.edu.au/non-school-leavers" data-gtm-label="Study > Non-school leavers">Non-school leavers</a></li>
                                                <li><a href="https://study.uq.edu.au/study-options/research" data-gtm-label="Study > Postgraduate research (HDR) students">Postgraduate research (HDR) students</a></li>
                                                <li><a href="https://atsis.uq.edu.au/future-students" data-gtm-label="Study > Aboriginal and Torres Strait Islander students">Aboriginal and Torres Strait Islander students</a></li>
                                                <li><a href="https://study.uq.edu.au/study-options/study-abroad" data-gtm-label="Study > Study abroad and exchange students">Study abroad and exchange students</a></li>
                                                <li><a href="https://study.uq.edu.au/teachers-guidance-counsellors" data-gtm-label="Study > Teachers and guidance counsellors">Teachers and guidance counsellors</a></li>
                                                <li><a href="https://study.uq.edu.au/parents" data-gtm-label="Study > Parents and guardians">Parents and guardians</a></li>
                                                <li><a href="https://my.uq.edu.au/" data-gtm-label="Study > Current students">Current students</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </PrimaryNavItem>
                            <PrimaryNavItem enableMegamenu={enableMegamenu} title="Research" href="https://research.uq.edu.au/">
                                {/* Megamenu contents */}
                                <a href="https://research.uq.edu.au/" className="megamenu__overview-link" data-gtm-label="Research > Research overview">Research overview</a>
                                <div className="megamenu__row">
                                    <div className="megamenu__column">
                                        <div className="megamenu__heading">Our research</div>
                                        <div className="megamenu__menu">
                                            <ul>
                                                <li><a href="https://research.uq.edu.au/news/impact" data-gtm-label="Research > Research impact">Research impact</a></li>
                                                <li><a href="https://research.uq.edu.au/research-support" data-gtm-label="Research > Research funding, ethics and support">Research funding, ethics and support</a></li>
                                                <li><a href="https://research.uq.edu.au/partner-with-us" data-gtm-label="Research > Industry research collaboration">Industry research collaboration</a></li>
                                                <li><a href="https://about.uq.edu.au/faculties-schools-institutes-centres" data-gtm-label="Faculties, schools, institutes and centres">Faculties, schools, institutes and centres</a></li>
                                                <li><a href="https://research.uq.edu.au/research-infrastructure" data-gtm-label="Research > Research facilities">Research facilities</a></li>
                                                <li><a href="https://researchers.uq.edu.au/" data-gtm-label="Research > Find a researcher">Find a researcher</a></li>
                                                <li><a href="https://research.uq.edu.au/contact" data-gtm-label="Research > Contact">Contact</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="megamenu__column">
                                        <div className="megamenu__heading">Graduate research</div>
                                        <div className="megamenu__menu">
                                            <ul>
                                                <li><a href="https://graduate-school.uq.edu.au/" data-gtm-label="Research > Research degrees (including PhDs)">Research degrees (including PhDs)</a></li>
                                                <li><a href="https://researchers.uq.edu.au/" data-gtm-label="Research > Find a supervisor">Find a supervisor</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="megamenu__column">
                                        <div className="megamenu__heading">Information for</div>
                                        <div className="megamenu__menu">
                                            <ul>
                                                <li><a href="https://study.uq.edu.au/study-options/research" data-gtm-label="Research > Future research students">Future research students</a></li>
                                                <li><a href="https://my.uq.edu.au/information-and-services/higher-degree-research/get-started" data-gtm-label="Research > New research students">New research students</a></li>
                                                <li><a href="https://my.uq.edu.au/information-and-services/higher-degree-research" data-gtm-label="Research > Current research students">Current research students</a></li>
                                                <li><a href="https://research.uq.edu.au/partner-with-us" data-gtm-label="Research > Prospective research partners">Prospective research partners</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </PrimaryNavItem>
                            <PrimaryNavItem enableMegamenu={enableMegamenu} title="Partners and community" href="https://partners-community.uq.edu.au">
                                {/* Megamenu contents */}
                                <a href="https://partners-community.uq.edu.au" className="megamenu__overview-link" data-gtm-label="Partners and community > Partners and community overview">Partners and community overview</a>
                                <div className="megamenu__row">
                                    <div className="megamenu__column">
                                        <div className="megamenu__heading">Partner with us</div>
                                        <div className="megamenu__menu">
                                            <ul>
                                                <li><a href="https://www.uq.edu.au/partner/business-industry" data-gtm-label="Partners and community > Partnership opportunities">Partnership opportunities</a></li>
                                                <li><a href="https://research.uq.edu.au/partner-with-us" data-gtm-label="Partners and community > Research and corporate partnerships">Research and corporate partnerships</a></li>
                                                <li><a href="https://employability.uq.edu.au/work-experience" data-gtm-label="Partners and community > Work placements and internships">Work placements and internships</a></li>
                                                <li><a href="https://global-engagement.uq.edu.au/partnerships" data-gtm-label="Partners and community > Teaching and exchange collaborations">Teaching and exchange collaborations</a></li>
                                                <li><a href="https://about.uq.edu.au/sponsorships" data-gtm-label="Partners and community > Sponsorship">Sponsorship</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="megamenu__column">
                                        <div className="megamenu__heading">Our communities</div>
                                        <div className="megamenu__menu">
                                            <ul>
                                                <li><a href="https://alumni.uq.edu.au/" data-gtm-label="Partners and community > Alumni community">Alumni community</a></li>
                                                <li><a href="https://alumni.uq.edu.au/giving" data-gtm-label="Partners and community > Giving and philanthropy">Giving and philanthropy</a></li>
                                                <li><a href="https://atsis.uq.edu.au/" data-gtm-label="Partners and community > Indigenous engagement">Indigenous engagement</a></li>
                                                <li><a href="https://study.uq.edu.au/teachers-guidance-counsellors" data-gtm-label="Partners and community > High school engagement">High school engagement</a></li>
                                                <li><a href="https://alumni.uq.edu.au/volunteer" data-gtm-label="Partners and community > Community engagement and volunteering">Community engagement and volunteering</a></li>
                                                <li><a href="https://campuses.uq.edu.au/information-and-services/shops" data-gtm-label="Partners and community > Community facilities and services">Community facilities and services</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="megamenu__column">
                                        <div className="megamenu__heading">Information for</div>
                                        <div className="megamenu__menu">
                                            <ul>
                                                <li><a href="https://alumni.uq.edu.au/" data-gtm-label="Partners and community > Alumni">Alumni</a></li>
                                                <li><a href="https://alumni.uq.edu.au/giving" data-gtm-label="Partners and community > Donors">Donors</a></li>
                                                <li><a href="https://www.uq.edu.au/partner/business-industry" data-gtm-label="Partners and community > Business and industry">Business and industry</a></li>
                                                <li><a href="https://www.uq.edu.au/news/" data-gtm-label="Partners and community > Media">Media</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </PrimaryNavItem>
                            <PrimaryNavItem enableMegamenu={enableMegamenu} title="About" href="https://about.uq.edu.au/">
                                {/* Megamenu contents */}
                                <a href="https://about.uq.edu.au/" className="megamenu__overview-link" data-gtm-label="About > About overview">About overview</a>
                                <div className="megamenu__row">
                                    <div className="megamenu__column">
                                        <div className="megamenu__heading">Our profile</div>
                                        <div className="megamenu__menu">
                                            <ul>
                                                <li><a href="https://about.uq.edu.au/university-profile" data-gtm-label="About > Introducing UQ">Introducing UQ</a></li>
                                                <li><a href="https://about.uq.edu.au/strategic-plan" data-gtm-label="About > Vision, values and strategy">Vision, values and strategy</a></li>
                                                <li><a href="https://teaching-learning.uq.edu.au/" data-gtm-label="About > Teaching and learning">Teaching and learning</a></li>
                                                <li><a href="https://about.uq.edu.au/initiatives" data-gtm-label="About > Initiatives">Initiatives</a></li>
                                                <li><a href="https://about.uq.edu.au/organisation" data-gtm-label="About > Organisational structure and governance">Organisational structure and governance</a></li>
                                                <li><a href="https://ppl.app.uq.edu.au/" data-gtm-label="About > Policies and procedures">Policies and procedures</a></li>
                                                <li><a href="https://about.uq.edu.au/faculties-schools-institutes-centres" data-gtm-label="About > Faculties, schools, institutes and centres">Faculties, schools, institutes and centres</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="megamenu__column">
                                        <div className="megamenu__heading">Campuses and facilities</div>
                                        <div className="megamenu__menu">
                                            <ul>
                                                <li><a href="https://campuses.uq.edu.au/" data-gtm-label="About > Campuses, maps and transport">Campuses, maps and transport</a></li>
                                                <li><a href="https://my.uq.edu.au/student-support/accommodation" data-gtm-label="About > Colleges and accommodation">Colleges and accommodation</a></li>
                                                <li><a href="https://campuses.uq.edu.au/information-and-services/shops" data-gtm-label="About > Community facilities and services">Community facilities and services</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="megamenu__column">
                                        <div className="megamenu__heading">Working at UQ</div>
                                        <div className="megamenu__menu">
                                            <ul>
                                                <li><a href="https://careers.uq.edu.au/" data-gtm-label="About > Careers at UQ">Careers at UQ</a></li>
                                                <li><a href="https://careers.uq.edu.au/search-jobs" data-gtm-label="About > Jobs search">Jobs search</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </PrimaryNavItem>
                        </ul>
                    </nav>
                </div>
                <div className="uq-header__search-toggle" data-gtm-category="Search">
                    <button onClick={handleSearchToggleClick}
                        className={classNames('nav-primary__toggle nav-primary__search-toggle', {
                            'nav-primary__search-toggle--is-open': searchToggleOn
                        })}
                        data-gtm-action="Toggle">
                        <div className="search-toggle__label">{searchToggleOn ? 'Close' : 'Search'}</div>
                    </button>
                </div>
            </div>
            {/* <!-- #region Mobile menu --> */}
            <nav
                className="slide-menu global-mobile-nav"
                ref={mobileNav} 
                id="global-mobile-nav" 
                data-gtm-category="Header navigation mobile"
                aria-label="primary navigation mobile">
                {/* <!-- #region Site title --> */}
                {/* 
                    Handles SiteHeader sibling comms FOR MOBILE.
                    As this block falls with our useRef hook usage, bc of shallow rendering, it may cause issues
                    if there wasn't a siteTitle value to begin with.
                */}
                {siteTitle && <div className="uq-site-header__title-container">
                    <div className="uq-site-header__title-container__left">
                        <a href="/" className="uq-site-header__title">{siteTitle}</a>
                    </div>
                </div>}
                {/* <!-- #endregion Site title --> */}
                <ul>
                    <MobilePrimaryNavItem
                        title="Study"
                        href="https://study.uq.edu.au/"
                        enableMegamenu={enableMegamenu}>
                        <ul>
                            <li>
                                <a href="https://study.uq.edu.au/" data-gtm-label="Study > Study overview">Study overview</a>
                            </li>
                            <li>
                                <a href="#" className="global-mobile-nav__audience-link slide-menu__control">Study with us</a>
                                <ul>
                                    <li><a href="https://study.uq.edu.au/study-options/programs" data-gtm-label="Study > Find a course or program">Find a course or program</a></li>
                                    <li><a href="https://study.uq.edu.au/study-options" data-gtm-label="Study > Browse study areas">Browse study areas</a></li>
                                    <li><a href="https://study.uq.edu.au/study-options" data-gtm-label="Study > Study options">Study options</a></li>
                                    <li><a href="https://study.uq.edu.au/admissions" data-gtm-label="Study > How to apply">How to apply</a></li>
                                    <li><a href="https://scholarships.uq.edu.au/" data-gtm-label="Study > Scholarships">Scholarships</a></li>
                                    <li><a href="https://my.uq.edu.au/starting-at-uq" data-gtm-label="Study > Starting at UQ">Starting at UQ</a></li>
                                    <li><a href="https://study.uq.edu.au/contact" data-gtm-label="Study > Contact">Contact</a></li>
                                </ul>
                            </li>
                            <li>
                                <a href="#" className="global-mobile-nav__audience-link slide-menu__control">Discover UQ</a>
                                <ul>
                                    <li><a href="https://study.uq.edu.au/why-choose-uq" data-gtm-label="Study > Why choose UQ">Why choose UQ</a></li>
                                    <li><a href="https://study.uq.edu.au/enhance-your-employability" data-gtm-label="Study > Enhance your employability">Enhance your employability</a></li>
                                    <li><a href="https://study.uq.edu.au/university-life" data-gtm-label="Study > Life at UQ and accommodation">Life at UQ and accommodation</a></li>
                                    <li><a href="https://campuses.uq.edu.au/" data-gtm-label="Study > Campuses, maps and transport">Campuses, maps and transport</a></li>
                                    <li><a href="https://study.uq.edu.au/events" data-gtm-label="Study > Events for prospective students">Events for prospective students</a></li>
                                </ul>
                            </li>
                            <li>
                                <a href="#" className="global-mobile-nav__audience-link slide-menu__control">Information for</a>
                                <ul>
                                    <li><a href="https://study.uq.edu.au/international-students" data-gtm-label="Study > International students">International students</a></li>
                                    <li><a href="https://study.uq.edu.au/high-school-students" data-gtm-label="Study > High school students">High school students</a></li>
                                    <li><a href="https://study.uq.edu.au/non-school-leavers" data-gtm-label="Study > Non-school leavers">Non-school leavers</a></li>
                                    <li><a href="https://study.uq.edu.au/study-options/research" data-gtm-label="Study > Postgraduate research (HDR) students">Postgraduate research (HDR) students</a></li>
                                    <li><a href="https://atsis.uq.edu.au/future-students" data-gtm-label="Study > Aboriginal and Torres Strait Islander students">Aboriginal and Torres Strait Islander students</a></li>
                                    <li><a href="https://study.uq.edu.au/study-options/study-abroad" data-gtm-label="Study > Study abroad and exchange students">Study abroad and exchange students</a></li>
                                    <li><a href="https://study.uq.edu.au/teachers-guidance-counsellors" data-gtm-label="Study > Teachers and guidance counsellors">Teachers and guidance counsellors</a></li>
                                    <li><a href="https://study.uq.edu.au/parents" data-gtm-label="Study > Parents and guardians">Parents and guardians</a></li>
                                    <li><a href="https://my.uq.edu.au/" data-gtm-label="Study > Current students">Current students</a></li>
                                </ul>
                            </li>
                        </ul>
                    </MobilePrimaryNavItem>
                    <MobilePrimaryNavItem
                        title="Research"
                        href="https://research.uq.edu.au/"
                        enableMegamenu={enableMegamenu}>
                        <ul>
                            <li>
                                <a href="https://research.uq.edu.au/" data-gtm-label="Research > Research overview">Research overview</a>
                            </li>
                            <li>
                                <a href="#" className="global-mobile-nav__audience-link slide-menu__control">Our research</a>
                                <ul>
                                    <li><a href="https://research.uq.edu.au/news/impact" data-gtm-label="Research > Research impact">Research impact</a></li>
                                    <li><a href="https://research.uq.edu.au/research-support" data-gtm-label="Research > Research funding, ethics and support">Research funding, ethics and support</a></li>
                                    <li><a href="https://research.uq.edu.au/partner-with-us" data-gtm-label="Research > Industry research collaboration">Industry research collaboration</a></li>
                                    <li><a href="https://about.uq.edu.au/faculties-schools-institutes-centres" data-gtm-label="Faculties, schools, institutes and centres">Faculties, schools, institutes and centres</a></li>
                                    <li><a href="https://research.uq.edu.au/research-infrastructure" data-gtm-label="Research > Research facilities">Research facilities</a></li>
                                    <li><a href="https://researchers.uq.edu.au/" data-gtm-label="Research > Find a researcher">Find a researcher</a></li>
                                    <li><a href="https://research.uq.edu.au/contact" data-gtm-label="Research > Contact">Contact</a></li>
                                </ul>
                            </li>
                            <li>
                                <a href="#" className="global-mobile-nav__audience-link slide-menu__control">Graduate research</a>
                                <ul>
                                    <li><a href="https://graduate-school.uq.edu.au/" data-gtm-label="Research > Research degrees (including PhDs)">Research degrees (including PhDs)</a></li>
                                    <li><a href="https://researchers.uq.edu.au/" data-gtm-label="Research > Find a supervisor">Find a supervisor</a></li>
                                </ul>
                            </li>
                            <li>
                                <a href="#" className="global-mobile-nav__audience-link slide-menu__control">Information for</a>
                                <ul>
                                    <li><a href="https://study.uq.edu.au/study-options/research" data-gtm-label="Research > Future research students">Future research students</a></li>
                                    <li><a href="https://my.uq.edu.au/information-and-services/higher-degree-research/get-started" data-gtm-label="Research > New research students">New research students</a></li>
                                    <li><a href="https://my.uq.edu.au/information-and-services/higher-degree-research" data-gtm-label="Research > Current research students">Current research students</a></li>
                                    <li><a href="https://research.uq.edu.au/partner-with-us" data-gtm-label="Research > Prospective research partners">Prospective research partners</a></li>
                                </ul>
                            </li>
                        </ul>
                    </MobilePrimaryNavItem>
                    <MobilePrimaryNavItem
                        title="Partners and community"
                        href="https://partners-community.uq.edu.au"
                        enableMegamenu={enableMegamenu}>
                        <ul>
                            <li>
                                <a href="https://partners-community.uq.edu.au" data-gtm-label="Partners and community > Partners and community overview">Partners and community overview</a>
                            </li>
                            <li>
                                <a href="#" className="global-mobile-nav__audience-link slide-menu__control">Partner with us</a>
                                <ul>
                                    <li><a href="https://www.uq.edu.au/partner/business-industry" data-gtm-label="Partners and community > Partnership opportunities">Partnership opportunities</a></li>
                                    <li><a href="https://research.uq.edu.au/partner-with-us" data-gtm-label="Partners and community > Research and corporate partnerships">Research and corporate partnerships</a></li>
                                    <li><a href="https://employability.uq.edu.au/work-experience" data-gtm-label="Partners and community > Work placements and internships">Work placements and internships</a></li>
                                    <li><a href="https://global-engagement.uq.edu.au/partnerships" data-gtm-label="Partners and community > Teaching and exchange collaborations">Teaching and exchange collaborations</a></li>
                                    <li><a href="https://about.uq.edu.au/sponsorships" data-gtm-label="Partners and community > Sponsorship">Sponsorship</a></li>
                                </ul>
                            </li>
                            <li>
                                <a href="#" className="global-mobile-nav__audience-link slide-menu__control">Our communities</a>
                                <ul>
                                    <li><a href="https://alumni.uq.edu.au/" data-gtm-label="Partners and community > Alumni community">Alumni community</a></li>
                                    <li><a href="https://alumni.uq.edu.au/giving" data-gtm-label="Partners and community > Giving and philanthropy">Giving and philanthropy</a></li>
                                    <li><a href="https://atsis.uq.edu.au/" data-gtm-label="Partners and community > Indigenous engagement">Indigenous engagement</a></li>
                                    <li><a href="https://study.uq.edu.au/teachers-guidance-counsellors" data-gtm-label="Partners and community > High school engagement">High school engagement</a></li>
                                    <li><a href="https://alumni.uq.edu.au/volunteer" data-gtm-label="Partners and community > Community engagement and volunteering">Community engagement and volunteering</a></li>
                                    <li><a href="https://campuses.uq.edu.au/information-and-services/shops" data-gtm-label="Partners and community > Community facilities and services">Community facilities and services</a></li>
                                </ul>
                            </li>
                            <li>
                                <a href="#" className="global-mobile-nav__audience-link slide-menu__control">Information for</a>
                                <ul>
                                    <li><a href="https://alumni.uq.edu.au/" data-gtm-label="Partners and community > Alumni">Alumni</a></li>
                                    <li><a href="https://alumni.uq.edu.au/giving" data-gtm-label="Partners and community > Donors">Donors</a></li>
                                    <li><a href="https://www.uq.edu.au/partner/business-industry" data-gtm-label="Partners and community > Business and industry">Business and industry</a></li>
                                    <li><a href="https://www.uq.edu.au/news/" data-gtm-label="Partners and community > Media">Media</a></li>
                                </ul>
                            </li>
                        </ul>
                    </MobilePrimaryNavItem>
                    <MobilePrimaryNavItem
                        title="About"
                        href="https://about.uq.edu.au"
                        enableMegamenu={enableMegamenu}>
                        <ul>
                            <li>
                                <a href="https://about.uq.edu.au/" data-gtm-label="About > About overview">About overview</a>
                            </li>
                            <li>
                                <a href="#" className="global-mobile-nav__audience-link slide-menu__control">Our profile</a>
                                <ul>
                                    <li><a href="https://about.uq.edu.au/university-profile" data-gtm-label="About > Introducing UQ">Introducing UQ</a></li>
                                    <li><a href="https://about.uq.edu.au/strategic-plan" data-gtm-label="About > Vision, values and strategy">Vision, values and strategy</a></li>
                                    <li><a href="https://teaching-learning.uq.edu.au/" data-gtm-label="About > Teaching and learning">Teaching and learning</a></li>
                                    <li><a href="https://about.uq.edu.au/initiatives" data-gtm-label="About > Initiatives">Initiatives</a></li>
                                    <li><a href="https://about.uq.edu.au/organisation" data-gtm-label="About > Organisational structure and governance">Organisational structure and governance</a></li>
                                    <li><a href="https://ppl.app.uq.edu.au/" data-gtm-label="About > Policies and procedures">Policies and procedures</a></li>
                                    <li><a href="https://about.uq.edu.au/faculties-schools-institutes-centres" data-gtm-label="About > Faculties, schools, institutes and centres">Faculties, schools, institutes and centres</a></li>
                                </ul>
                            </li>
                            <li>
                                <a href="#" className="global-mobile-nav__audience-link slide-menu__control">Campuses and facilities</a>
                                <ul>
                                    <li><a href="https://campuses.uq.edu.au/" data-gtm-label="About > Campuses, maps and transport">Campuses, maps and transport</a></li>
                                    <li><a href="https://my.uq.edu.au/student-support/accommodation" data-gtm-label="About > Colleges and accommodation">Colleges and accommodation</a></li>
                                    <li><a href="https://campuses.uq.edu.au/information-and-services/shops" data-gtm-label="About > Community facilities and services">Community facilities and services</a></li>
                                </ul>
                            </li>
                            <li>
                                <a href="#" className="global-mobile-nav__audience-link slide-menu__control">Working at UQ</a>
                                <ul>
                                    <li><a href="https://careers.uq.edu.au/" data-gtm-label="About > Careers at UQ">Careers at UQ</a></li>
                                    <li><a href="https://careers.uq.edu.au/search-jobs" data-gtm-label="About > Jobs search">Jobs search</a></li>
                                </ul>
                            </li>
                        </ul>
                    </MobilePrimaryNavItem>
                    <li className="uq-header__nav-secondary-item" data-gtm-category="Secondary header">
                        <a href="https://www.uq.edu.au/" className="uq-header__nav-secondary-link">UQ home</a>
                    </li>
                    <li className="uq-header__nav-secondary-item" data-gtm-category="Secondary header">
                        <a href="https://www.uq.edu.au/news/" className="uq-header__nav-secondary-link">News</a>
                    </li>
                    <li className="uq-header__nav-secondary-item" data-gtm-category="Secondary header">
                        <a href="https://www.uq.edu.au/uq-events" className="uq-header__nav-secondary-link">Events</a>
                    </li>
                    <li className="uq-header__nav-secondary-item" data-gtm-category="Secondary header">
                        <a href="https://alumni.uq.edu.au/giving" className="uq-header__nav-secondary-link">Give</a>
                    </li>
                    <li className="uq-header__nav-secondary-item" data-gtm-category="Secondary header">
                        <a href="https://contacts.uq.edu.au/" className="uq-header__nav-secondary-link">Contact</a>
                    </li>
                </ul>
            </nav>
            {/* <!-- #endregion Mobile menu --> */}
            <div className={classNames('uq uq-header__search', {
                'uq-header__search--is-open': searchToggleOn
            })} data-gtm-category="Search">
                <div className="uq-header__search-container">
                    <form className="uq-header__search-form" action="https://www.uq.edu.au/search/" method="get" data-gtm-action="Text search" data-gtm-form-action>
                        <fieldset className="uq-header__search-fieldset">
                            <div className="uq-header__search-query">
                                <label htmlFor="edit-q" className="uq-header__search-query-label">Search by keyword</label>
                                <input type="text" id="edit-q" name="q"
                                    autoComplete='off'
                                    ref={searchInput}
                                    onChange={(e) => {setSearchTerm(e.target.value);}}
                                    value={searchTerm}
                                    maxLength={128}
                                    placeholder="Search by keyword"
                                    className="uq-header__search-query-input" />
                                <span className="uq-header__search-query-button">
                                    <input type="submit" name="op" value="Search" className="uq-header__search-query-submit" data-gtm-trigger="click" />
                                </span>
                            </div>
                            {enableSiteSearch && <>
                                <div className="uq-header__search-range">
                                    <input type="radio" id="edit-as_sitesearch-off" name="as_sitesearch" value="" className="uq-header__search-radio"
                                        onChange={() => {setSearchSiteOn(false);}}
                                        checked={!searchSiteOn} />
                                    <label htmlFor="edit-as_sitesearch-off" className="uq-header__search-label">Search all UQ websites</label>
                                </div>
                                <div className="uq-header__search-range">
                                    <input type="radio" id="edit-as_sitesearch-on" name="as_sitesearch" value={siteUrl} className="uq-header__search-radio"
                                        onChange={() => {setSearchSiteOn(true);}}
                                        checked={searchSiteOn} />
                                    <label htmlFor="edit-as_sitesearch-on" className="uq-header__search-label">Search this website ({siteUrl})</label>
                                </div>
                            </>}
                        </fieldset>
                    </form>
                </div>
            </div>
            <div className="uq-header__nav-secondary" data-gtm-category="Secondary header">
                <nav className="uq-header__nav-secondary-container">
                    <ul className="uq-header__nav-secondary-list">
                        <li className="uq-header__nav-secondary-item">
                            <a href="https://www.uq.edu.au/" rel="external" className="uq-header__nav-secondary-link">UQ home</a>
                        </li>
                        <li className="uq-header__nav-secondary-item">
                            <a href="https://www.uq.edu.au/news/" rel="external" className="uq-header__nav-secondary-link">News</a>
                        </li>
                        <li className="uq-header__nav-secondary-item">
                            <a href="https://www.uq.edu.au/uq-events" rel="external" className="uq-header__nav-secondary-link">Events</a>
                        </li>
                        <li className="uq-header__nav-secondary-item">
                            <a href="https://alumni.uq.edu.au/giving" rel="external" className="uq-header__nav-secondary-link">Give</a>
                        </li>
                        <li className="uq-header__nav-secondary-item">
                            <a href="https://contacts.uq.edu.au/" rel="external" className="uq-header__nav-secondary-link">Contact</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
}

Header.displayName = 'Header';
