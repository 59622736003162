import { MapEx } from "MazeMapTypes";
import React, { ReactElement } from "react";
import MapBoxSource from "../source/MapBoxSource";
import MapBoxLayer from "./MapBoxLayer";
import { GeoJSONSourceRaw } from "mapbox-gl";

/* eslint-disable @typescript-eslint/no-magic-numbers  */

interface HeatmapLayerProps {
  map: MapEx;
  geojson: GeoJSON.FeatureCollection<GeoJSON.Point, { heatValue: number; z: number }>;
  layerName: string;
  visible: boolean;
  minZoom?: number;
  maxZoom?: number;
  mapzLevel: number;
  maximumValue: number;
}

export class HeatmapLayer extends React.Component<HeatmapLayerProps> {

  private readonly dataSource: GeoJSONSourceRaw;

  private readonly heatmapFeatureLayerName: string;

  constructor(props: HeatmapLayerProps) {
    super(props);

    this.dataSource = {
      type: "geojson",
      data: props.geojson
    };
    this.heatmapFeatureLayerName = `${props.layerName}features`;
  }

  public shouldComponentUpdate(): boolean {
    return true;
  }

  public updateDataSource(): void {
    const { geojson } = this.props;
    this.dataSource.data = geojson;
  }

  public componentWillUnmount(): void {
    this.clearFeatures();
  }

  private clearFeatures(): void {
    this.dataSource.data = { type: "FeatureCollection", features: [] };
  }

  public componentDidUpdate = (): void => {
    this.updateLayerVisibility();
  };

  public render = (): ReactElement => {
    const { layerName, map, visible, minZoom, maxZoom, mapzLevel, maximumValue } = this.props;
    const src = `${layerName}src`;
    const visiblilityValue = visible ? "visible" : "none";

    this.updateDataSource();
    return (
      <>
        <MapBoxSource map={map} id={src} geoJsonSource={this.dataSource} />
        <MapBoxLayer
          map={map}
          id={layerName}
          sourceId={src}
          type={"heatmap"}
          layout={{ visibility: visiblilityValue }}
          paint={
            {
              "heatmap-weight": {
                property: "heatValue",
                type: "exponential",
                stops: [
                  [1, 0],
                  [maximumValue, 1]
                ]
              },
              "heatmap-color": [
                "interpolate",
                ["linear"],
                ["heatmap-density"],
                0,
                "rgba(0,255,0,0.2)",
                0.1,
                "green",
                0.25,
                "yellow",
                0.5,
                "orange",
                0.75,
                "red"
              ],
              "heatmap-intensity": {
                stops: [
                  [11, 1],
                  [22, 5]
                ]
              },
              // Adjust the heatmap radius by zoom level
              "heatmap-radius": ["interpolate", ["linear"], ["zoom"], 10, 1, 22, 100]
            } as never
          }
          minZoom={minZoom}
          maxZoom={maxZoom}
          filter={["==", ["get", "z"], mapzLevel]}
        />
      </>
    );
  };

  private updateLayerVisibility(): void {
    try {
      const { map, visible, layerName } = this.props;
      const visiblilityValue = visible ? "visible" : "none";
      map.setLayoutProperty(layerName, "visibility", visiblilityValue);
    } catch (ex) {
      console.info(ex);
    }
  }
}
