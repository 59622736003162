import { createTransform } from "redux-persist";
import { MapState } from "./models/MapState";
import { Transform } from "redux-persist/es/types";

export const TransformMapState: Transform<MapState, MapState, MapState, MapState> = createTransform(
  (inboundState: MapState, key: keyof MapState): MapState => {
    switch (key) {
      case "campusId":
        return inboundState;
      default:
        return { ...inboundState };
    }
  },
  (outboundState: MapState, key: keyof MapState): MapState => {
    switch (key) {
      case "campusId":
        return outboundState;
      default:
        return { ...outboundState };
    }
  },
  {
    whitelist: ["campusId"]
  }
);
