import React, { ReactElement } from "react";
import classnames from "classnames";
import "./ModalHeader.scss";

interface ModalHeaderProps {
  borderBottom?: boolean;
  className?: string;
  children?: React.ReactNode;
}

export default function ModalHeader(props: ModalHeaderProps): ReactElement {
  // Props
  const { className: classNameProp, children = null, borderBottom = false } = props;

  const extendedClasses = classnames("uq-modal-header", { "uq-modal-header--border": borderBottom }, classNameProp);

  return <header className={extendedClasses}>{children}</header>;
}
