import React, { ReactNode } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../app/store";
import { Dispatch, bindActionCreators } from "@reduxjs/toolkit";
import { pulsingMarker } from "../image/pulsingMarker";
import { IUserCoordinates } from "../tracking/geolocateSlice";
import CompassIcon from "../../assets/compass_icon.svg";
import MapBoxImage from "../image/MapBoxImage";
import MapBoxSource from "../source/MapBoxSource";
import MapBoxLayer from "../layers/MapBoxLayer";
import { GeoJSONSourceRaw } from "mapbox-gl";
import { MapboxImage, MapEx } from "MazeMapTypes";
import { Feature } from "geojson";

const MARKER_SIZE = 150;
const COMPASS_ICON_ID = "userLocationOrientation";
const LOCATION_MARKER_ID = "userLocationMarker";

interface UserMarkerProps extends PropsFromRedux {
  map: MapEx;
}

class UserMarker extends React.Component<UserMarkerProps> {
  private readonly pulsingMaker: MapboxImage;

  private readonly featureCollection: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
    type: "FeatureCollection",
    features: []
  };

  private readonly dataSource: GeoJSONSourceRaw;

  constructor(props: UserMarkerProps) {
    super(props);

    this.pulsingMaker = pulsingMarker(props.map, MARKER_SIZE);
    this.dataSource = {
      type: "geojson",
      data: this.featureCollection
    };
  }

  public componentWillUnmount(): void {
    this.clearFeatures();
  }

  private updateDataSource(umProps: UserMarkerProps): void {
    const { angle } = this.props;

    if (umProps.coordinates) {
      // console.log(
      //   `### USERMARKER -> re-rendering -> coordinates: lat(${props.coordinates.latitude})
      //              lng(${props.coordinates.longitude})`,
      // );
      this.featureCollection.features = UserMarker.createFeatures(umProps.coordinates, angle ?? 0);
      this.dataSource.data = this.featureCollection;
    }
  }

  private clearFeatures(): void {
    this.featureCollection.features = [];
    this.dataSource.data = this.featureCollection;
  }

  private static createFeatures(coordinates: IUserCoordinates, angle: number): Feature[] {
    return [
      UserMarker.createFeature(coordinates, COMPASS_ICON_ID, angle),
      UserMarker.createFeature(coordinates, LOCATION_MARKER_ID, angle)
    ];
  }

  private static createFeature(coordinates: IUserCoordinates, iconkey: string, angle: number): Feature {
    return {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [coordinates.longitude, coordinates.latitude]
      },
      properties: { rotate: angle, icon: iconkey }
    };
  }

  public shouldComponentUpdate(nextProds: UserMarkerProps): boolean {
    const { watching } = this.props;
    if (watching) {
      this.updateDataSource(nextProds);
    } else {
      this.clearFeatures();
    }
    return true;
  }

  public render(): ReactNode {
    // console.log('### USERMARKER -> re-rendering -> angle: ', this.props.angle);
    const { map } = this.props;
    const src = "userLocationsrc";

    return (
      <MapBoxSource
        map={map}
        id={src}
        geoJsonSource={this.dataSource}
        render={(): ReactNode => <>
          <MapBoxLayer
            map={map}
            id={"userLocation"}
            sourceId={"userLocationsrc"}
            type={"symbol"}
            layout={{
              "icon-image": ["get", "icon"],
              "icon-size": 0.75,
              "icon-rotate": ["get", "rotate"],
              "icon-rotation-alignment": "map",
              "icon-allow-overlap": true
            }}
          />
          <MapBoxImage map={map} svg={{ source: CompassIcon, width: 25, height: 25 }} id={COMPASS_ICON_ID} />
          <MapBoxImage map={map} data={this.pulsingMaker} id={LOCATION_MARKER_ID} />
        </>}
      />
    );
  }
}

const mapStateToProps = (
  state: RootState
): {
  coordinates: IUserCoordinates | null;
  watching: boolean;
  angle: number | null;
} => {
  return {
    coordinates: state.geolocator.coordinates,
    watching: state.geolocator.watch,
    angle: state.geolocator.angle
  };
};

function mapDispatchToProps(dispatch: Dispatch): {
  dispatch: Dispatch;
} {
  return {
    dispatch,
    ...bindActionCreators({}, dispatch)
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(UserMarker);

type PropsFromRedux = ConnectedProps<typeof connector>;
