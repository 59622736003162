import React, { ReactElement, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchCategories, setCampusId, zLevelForLevelZero } from "../../store/map/mapSlice";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { useHistory } from "react-router-dom";
import "./CustomMap.scss";
import coreStyles from "../../coreStyles.module.scss";
import { useRootSelector } from "../../app/store";
import { LatLng } from "../../app/models/LatLng";
import colours from "../../colours";
import { PoiUtil } from "../../services/PoiUtil";
import { setDropPin } from "../map/dropPin";
import { UrlProcessing } from "../../services/UrlProcessing";
import { GeoJsonUtil } from "../../services/GeoJsonUtil";
import { MapEx } from "MazeMapTypes";
import { updateFollowing } from "../tracking/geolocateSlice";
import { updateSearchTerm } from "../../store/search/searchSlice";
import MazeMapDataService from "../../services/MazeMapDataService";
import { ItemDetail } from "../../app/models/ItemDetail";
import { MapMouseEvent } from "mapbox-gl";
import { Point, Polygon, Position } from "geojson";

interface CustomMapProps {
  id: string;
  map: MapEx;
  onCampusChange?(visibility: boolean): void;
  onMapViewPortChanged?: ActionCreatorWithPayload<{
    centre?: LatLng;
    zoom?: number;
    zLevel?: number;
    rotation?: number;
    pitch?: number;
  }>;
}

function getCenterFromCoordinates(coordinates: Position[][]): mapboxgl.LngLat {
  const flat1 = coordinates.flat();
  const lats = flat1.map((p) => p[1]);
  const lngs = flat1.map((p) => p[0]);

  const campusBounds = Mazemap.mapboxgl.LngLatBounds.convert([
    Math.min(...lngs),
    Math.min(...lats),
    Math.max(...lngs),
    Math.max(...lats)
  ]);

  return campusBounds.getCenter();
}

export function CustomMap(props: CustomMapProps): ReactElement {
  const history = useHistory();
  const dispatch = useDispatch();

  const { map, onMapViewPortChanged, onCampusChange, id } = props;

  const mapData = useRootSelector(s => s.map);
  const searchData = useRootSelector(s => s.search);
  const wayFindingState = useRootSelector(s => s.wayFinding);
  const isMobile = useRootSelector(s => s.settings.useMobileLayout);
  const fixed = useRootSelector(s => s.settings.siteFeatures.fixed);

  const searchWidth = parseInt(coreStyles.desktopSearchWidth);

  const { campusId, centre, zoom, zLevel, rotation, pitch, bounds } = mapData;

  const { selectedCategory, isSearching } = searchData;

  const userBeingFollowed = useRootSelector(s => s.geolocator.following);

  useEffect(() => {
    const onMoveEndSwitchCampusId = (): void => {
      const itemsInCenterOfMap = Mazemap.Util.getMapClickData(map, map.getCenter());
      if (itemsInCenterOfMap.campusIds.length && campusId !== itemsInCenterOfMap.campusIds[0]?.toString()) {
        dispatch(updateSearchTerm(""));

        const campusId = itemsInCenterOfMap.campusIds[0]?.toString();
        dispatch(setCampusId(campusId));
        dispatch(fetchCategories(MazeMapDataService, campusId));
        onCampusChange?.(true);
      }
    };
    map.on("moveend", onMoveEndSwitchCampusId);
    return (): void => {
      map.off("moveend", onMoveEndSwitchCampusId);
    };
  }, [dispatch, map, campusId, onCampusChange]);

  useEffect(() => {
    const currentCentre = map.getCenter();
    const currentZoom = map.getZoom();
    const currentBearing = map.getBearing();
    const currentPitch = map.getPitch();

    const stateCentre = centre;
    const centreLatLngChanged
              = (stateCentre.lat && stateCentre.lat !== currentCentre.lat)
              || (stateCentre.lng && stateCentre.lng !== currentCentre.lng);
    if (!map._userIsInteracting && !map.isMoving()) {
      if (
        centreLatLngChanged
                  || (zoom && zoom !== currentZoom)
                  || pitch !== currentPitch
                  || currentBearing !== rotation
      ) {
        map.flyTo({
          center: stateCentre,
          animate: true,
          zoom: zoom,
          pitch: (pitch as number | undefined) ?? 0,
          bearing: rotation ?? 0,
          maxDuration: 1500
        });
      }
    }
  }, [dispatch, map, centre, zoom, rotation, pitch]);

  useEffect(() => {
    const currentZLevel = map.getZLevel();
    if (zLevel !== currentZLevel) {
      map.setZLevel(zLevel === 0 ? zLevelForLevelZero : zLevel);
    }
  }, [dispatch, map, zLevel]);

  useEffect(() => {
    const basePadding = 50;
    const sidePadding = isMobile ? 0 : searchWidth;

    const padding = {
      top: basePadding,
      bottom: basePadding,
      left: basePadding + sidePadding,
      right: basePadding
    };

    map.setPadding(padding);
  }, [isMobile, map, searchWidth]);

  useEffect(() => {
    if (bounds) {
      map.fitBounds([bounds.ne, bounds.sw]);
    }
  }, [dispatch, map, bounds, searchWidth]);

  useEffect(() => {
    if (userBeingFollowed || isSearching) return;
    UrlProcessing.updateBrowserUrl(
      mapData,
      searchData,
      wayFindingState,
      selectedCategory,
      centre,
      zLevel,
      history,
      new URLSearchParams(window.location.search)
    );
  }, [
    history,
    userBeingFollowed,
    isSearching,
    mapData,
    centre,
    zLevel,
    selectedCategory,
    wayFindingState,
    searchData
  ]);

  useEffect(() => {
    const _updateZLevelControlHeight = (): void => {
      // Update the zLevelControl maxHeight, if it exists

      const SPACING = 50; // 50 pixels account for margins and spacing
      const height = map.getCanvas().clientHeight;
      const maxHeight = height - SPACING;
      map.zLevelControl?.setMaxHeight?.(maxHeight);
    };

    map.on("resize", _updateZLevelControlHeight);
    map.resize();

    return (): void => {
      map.off("resize", _updateZLevelControlHeight);
    };
  }, [map]);

  useEffect(() => {
    // in wayFinding move to Route
    if (wayFindingState.currentRoute && wayFindingState.origin && !wayFindingState.selectedPoint) {
      if ((wayFindingState.origin as ItemDetail).geometry.type === "Point") {
        const pointCoordinates = ((wayFindingState.origin as ItemDetail).geometry as Point).coordinates;
        map.flyTo({
          center: { lng: pointCoordinates[0], lat: pointCoordinates[1] },
          animate: true,
          zoom: 17,
          pitch: 0,
          bearing: 0
        });
      } else if ((wayFindingState.origin as ItemDetail).geometry.type === "Polygon") {
        const polygonCenter = getCenterFromCoordinates(
          ((wayFindingState.origin as ItemDetail).geometry as Polygon).coordinates
        );
        map.flyTo({
          center: polygonCenter,
          animate: true,
          zoom: 17,
          pitch: 0,
          bearing: 0
        });
      }
    }
  }, [dispatch, map, wayFindingState]);


  useEffect(() => {
    const _onMapMoved = (e: { isFlyingTo: unknown }): void => {
      const currentMapState = mapData;
      if (!currentMapState.hasProcessedUrl) return;

      if (map._userIsInteracting) {
        // Stop following user if they moved the map
        dispatch(updateFollowing(false));
      }

      //If the map is animating, don't send updates to the store.
      if (e.isFlyingTo) return;

      if (onMapViewPortChanged) {
        const centre = map.getCenter();
        const zoom = map.getZoom();
        const currentZLevel = map.getZLevel() || 0;
        const currentRotation = map.getBearing();
        const currentPitch = map.getPitch();
        const args = {
          centre: { lat: centre.lat, lng: centre.lng },
          zoom,
          zLevel: currentZLevel,
          rotation: currentRotation,
          pitch: currentPitch
        };

        if (centre.lat || centre.lng || zoom) {
          onMapViewPortChanged(args);
        }
        UrlProcessing.updateBrowserUrl(
          { ...currentMapState, ...args },
          searchData,
          wayFindingState,
          selectedCategory,
          args.centre,
          args.zLevel,
          history,
          new URLSearchParams(window.location.search)
        );
      }
    };

    map.on("moveend", _onMapMoved);
    map.on("zlevel", _onMapMoved);
    return (): void => {
      map.off("moveend", _onMapMoved);
      map.off("zlevel", _onMapMoved);
    };
  }, [dispatch, history, map, mapData, searchData, onMapViewPortChanged, selectedCategory, wayFindingState]);

  useEffect(() => {
    const _onUserInteractionStarted = (): void => {
      map._userIsInteracting = true;
    };

    const _onUserInteractionEnded = (): void => {
      map._userIsInteracting = false;
    };

    map.on("touchstart", _onUserInteractionStarted);
    map.on("mousedown", _onUserInteractionStarted);
    map.on("zoomstart", _onUserInteractionStarted);

    map.on("touchend", _onUserInteractionEnded);
    map.on("mouseup", _onUserInteractionEnded);
    map.on("zoomend", _onUserInteractionEnded);

    return (): void => {
      map.off("touchstart", _onUserInteractionStarted);
      map.off("mousedown", _onUserInteractionStarted);
      map.off("zoomstart", _onUserInteractionStarted);

      map.off("touchend", _onUserInteractionEnded);
      map.off("mouseup", _onUserInteractionEnded);
      map.off("zoomend", _onUserInteractionEnded);
    };
  }, [map]);

  useEffect(() => {
    const onMapClick = (mouseEvent: MapMouseEvent): void => {
      // if event already handled or point is fixed, do not adjust the map.
      if (mouseEvent.defaultPrevented || fixed) {
        return;
      }

      const lngLat = mouseEvent.lngLat;
      const zLevel = map.getZLevel();

      MazeMapDataService.getPoiAt(lngLat, zLevel)
        .then(poi => {
          if (poi) {
            dispatch(setDropPin(poi));
          } else {
            throw new Error(`No POI at ${lngLat}, zoom level ${zLevel}`);
          }
        })
        .catch(e => {
          console.log(e);
          dispatch(setDropPin(PoiUtil.createSelectedPOI(GeoJsonUtil.toLatLng(lngLat), zLevel)));
          return false;
        });
    };

    if (!map.highlighter) {
      map.highlighter = new Mazemap.Highlighter(map, {
        showOutline: true,
        showFill: true,
        outlineColor: colours.secondary,
        fillColor: colours.secondaryLightSolid
      });
    }
    map._clickDispatcher.onClick(onMapClick);

    return (): void => {
      map._clickDispatcher.offClick(onMapClick);
    };
  }, [dispatch, map, fixed, mapData.campusId]);

  const setRef = useCallback(
    (ref: HTMLDivElement | null) => {
      ref?.appendChild(map.getContainer());
    },
    [map]
  );

  return <div id={id} ref={setRef} />;
}
