/*
Adapted from - https://github.com/alex3165/react-mapbox-gl/blob/master/docs/API.md
*/

import * as React from "react";
import { Map } from "mapbox-gl";

interface ImageOptionsType {
  pixelRatio?: number;
  sdf?: boolean;
}

type ImageDataType =
  | HTMLImageElement
  | ArrayBufferView
  | { width: number; height: number; data: Uint8Array | Uint8ClampedArray }
  | ImageData;

interface SvgData {
  source: string;
  width: number;
  height: number;
}

/* eslint react/no-unused-prop-types: 0 */
export interface Props {
  id: string;
  url?: string;
  svg?: SvgData;
  data?: ImageDataType;
  options?: ImageOptionsType;
  onLoaded?(): void;
  onError?(error: Error): void;
  map?: Map;
}

class MapBoxImage extends React.Component<Props> {
  private static removeImage(props: Props): void {
    const { id, map } = props;
    if (map?.getStyle()) {
      map.removeImage(id);
    }
  }

  private loading = false;

  public componentDidMount(): void {
    this.loadImage(this.props);
  }

  public shouldComponentUpdate(): boolean {
    return true;
  }

  public componentDidUpdate(prevProps: Props): void {
    const { id, map } = this.props;

    if (map !== prevProps.map) {
      // Remove image from old map
      MapBoxImage.removeImage(this.props);
    }

    if (prevProps.map && !prevProps.map.hasImage(id)) {
      // Add missing image to map
      this.loadImage(this.props);
    }
  }

  public componentWillUnmount(): void {
    MapBoxImage.removeImage(this.props);
  }

  private loadImage(props: Props): void {
    const {
      map, id, url, data, options, svg, onError,
    } = props;

    if (!map) return;

    if (data) {
      map.addImage(id, data, options);
      this.loaded();
    } else if (url) {
      map.loadImage(url, (error, result) => {
        if (error || !result) {
          if (onError) {
            onError(error ?? new Error("unknown error occured when loading mapbox image"));
          }
          return;
        }
        map.addImage(id, result, options);
        this.loaded();
      });
    } else if (svg && !this.loading) {
      const image = new Image(svg.width, svg.height);
      this.loading = true;
      image.addEventListener("load", (): void => {
        map.addImage(id, image, options);
        this.loading = false;
      });
      image.src = svg.source;
      image.id = id;
    }
  }

  private loaded(): void {
    const { onLoaded } = this.props;
    if (onLoaded) {
      onLoaded();
    }
  }

  public render(): null {
    return null;
  }
}

export default MapBoxImage;
