import { Feature, Geometry, LineString, Point, Polygon, Position } from "geojson";
import { LatLng } from "../app/models/LatLng";
import * as turf from "@turf/turf";
import { IUserCoordinates } from "../features/tracking/geolocateSlice";
import { categoryIconExists, categoryIconPrefix, categoryPrimaryIconPrefix } from "./categoryIcons";
import { TYPE_ICON_PREFIX, TYPE_ICON_PRIMARY_PREFIX } from "./typeIcons";
import { LngLat, LngLatBounds } from "mapbox-gl";
import {
  BUILDING_POI_PROPERTIES, MixedPOIList,
  PointOfInterest,
  POIProperties, SIMPLE_CATEGORY_POI_PROPERTIES,
  SimpleCategoryPOIProperties
} from "../types/UQMapsTypes";

export class GeoJsonUtil {
  public static getMedianValue(numbers: number[]): number {
    const minValue = Math.min(...numbers);
    const maxValue = Math.max(...numbers);
    return (minValue + maxValue) / [minValue, maxValue].length;
  }

  public static getCentre(feature: Feature): number[] {
    return GeoJsonUtil.getGeoCentre(feature.geometry);
  }

  /**
   *
   * @param geometry
   * @returns [lng: number, lat: number]
   */
  public static getGeoCentre(geometry: Geometry): [number, number] {
    let lat, lng: number;
    switch (geometry.type) {
      case "Point":
        [lng, lat] = geometry.coordinates;
        break;
      case "Polygon": {
        const flat = geometry.coordinates.flat(1);
        const lats = flat.map(item => item[1]);
        const medLat = GeoJsonUtil.getMedianValue(lats);
        const lngs = flat.map(item => item[0]);
        const medLng = GeoJsonUtil.getMedianValue(lngs);
        [lng, lat] = [medLng, medLat];
        break;
      }
      case "LineString":
        [lng, lat] = geometry.coordinates[0];
        break;
      default:
        throw new Error(`GeoCentre not implemented for type: ${geometry.type}`);
    }

    return [lng, lat];
  }

  public static toPoint(lngLat: number[]): Point {
    return {
      coordinates: lngLat,
      type: "Point"
    };
  }

  public static toLngLat(position: Position): LngLat {
    return new Mazemap.mapboxgl.LngLat(position[0], position[1]);
  }

  public static toLatLng(lngLat: LngLat): LatLng {
    return { lat: lngLat.lat, lng: lngLat.lng };
  }

  public static toPosition(pt: LatLng): Position {
    return [pt.lng, pt.lat];
  }

  private static extendBounds(bounds: LngLatBounds, position: Position): void {
    bounds.extend(this.toLngLat(position));
  }

  public static getBounds(features: Feature[]): LngLatBounds {
    const bounds: LngLatBounds = new Mazemap.mapboxgl.LngLatBounds();
    features.forEach(feature => {
      switch (feature.geometry.type) {
        case "Point":
          GeoJsonUtil.extendBounds(bounds, feature.geometry.coordinates);
          break;
        case "Polygon":
          feature.geometry.coordinates.flat().forEach(position => {
            GeoJsonUtil.extendBounds(bounds, position as Position);
          });
          break;
        case "LineString":
          feature.geometry.coordinates.forEach(position => {
            GeoJsonUtil.extendBounds(bounds, position as Position);
          });
          break;
        default:
          return [];
      }
    });
    return bounds;
  }

  public static featureToPoint(poi: PointOfInterest): PointOfInterest {
    const center = GeoJsonUtil.getGeoCentre(poi.geometry);
    return {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: center
      },
      properties: poi.properties
    };
  }

  public static toDegrees(angle: number): number {
    const halfCircle = 180;
    return angle * (halfCircle / Math.PI);
  }

  public static toRadians(angle: number): number {
    const halfCircle = 180;
    return angle * (Math.PI / halfCircle);
  }

  /**
   * Get the initial bearing between 2 points
   * adapted from https://www.igismap.com/map-tool/bearing-angle
   */
  public static getBearing(pt1: LatLng, pt2: LatLng): number {
    const circle = 360;
    const y = Math.sin(GeoJsonUtil.toRadians(pt2.lng - pt1.lng)) * Math.cos(GeoJsonUtil.toRadians(pt2.lat));
    const x
      // eslint-disable-next-line no-mixed-operators
      = Math.cos(GeoJsonUtil.toRadians(pt1.lat)) * Math.sin(GeoJsonUtil.toRadians(pt2.lat))
      // eslint-disable-next-line no-mixed-operators
      - Math.sin(GeoJsonUtil.toRadians(pt1.lat))
        * Math.cos(GeoJsonUtil.toRadians(pt2.lat))
        // eslint-disable-next-line no-mixed-operators
        * Math.cos(GeoJsonUtil.toRadians(pt2.lng - pt1.lng));
    const brng = Math.atan2(y, x);
    return (GeoJsonUtil.toDegrees(brng) + circle) % circle;
  }

  public static getLineBearing(line: LineString): number {
    const firstPt = GeoJsonUtil.toLatLng(GeoJsonUtil.toLngLat(line.coordinates[0]));
    const nextPt = GeoJsonUtil.toLatLng(GeoJsonUtil.toLngLat(line.coordinates[1]));
    return GeoJsonUtil.getBearing(firstPt, nextPt);
  }

  public static getLineDistance(line: LineString): number {
    const reducer = (acc: number, cur: Position, index: number, src: Position[]): number => {
      if (index === 0) return 0;
      const currll = turf.point(cur);
      const prev = src[index - 1];
      const prevll = turf.point(prev);
      return acc + turf.distance(prevll, currll, { units: "metres" });
    };

    return line.coordinates.reduce(reducer, 0);
  }

  /**
   * gets the absolute angle between 2 bearings
   */
  public static getDirectionChangeAbsAngle(bearing1: number, bearing2: number): number {
    const halfCircle = 180;
    const difference = Math.abs(bearing2 - bearing1);
    if (difference > halfCircle) return difference % halfCircle;
    return difference;
  }

  public static convertFeaturesToPoints = (items: MixedPOIList): Feature<Point>[] => {
    return items.map(item => {
      const { iconImgKey, primaryIconImgKey } = GeoJsonUtil.getFeatureIconImages(item.properties);
      return {
        geometry: GeoJsonUtil.toPoint(GeoJsonUtil.getCentre(item)),
        properties: {
          ...item.properties,
          propertiesJson: JSON.stringify(item.properties),
          originalGeometry: JSON.stringify(item.geometry),
          iconImgKey,
          primaryIconImgKey
        },
        type: "Feature"
      };
    });
  };

  public static getFeatureIconImages = (
    itemProps: POIProperties | SimpleCategoryPOIProperties
  ): { iconImgKey: string; primaryIconImgKey: string } => {
    const poiIconKey = "poi";
    const buildingIconKey = "building";

    // default
    let iconImgKey = TYPE_ICON_PREFIX + poiIconKey;
    let primaryIconImgKey = TYPE_ICON_PRIMARY_PREFIX + poiIconKey; // -> primary color icon / used when poi selected

    if (itemProps.type === SIMPLE_CATEGORY_POI_PROPERTIES) {
      if (categoryIconExists(itemProps.category.id)) {
        iconImgKey = categoryIconPrefix + itemProps.category.id;
        primaryIconImgKey = categoryPrimaryIconPrefix + itemProps.category.id;
      }
    } else if (itemProps.categories?.length) {
      // use the category type
      let workingIconImgKey = iconImgKey;
      for (const category of itemProps.categories) {
        if (categoryIconExists(category.id)) {
          workingIconImgKey = categoryIconPrefix + category.id;
          primaryIconImgKey = categoryPrimaryIconPrefix + category.id;
          if (workingIconImgKey !== iconImgKey) {
            iconImgKey = workingIconImgKey;
            break;
          }
        }
      }
    } else if (itemProps.type === BUILDING_POI_PROPERTIES) {
      iconImgKey = TYPE_ICON_PREFIX + buildingIconKey;
      primaryIconImgKey = TYPE_ICON_PRIMARY_PREFIX + buildingIconKey;
    }
    return { iconImgKey, primaryIconImgKey };
  };

  public static sortFeaturesByProximity<T extends Feature>(coordinates: IUserCoordinates, pois: T[]): T[] {
    const myLocation = turf.point([coordinates.longitude, coordinates.latitude]);
    return pois
      .map(item => {
        const thisLocation = turf.point(GeoJsonUtil.getGeoCentre(item.geometry));
        const distanceFromMe = turf.distance(myLocation, thisLocation, { units: "metres" });
        return { item, distanceFromMe };
      })
      .sort((a, b) => {
        return a.distanceFromMe - b.distanceFromMe;
      })
      .map(x => x.item);
  }

  public static isPointInPolygon(point: Point, polygon: Polygon): boolean {
    return turf.booleanPointInPolygon(point, polygon);
  }
}
