import { AbsoluteOrientationSensor } from "motion-sensors-polyfill";
import _ from "lodash";

export interface IPositionTracker {
  stopWatchingGeolocation(): void;
  stopWatchingOrientation(): void;
  watchPosition(
    onNewPosition: (coords: GeolocationCoordinates) => void,
    errorCallback: (err: GeolocationPositionError) => void
  ): void;
  getPosition(
    coordinatesCallback: (coords: GeolocationCoordinates) => void,
    errorCallback: (err: GeolocationPositionError) => void
  ): void;
  watchOrientation(orientationCallback: (angle: number) => void): void;
}

const gpsMaxAge = 1000; // time in ms
const orientationUpdateFrequency = 8; // Updates per seconds
const throttleSettings = {
  leading: true,
  trailing: true
};
export default class GeoLocationPositionTracker implements IPositionTracker {
  private sensor: AbsoluteOrientationSensor | null;

  private watchId: number | null;

  private readonly supportsGeoLocation: boolean;

  private readonly supportsOrientationChange: boolean;

  private readonly enableHighAccuracy: boolean;

  constructor(enableHighAccuracy = true) {
    this.watchId = null;
    this.sensor = null;
    this.enableHighAccuracy = enableHighAccuracy;
    this.supportsGeoLocation = Boolean(navigator.geolocation);
    this.supportsOrientationChange = GeoLocationPositionTracker.checkOrientationSupport();
  }

  private static checkOrientationSupport(): boolean {
    if (typeof window.DeviceOrientationEvent !== "undefined") {
      const eventDirtyCast = window.DeviceOrientationEvent as unknown as {
        requestPermission(deprecatedCallback?: NotificationPermissionCallback): Promise<NotificationPermission>;
      };
      if (typeof eventDirtyCast.requestPermission === "function") {
        //ios permissions
        eventDirtyCast
          .requestPermission()
          .then((permissionState: string) => {
            if (permissionState === "granted") {
              return true;
            }
          })
          .catch(console.error);
      } else {
        return true;
      }
    }
    return false;
  }

  public stopWatchingGeolocation(): void {
    if (this.watchId !== null) {
      navigator.geolocation.clearWatch(this.watchId);
    }
  }

  public stopWatchingOrientation(): void {
    if (this.sensor !== null) {
      this.sensor.stop();
    }
  }

  private static getAngleFromReading(quaternion: number[]): number {
    const q = quaternion;

    const degreesInOneRotation = 360;

    let alpha
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers,no-mixed-operators
            = Math.atan2(2 * q[0] * q[1] + 2 * q[2] * q[3], 1 - 2 * q[1] * q[1] - 2 * q[2] * q[2]) * (180 / Math.PI);
    if (alpha < 0) alpha = degreesInOneRotation + alpha;
    return degreesInOneRotation - alpha;
  }

  public watchOrientation(orientationCallback: (angle: number) => void): void {
    const options = { frequency: orientationUpdateFrequency };
    this.sensor = new AbsoluteOrientationSensor(options);
    this.sensor.addEventListener("reading", e => {
      const dirtyCastedEvent = e as unknown as {
        target: {
          quaternion: number[];
        };
      };
      const angle = GeoLocationPositionTracker.getAngleFromReading(dirtyCastedEvent.target.quaternion);
      // console.log('### orient sensor -> angle : ', angle);
      orientationCallback(angle);
    });
    this.sensor.start();
  }

  public watchPosition(
    coordinatesCallback: (coords: GeolocationCoordinates) => void,
    errorCallback: (err: GeolocationPositionError) => void
  ): void {
    if (this.watchId === null) {
      const options = {
        enableHighAccuracy: this.enableHighAccuracy,
        maximumAge: gpsMaxAge
      };
      const throttledCallback = _.throttle(coordinatesCallback, gpsMaxAge, throttleSettings);
      this.watchId = navigator.geolocation.watchPosition(
        function (position) {
          // console.log('### navigator.geolocation.watchPosition - position: ', position);
          throttledCallback(position.coords);
        },
        function (error) {
          console.log(error);
          errorCallback(error);
        },
        options
      );
    }
  }

  public getPosition(
    coordinatesCallback: (coords: GeolocationCoordinates) => void,
    errorCallback: (err: GeolocationPositionError) => void
  ): void {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        coordinatesCallback(position.coords);
      },
      function (error) {
        console.log(error);
        errorCallback(error);
      }
    );
  }
}
