import colours from "../../colours";
import { MapboxImage, MapEx } from "MazeMapTypes";

export const pulsingMarker = (map: MapEx, size: number): MapboxImage => {
  let context = map.context;

  const DATA_MULTIPLIER = 4;

  return {
    width: size,
    height: size,
    data: new Uint8Array(size * size * DATA_MULTIPLIER),

    // get rendering context for the map canvas when layer is added to the map
    onAdd: function (): void {
      context = createCanvas(context, this.width, this.height);
    },

    // called once before every frame where the icon will be used
    render: function (): boolean {
      const duration = 1500;
      const t = (performance.now() % duration) / duration;
      const centreDivisor = 2;
      const xCenter = this.width / centreDivisor;
      const yCenter = this.height / centreDivisor;
      const radiusFactor = 0.3;
      const radius = (size / centreDivisor) * radiusFactor;
      const outerRadiusFactor = 0.2;
      const outerRadius = ((size / centreDivisor) * outerRadiusFactor * t) + radius;

      context.clearRect(0, 0, this.width, this.height);

      // draw outer circle
      addCircle(context, xCenter, yCenter, outerRadius, colours.secondaryLight);

      // draw inner circle
      addCircle(context, xCenter, yCenter, radius, colours.secondary);

      // update this image's data with data from the canvas
      this.data = context.getImageData(0, 0, this.width, this.height).data;

      // continuously repaint the map, resulting in the smooth animation of the dot
      map.triggerRepaint();

      // return `true` to let the map know that the image was updated
      return true;
    }
  };
};

function addCircle(
  context: CanvasRenderingContext2D,
  xCenter: number,
  yCenter: number,
  radius: number,
  fillColor: string
): void {
  const startAngle = 0;
  const endAngleMultiplier = 2;
  const endAngle = Math.PI * endAngleMultiplier;
  context.beginPath();
  context.arc(xCenter, yCenter, radius, startAngle, endAngle);
  context.fillStyle = fillColor;
  context.fill();
}

function createCanvas(context: CanvasRenderingContext2D, width: number, height: number): CanvasRenderingContext2D {
  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  return canvas.getContext("2d") ?? context;
}
