import { IProvideLiveData } from "./IProvideLiveData";
import Api from "./ApiService";
import { ILiveDataSet } from "./ILiveDataSet";
import { ItemStatusDetailI, ItemStatusI, ParkingPoiI } from "../util/types";
import { getCalcLiveData } from "../features/live/liveDataUtil";
import { LiveDataCategoryType } from "./LiveDataCategoryType";

export class LiveParking implements IProvideLiveData {

  public static categoryName = "Parking";

  public static categoryId = LiveDataCategoryType.PARKING;

  public async getLiveData(campusId: string): Promise<ILiveDataSet> {
    const parkingData = await Api.getParkingData(campusId);
    const parkingStatusData = parkingData.map((parkingPoi: ParkingPoiI): ItemStatusI => {
      let status: ItemStatusDetailI | undefined;
      if (parkingPoi.status) {
        const total = parkingPoi.status.availability;
        const currentAvailability = parkingPoi.status.current_availability;
        const free = Number(currentAvailability);
        let used;
        if (isNaN(free)) {
          used = total;
        } else {
          used = total - free;
        }
        status = {
          total,
          free: currentAvailability,
          used
        };
      }
      return {
        poiId: parkingPoi.poiId,
        name: parkingPoi.title,
        status,
        statusesDetail: parkingPoi.status?.statuses
      };
    });

    const liveData = getCalcLiveData(parkingStatusData, LiveParking.categoryName);
    return { data: liveData.data, totals: liveData.totals, categoryId: LiveParking.categoryId };
  }
}
