import { LiveData } from "../../app/models/LiveData";
import { ItemStatusI } from "../../util/types";
import { LiveBuildings } from "../../services/LiveBuildings";
import { LiveComputers } from "../../services/LiveComputers";
import { LiveParking } from "../../services/LiveParking";
import _ from "lodash";

const MAX_PERCENTAGE = 100;

/**
 * value / range. value con be either free or used space
 * @param liveData
 */
export function calculateFreePercentage(liveData: LiveData): number {
  return calculatePercentage(liveData, "free");
}

export function calculateUsedPercentage(liveData: LiveData): number {
  return calculatePercentage(liveData, "used");
}

function calculatePercentage(liveData: LiveData, mode: "used" | "free"): number {
  const dataRange = liveData.max - liveData.min;
  let dataPercent;

  const testNum = liveData[mode];

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (testNum === undefined || testNum < 0) {
    dataPercent = -1; // no data avail
  } else if (dataRange !== 0) {
    dataPercent = testNum / dataRange;
  } else {
    dataPercent = 0;
  }

  return dataPercent;
}


export function isFull(availText?: string): boolean {
  return ["FULL", "NEARLYFULL"].includes(availText?.toUpperCase().replaceAll(" ", "") ?? "");
}
export function getFullText(availText?: string): string {
  return availText?.toUpperCase().replaceAll(" ", "") === "NEARLYFULL" ? "nearly full" : "full";
}
export function getEntityToShow(entityName: string, plural: boolean): string {
  if (entityName === LiveParking.categoryName) return plural ? "parks" : "parking";
  if (entityName === LiveComputers.categoryName) return plural ? "computers" : "computer";
  return "";
}

export function isBuilding(entityName: string): boolean {
  return entityName === LiveBuildings.categoryName;
}

// export function getLiveDataColourForFree(dataPercent: number): string {
//     let colour: string = green[400];
//
//     const yellowMin = 0.5;
//     const yellowMax = 0.75;
//     const orangeMin = 0.25;
//     const orangeMax = 0.5;
//     const redMin = 0;
//     const redMax = 0.25;
//
//     if (dataPercent > yellowMin && dataPercent < yellowMax) colour = yellow[400];
//     if (dataPercent > orangeMin && dataPercent <= orangeMax) colour = orange[400];
//     if (dataPercent >= redMin && dataPercent <= redMax) colour = red[400];
//     if (dataPercent < 0) colour = grey[400]; // not avail data
//
//     return colour;
// }

export function getLiveDataColourForUsed(dataPercent: number): string {
  // the highest the perc, the greener, more free.
  let colour = "#ef5350";

  const orangeMin = 0.5;
  const orangeMax = 0.75;
  const yellowMin = 0.25;
  const yellowMax = 0.5;
  const greenMin = 0;
  const greenMax = 0.25;

  if (dataPercent > orangeMin && dataPercent < orangeMax) colour = "#ffa726";
  if (dataPercent > yellowMin && dataPercent <= yellowMax) colour = "#ffee58";
  if (dataPercent >= greenMin && dataPercent <= greenMax) colour = "#66bb6a";
  if (dataPercent < 0) colour = "#bdbdbd"; // not avail data

  return colour;
}

// @TODO this method is a mess, needs refactor
export function getCalcLiveData(
  statusData: ItemStatusI[],
  entityName: string
): { data: { [id: string]: LiveData }; totals: Omit<LiveData, "poi"> } {
  const updated = new Date().toISOString();
  const data: { [id: string]: LiveData } = {};
  let aggregateMax = 0;
  const aggregateMin = 0;
  let aggregateFree = 0;
  let aggregateUsed = 0;

  statusData.forEach((item: ItemStatusI) => {
    let max;
    let free;
    let used;
    let phrase;
    const status = item.status;

    if (!status) {
      phrase = "No availability information";
      free = -1;
      max = -1;
      used = -1;
    } else {
      used = status.used;
      free = Number(status.free);
      if (isNaN(free)) {
        let statusStr;
        if (isFull(status.free as string)) {
          statusStr = getFullText(status.free as string);
        } else {
          statusStr = "N/A";
        }
        // TODO: hardcoded total just for rendering calculations
        max = status.total ? status.total : MAX_PERCENTAGE;
        free = 0;
        phrase = `${getEntityToShow(entityName, false)} is ${statusStr}`;
      } else {
        aggregateFree += free;
        aggregateUsed += used;
        if (status.total) {
          max = status.total;
          const usedPerc = max > 0 ? ((used / max) * MAX_PERCENTAGE).toFixed(0) : "N/A";
          phrase = `${free} of ${max} ${getEntityToShow(entityName, true)} available. ${usedPerc}% full.`;
          aggregateMax += max;
        } else {
          phrase = `${free} ${getEntityToShow(entityName, free > 1)} available.`;
          max = 0;
        }
      }
      if (isBuilding(entityName)) {
        phrase = `${used} people inside (allowed: ${max}). ${Math.round((used / max) * MAX_PERCENTAGE)}% full.`;
      }
    }

    phrase = _.upperFirst(phrase);

    data[item.poiId.toString()] = {
      poi: item.poiId,
      min: 0,
      max,
      free,
      used,
      phrase,
      timestamp: updated
    };
  });

  const totals = {
    min: aggregateMin,
    max: aggregateMax,
    free: aggregateFree,
    used: aggregateUsed,
    phrase: "Availabilty",
    timestamp: updated
  };

  return { data, totals };
}

// export const getStatusesDetailsPhrase = (statuses: any[]) => {
//     const statusesMessages = statuses.map((status: any) => {
//         return `${status.uqIdentifier}: ${status.current_availability}`;
//     });
//
//     return `(${statusesMessages})`;
// };
