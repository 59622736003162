import React, { forwardRef, ReactElement, useState } from "react";
import styles from "./searchResults.module.scss";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { RootState, store } from "../../../app/store";
import { SearchState } from "../../../app/models/SearchState";
import { ReactComponent as Campus } from "../../../assets/Icons/LightPurple/buildings-2.svg";
import { ReactComponent as ShareIcon } from "../../../assets/share_link.svg";
import {
  closeItemDetails,
  moveMapTo,
  openNotification,
  setItemDetails,
  setUrlCopyOpen
} from "../../../store/map/mapSlice";
import { pushLayer } from "../../../store/layers/layerSlice";
import InfiniteScroll from "react-infinite-scroller";
import {
  beginLoading,
  categorySearchResults,
  fetchCategoryResults,
  fetchCategoryResultsForMap,
  fetchSearchResults,
  SEARCH_RESULTS_LAYER_NAME,
  selectSearchResult
} from "../../../store/search/searchSlice";
import { getPrimaryCategoryIcon } from "../../../services/categoryIcons";
import { IUserCoordinates, updateFollowing, updateWatch } from "../../tracking/geolocateSlice";
import { getPrimaryTypeIcon } from "../../../services/typeIcons";
import { AnalyticsHandler } from "../../../services/analytics/google-analytics/AnalyticsHandler";
import { AnalyticsOptionsImpl } from "../../../services/analytics/google-analytics/AnalyticsOptions";
import LiveDataIndicator from "../../live/LiveDataIndicator";
import { BUILDING_POI_PROPERTIES, Category, INDOOR_POI_PROPERTIES, PointOfInterest } from "../../../types/UQMapsTypes";
import { ReactComponent as GpsIconLocked } from "../../../assets/icons8_target_locked.svg";
import { ReactComponent as GpsIcon } from "../../../assets/icons8_target_1.svg";
import { ReactComponent as MapSearch } from "../../../assets/Icons/maps-search.svg";
import {
  clearSearchResults,
  endSelection,
  fetchWayFindingSearchResults,
  selectDestination,
  selectedLocation
} from "../../../store/wayFinding/wayFindingSlice";
import { PoiUtil } from "../../../services/PoiUtil";
import { CHOOSE_ON_MAP, CURRENT_LOCATION, PROGRESS } from "../../../constants";
import { LiveData } from "../../../app/models/LiveData";
import IconButton from "../../../components/IconButton";
import { UrlProcessing } from "../../../services/UrlProcessing";
import copy from "clipboard-copy";

interface SearchResultsProps {
  panelOpen: boolean;

  clickPanelSection(visibility: boolean): void;

  searchType: "search" | "wayfinding";
}

// eslint-disable-next-line react/display-name,max-len
const SearchResults = forwardRef<HTMLDivElement, SearchResultsProps>((props: SearchResultsProps, ref: any): ReactElement => {
  const dispatch = useDispatch();
  const { clickPanelSection, searchType } = props;
  const isWayFinding = searchType === "wayfinding";

  const [isWatching, setIsWatching] = useState(false);
  const state = useSelector((state: RootState) => state);
  const searchState = isWayFinding ? state.wayFinding.pointSelection : state.search;
  const isShowingCategoryResults = isWayFinding ? false : (searchState as SearchState).isShowingCategoryResults;

  const categories = useSelector((state: RootState) => state.map.categories);
  const wayFinding = useSelector((state: RootState) => state.wayFinding);
  const currentRoute = wayFinding.currentRoute;
  const isSelectingOrigin = wayFinding.isSelectingOrigin;
  const watching = useSelector((state: RootState) => state.geolocator.watch);

  const { hasNoResults, isLoading: isLoadingState, searchResults, searchTerm } = searchState;
  const categoryId = isWayFinding ? null : (searchState as SearchState).selectedCategory;
  const isLoading = isWayFinding ? false : isLoadingState;
  const { currentPage, hasMore } = searchResults;
  const nextPage = currentPage + 1;
  const gps = useSelector((state: RootState) => state.geolocator);
  const openItemDetail = useSelector((state: RootState) => state.map.openItemDetail);

  const searchError = isWayFinding ? "" : (searchState as SearchState).searchError;
  const coordinates = gps.coordinates;
  const watchPending = gps.pending;

  const { campusId, centre } = state.map;
  const { useLandscapeLayout: isLandscape } = state.settings;
  const liveData = useSelector((state: RootState) => state.live.liveData);
  const showMobileItemDetails = useSelector((state: RootState) => state.search.showMobileItemDetails);
  const embed = useSelector((state: RootState) => state.settings.siteFeatures.embed);

  const loader = (
    <div className={styles.loadingIndicator} key={0}>
      <IconButton ariaLabel={PROGRESS} className={classnames("uq-loading-spinner", styles.progressIcon)} />
    </div>
  );

  function resultsIcon(categoryTypes: Category[], type: string): ReactElement {
    const catTypes = categoryTypes.length > 0 ? categoryTypes : [];

    let iconSrc = getPrimaryTypeIcon(type);

    if (type !== BUILDING_POI_PROPERTIES) {
      // try all the category IDs in the list until we get an icon that isn't the default one.
      for (const cat of catTypes) {
        const catIcon = getPrimaryCategoryIcon(cat.id);
        if (catIcon !== iconSrc) {
          iconSrc = catIcon;
          break;
        }
      }
    }
    return <img src={iconSrc} className={styles.poiPicture} alt={"Poi icon"} />;
  }

  const locationCurrent = (): false | JSX.Element => {
    return isWayFinding && isLandscape && (
      <li
        className={classnames(styles.poiItem, isWatching ? styles.pulsate : "")}
        onClick={myLocation}
        onKeyPress={myLocation}
        tabIndex={0}
      >
        <span className={classnames(styles.gpsIcon, styles.poiPicture)}>
          {watching ? <span className={watchPending ? styles.gpsPending : ""}><GpsIconLocked /> </span> : <GpsIcon />}
        </span>
        <div className={styles.poiItemText}>
          <span className={classnames(styles.poiName, styles.m0)}>{CURRENT_LOCATION}</span>
        </div>
      </li>
    );
  };

  const locationOnMap = (): any => {
    return (
      isWayFinding && isLandscape && (
        <li
          className={styles.poiItem}
          onClick={(): void => clickPanelSection(false)}
          onKeyPress={(): void => clickPanelSection(false)}
          tabIndex={0}
        >
          <span className={classnames(styles.poiPicture)}>
            <span className={styles.mapIcon}><MapSearch /></span>
          </span>
          <div className={styles.poiItemText}>
            <span className={classnames(styles.poiName, styles.m0)}>{CHOOSE_ON_MAP}</span>
          </div>
        </li>
      )
    );
  };
  const categoriesAppliedFilter = categories.filter(
    category => !searchTerm || category.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // click on category item
  function handleCategoryClick(categoryId: string, categoryTitle: string): void {
    if (!isShowingCategoryResults) {
      if (isLandscape) clickPanelSection(false);
      AnalyticsHandler.handleEvent(new AnalyticsOptionsImpl("Custom click", "CategoryList", "Click", categoryTitle));
      dispatch(updateFollowing(false));
      dispatch(categorySearchResults({ results: [], categoryTitle, categoryId, pageNumber: 0 }));
      dispatch(beginLoading());
      if (campusId) {
        dispatch(fetchCategoryResults(campusId, categoryId, categoryTitle, 0));
        dispatch(fetchCategoryResultsForMap(campusId, categoryId));
      }
    }
  }

  function selectItem(newItem: PointOfInterest): void {
    dispatch(setItemDetails(newItem));
  }

  async function handlePoiClick(item: PointOfInterest): Promise<void> {
    if (isLandscape) clickPanelSection(false);
    dispatch(updateFollowing(false));
    const lngLat = Mazemap.Util.getPoiLngLat(item);
    const zoomLevel = 20;
    dispatch(
      moveMapTo({
        centre: { ...lngLat },
        zoom: isShowingCategoryResults ? undefined : zoomLevel,
        zLevel: item.properties.type === INDOOR_POI_PROPERTIES ? item.properties.floorZLevel : 0
      })
    );

    dispatch(
      pushLayer({
        name: SEARCH_RESULTS_LAYER_NAME,
        type: "selectable",
        layerDetails: { items: [item], cluster: false },
        includeInMapControl: false
      })
    );
    dispatch(selectSearchResult());
    await selectItem(item);
  }

  function handlePoiClickWayFinder(item: PointOfInterest): void {
    dispatch(selectedLocation(PoiUtil.toItemDetail(item), isSelectingOrigin ? "origin" : "destination"));
    dispatch(closeItemDetails());
    dispatch(clearSearchResults());
    if (isWayFinding && isSelectingOrigin && !wayFinding.destination) {
      dispatch(selectDestination());
    } else {
      dispatch(endSelection());
    }
  }

  const waitNumber = 100;

  async function myLocation(): Promise<void> {
    if (!watching) {
      dispatch(updateWatch(true));

      // waiting for results
      setIsWatching(true);
      const awaitCoordinates = await setInterval(function() {
        if (store.getState().geolocator.coordinates) {
          clearInterval(awaitCoordinates);
          selectCurrentLocation(store.getState().geolocator.coordinates, isSelectingOrigin);
          setIsWatching(false);
        }
      }, waitNumber);
    }
    if (coordinates) {
      selectCurrentLocation(coordinates, isSelectingOrigin);
    }
  }

  function selectCurrentLocation(coordinates: IUserCoordinates | null, isSelectingOrigin: boolean): void {
    if (!coordinates) {
      return;
    }
    const myLocation = PoiUtil.toItemDetail(
      PoiUtil.createPOI({ lng: coordinates.longitude, lat: coordinates.latitude }, "My Location", "")
    );
    dispatch(selectedLocation(myLocation, isSelectingOrigin ? "origin" : "destination"));
    dispatch(endSelection());
  }

  const poiView = (): ReactElement[] | false => {
    return (
      !currentRoute
      && searchResults.items.map((item) => {
        let buildingName = "";
        if (item.properties.type === BUILDING_POI_PROPERTIES) {
          buildingName = item.properties.title;
        } else if (item.properties.type === INDOOR_POI_PROPERTIES) {
          buildingName = item.properties.buildingName;
        }
        const itemProps = item.properties;
        const dataToShow = liveData[itemProps.id] as LiveData | undefined;

        return (
          <li
            key={itemProps.title}
            className={styles.poiItem}
            onClick={(): void => {
              if (isWayFinding) {
                handlePoiClickWayFinder(item);
              } else {
                handlePoiClick(item);
              }
            }}
            onKeyPress={(): void => {
              if (isWayFinding) {
                handlePoiClickWayFinder(item);
              } else {
                handlePoiClick(item);
              }
            }}
            tabIndex={0}
          >
            {resultsIcon(itemProps.categories ?? [], item.properties.type)}
            <div className={styles.poiItemText}>
              <p className={styles.poiName}>{itemProps.title}</p>
              <p className={styles.secondaryItemText}>
                {getFloorName(item) && (
                  <span>
                    {"Floor "}
                    {getFloorName(item)}
                    {" • "}
                  </span>
                )}
                {buildingName && (
                  <span>
                    <em>{buildingName}</em>
                  </span>
                )}
                {dataToShow && (
                  <>
                    <br />
                    <LiveDataIndicator {...dataToShow} />
                  </>
                )}
              </p>
            </div>
          </li>
        );
      })
    );
  };


  const categoryView = (): ReactElement[] => {
    return categoriesAppliedFilter.map((item) => {
      const live = liveData[item.id] as LiveData | undefined;
      return (
        <li
          key={item.id}
          className={classnames(styles.categoryItem, isShowingCategoryResults ? styles.categoryItemNoActive : "")}
          onClick={(): unknown => handleCategoryClick(item.id, item.title)}
          onKeyPress={(): unknown => handleCategoryClick(item.id, item.title)}
          tabIndex={0}
        >
          <div className={styles.categoryPicture}>
            <img src={getPrimaryCategoryIcon(item.id)} className={styles.categoryIcon} alt={item.title} />
          </div>
          <div className={styles.categoryListItemText}>
            <span>{item.title}</span>
            <div>{live ? <LiveDataIndicator {...live} /> : ""}</div>
          </div>
        </li>
      );
    });
  };

  const handleShareClicked = (): void => {
    const sharedURL = !categoryId ? location.toString()
      : UrlProcessing.getCategoryShareUrl(window.location, state.map, categoryId);
    if (!embed) {
      copy(sharedURL).then(() => dispatch(setUrlCopyOpen(true)));
      dispatch(openNotification("Link copied to clipboard!"));
    } else {
      window.open(sharedURL, "_blank");
    }
  };

  const commonButtonClass = classnames(
    isLandscape && showMobileItemDetails ? "uq-button uq-button--small uq-button--text" : styles.descriptionButton
  );

  const circleClass = classnames(
    !(isLandscape && showMobileItemDetails) ? styles.outlinedButton : styles.hidden
  );

  const categoryShareView = (): ReactElement[] => {
    return categoriesAppliedFilter.map((item) => {
      return (
        <li
          key={`${item.id} share`}
          className={classnames(styles.categoryItem, isShowingCategoryResults ? styles.categoryItemNoActive : "")}
          onClick={handleShareClicked}
          onKeyPress={handleShareClicked}
          tabIndex={0}
        >
          <button className={commonButtonClass} onClick={handleShareClicked}>
            <span className={classnames(circleClass, styles.shareLink)}>
              <ShareIcon />
            </span>
          </button>
          <div className={styles.categoryListItemText}>
            <span>Share</span>
          </div>
        </li>
      );
    });
  };

  const getFloorName = (item: PointOfInterest): string | null => {
    return item.properties.type === INDOOR_POI_PROPERTIES ? item.properties.floorLabel : null;
  };

  return (
    <div className={classnames(styles.resultsListWrapper)} ref={ref}>
      <InfiniteScroll
        pageStart={0}
        loadMore={(): void => {
          if (searchTerm) {
            if (isWayFinding) {
              dispatch(fetchWayFindingSearchResults(campusId ?? "", searchTerm, nextPage, centre));
            } else {
              if (isShowingCategoryResults) {
                dispatch(fetchCategoryResults(campusId ?? "", categoryId ?? "", searchTerm, nextPage));
              }
              dispatch(fetchSearchResults(campusId ?? "", searchTerm, nextPage, centre));
            }
          }
        }}
        hasMore={isWayFinding ? searchResults.hasMore : hasMore}
        loader={loader}
        useWindow={false}
        initialLoad={!isShowingCategoryResults}
      >
        <ul className={styles.resultsList}>
          {/*VIEW of categories*/}
          {!isWayFinding && !openItemDetail && categoryView()}
          {/* share link */}
          {!isWayFinding && !openItemDetail && searchTerm && categoriesAppliedFilter.length !== 0
            && categoryShareView()
          }
          {/* decoration */}
          {!isWayFinding && !openItemDetail && searchTerm && categoriesAppliedFilter.length !== 0 && (
            <li className={styles.divider} />
          )}
          {/* VIEW of poiItem => myCurrentLocation for wayFinding*/}
          {locationCurrent()}
          {/* VIEW of poiItem => choose on map*/}
          {locationOnMap()}
          {/* VIEW of poiItems*/}
          {poiView()}
        </ul>
      </InfiniteScroll>
      {searchTerm && hasNoResults && !isLoading && (
        <div className={styles.noResults}>
          <p>
            <Campus />
          </p>
          <p>
            <strong>{"No results found"}</strong>
            <br />
            {"Try changing your search term."}
          </p>
        </div>
      )}
      {searchError && (
        <div className={styles.noResults}>
          <p>
            {"Search errors:"}
            <br />
            {searchError}
          </p>
        </div>
      )}
    </div>
  );
});
export default SearchResults;
