// MazeMap's unique campus collection ID
export const MAZEMAP_CAMPUS_COLLECTION_ID = "uq";

export const FEEDBACK_RATE_APP = "How would you rate this app";
export const FEEDBACK_OPTIONAL_DETAILS = "Provide optional details";
export const FEEDBACK_SELECT_DEFAULT = "Select feedback type";


export const MOBILE_PLATFORM = "Mobile App Platform";
export const MOBILE_PLATFORM_VERSION = "Mobile App Version";

export const TITLE_ABOUT = "About";
export const TITLE_FEEDBACK = "Submit Feedback";
export const TITLE_PRIVACY = "Privacy & Terms of use";
export const TITLE_OPENSTREETMAP = "© OpenStreetMap";

export const FEEDBACK_RECEIVED = "Your feedback was received. Thank You!";
export const TITLE_SUBMIT = "Submit";

export const PLACEHOLDER_DETAILS = "Enter details...";
export const TITLE_ROTATION = "Map Rotation";
export const TITLE_LAYERS = "Map Layers";
export const TITLE_NAVIGATION = "Map Navigation";

export const SEARCH_BACK = "Back to Categories";

export const SEARCH = "Search";
export const SEARCH_STOP = "Stop Search";
export const SEARCH_IN = "Search in";
export const PROGRESS = "Progress";
export const DIRECTIONS = "Directions";

export const ORIGIN = "Starting point";
export const ORIGIN_SHORT = "origin";
export const DESTINATION = "Destination";
export const DESTINATION_SHORT = "destination";
export const LINK_COPIED = "Link copied to clipboard!";
export const LINK_SHARE = "Share link";
export const CURRENT_LOCATION = "My Location";
export const CHOOSE_ON_MAP = "Choose on map";
export const AVOID_STAIRS = "Avoid stairs";
export const SWITCH_VIEW = "Switch view";
export const ROUTE_IMG = "route path";
export const ROUTE_TITLE = "Detailed route";
export const SWAP_ICON = "Search Field Swap";
export const UQ_ART = "Uq University Brisbane River Artwork";