import React, { ReactNode } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { MapEx } from "MazeMapTypes";
import { ItemDetail } from "../../app/models/ItemDetail";

interface SelectedItemHighlighterProps extends PropsFromRedux {
  map?: MapEx;
}

class SelectedItemHighlighter extends React.Component<SelectedItemHighlighterProps> {
  private isRendering = false;

  public componentWillUnmount(): void {
    const { map } = this.props;
    map?.highlighter?.clear();
  }

  public shouldComponentUpdate(): boolean {
    return !this.isRendering; //avoid re-entrant render.
  }

  public render(): ReactNode {
    const { itemDetail, hasSelection, map } = this.props;
    if (!map?.highlighter) return null;

    this.isRendering = true;
    if (hasSelection && itemDetail && itemDetail.geometry.type === "Polygon") {
      // Highlight the room
      map.highlighter.highlight({
        type: "Feature",
        properties: { ...itemDetail },
        geometry: itemDetail.geometry
      });
    } else {
      map.highlighter.clear();
    }
    this.isRendering = false;
    return null;
  }
}

const mapStateToProps = (state: RootState): {
  itemDetail?: ItemDetail;
  hasSelection: boolean;
} => {
  return {
    itemDetail: state.map.itemDetail,
    hasSelection: state.map.openItemDetail
  };
};

function mapDispatchToProps(dispatch: Dispatch): {
  dispatch: Dispatch;
} {
  return {
    dispatch,
    ...bindActionCreators({}, dispatch)
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(SelectedItemHighlighter);

type PropsFromRedux = ConnectedProps<typeof connector>;
