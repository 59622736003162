import { MapboxImage, MapEx } from "MazeMapTypes";
import { LiveData } from "../../app/models/LiveData";
import { calculateUsedPercentage, getLiveDataColourForUsed } from "../live/liveDataUtil";

export const gradientMarker = (
  map: MapEx,
  size: number,
  liveData: LiveData
  // alternateFill: boolean,
  // poi?: number
): MapboxImage => {

  const DATA_MULTIPLIER = 4;
  let context = map.context;

  return {
    width: size,
    height: size,
    data: new Uint8Array(size * size * DATA_MULTIPLIER),

    // get rendering context for the map canvas when layer is added to the map
    onAdd: function (): void {
      context = createCanvas(context, this.width, this.height);
    },

    // called once before every frame where the icon will be used
    render: function (): boolean {
      const divisor = 2;
      const xCenter = this.width / divisor;
      const yCenter = this.height / divisor;
      const radius = size / divisor;

      context.clearRect(0, 0, this.width, this.height);
      const dataPercent = calculateUsedPercentage(liveData);
      const colour: string = getLiveDataColourForUsed(dataPercent);

      // minimum percentage
      const minPercentage = 0.05;
      const arcSize = dataPercent >= 0 && dataPercent < minPercentage ? minPercentage : dataPercent;

      // draw graded arc
      addCircle(context, xCenter, yCenter, radius, colour, arcSize);

      // update this image's data with data from the canvas
      this.data = context.getImageData(0, 0, this.width, this.height).data;

      // return `true` to let the map know that the image was updated
      return true;
    }
  };
};

function addCircle(
  context: CanvasRenderingContext2D,
  xCenter: number,
  yCenter: number,
  radius: number,
  fillColor: string,
  arcSize: number
): void {
  const startAngleRadians = -0.5;
  const startAngle = startAngleRadians * Math.PI;
  const arcSizeMultiplier = 2;
  const endAngle = startAngle + (Math.PI * arcSizeMultiplier * arcSize);
  context.beginPath();
  context.moveTo(xCenter, yCenter);
  context.lineTo(xCenter, yCenter - radius);
  context.arc(xCenter, yCenter, radius, startAngle, endAngle);
  context.lineTo(xCenter, yCenter);
  context.fillStyle = fillColor;
  context.lineCap = "square";
  context.fill();
}

function createCanvas(context: CanvasRenderingContext2D, width: number, height: number): CanvasRenderingContext2D {
  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  return canvas.getContext("2d") ?? context;
}
