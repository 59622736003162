import React, { ReactElement } from "react";
import classnames from "classnames";
import { Palettes, PalettesType } from "../ModalOverlay";
import "./ModalContent.scss";

interface ModalContentProps {
  children: React.ReactNode;
  className?: string;
  palette?: PalettesType;
}

export default function ModalContent(props: ModalContentProps): ReactElement {
  // Props
  const { className: classNameProp, children = null, palette = null } = props;

  const extendedClasses = classnames(
    "uq-modal-content",
    { [`uq-modal-content--${palette}`]: palette === Palettes.dark || palette === Palettes.light },
    classNameProp
  );

  return <div className={extendedClasses}>{children}</div>;
}
