import React, { ReactElement } from "react";
import styles from "./alertNotification.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { closeNotification } from "../../store/map/mapSlice";
import Modal from "../../components/Modal";
import useTimeout from "../../hooks/useTimeout";
import classnames from "classnames";


export default function AlertNotification(): ReactElement {
  const dispatch = useDispatch();
  const notificationMessage = useSelector((state: RootState) => state.map.notificationMessage);

  const autoHideNotification = (): void => {
    dispatch(closeNotification());
  };

  const hideDurationTime = 5000;
  useTimeout(autoHideNotification, notificationMessage ? hideDurationTime : null);

  return (
    <Modal
      show={Boolean(notificationMessage)}
      onClose={autoHideNotification}
      portalContainer={document.getElementById("root")}
      showOverlay={false}
      className={classnames(styles.alertModal, styles.success)}
      classNameCloseButton={styles.classNameCloseButton}
    >
      <div className={styles.alertModalBody}>
        {notificationMessage}
      </div>
    </Modal>
  );
}
