import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { WayFindingPoint, WayFindingState } from "../../app/models/WayFindingState";
import { ItemDetail } from "../../app/models/ItemDetail";
import { LatLng } from "../../app/models/LatLng";
import { AppThunk } from "../../app/store";
import MazeMapDataService from "../../services/MazeMapDataService";
import { pushLayer, removeLayer, showLayer } from "../layers/layerSlice";
import { GeoJsonUtil } from "../../services/GeoJsonUtil";
import { closeItemDetails, fitToBounds, moveMapTo } from "../map/mapSlice";
import {
  endSearching,
  SEARCH_RESULTS_LAYER_NAME,
  setSearchError
} from "../search/searchSlice";
import { PoiUtil } from "../../services/PoiUtil";
import { AnalyticsOptionsImpl } from "../../services/analytics/google-analytics/AnalyticsOptions";
import { AnalyticsHandler } from "../../services/analytics/google-analytics/AnalyticsHandler";
import { RouteOptions } from "../../app/models/RouteOptions";
import { LatLngRouteArgument, POIIdRouteArgument } from "../../services/MapDataService";
import { BUILDING_POI_PROPERTIES, PointOfInterest, RouteDetail } from "../../types/UQMapsTypes";
import { getLocationLabel } from "../../MapsApp";
import { dropPinLayerName } from "../../features/map/dropPin";

export const SEARCH_PAGE_SIZE = 25;
const routeLayerName = "route";

const initialState: WayFindingState = {
  wayFindingOrigin: "",
  wayFindingDestination: "",
  isWayFinding: false,
  isSelectingOrigin: false,
  isSelectingDestination: false,
  isSelectingPoint: false,
  pointSelection: {
    isLoading: false,
    isSearching: false,
    hasNoResults: false,
    searchTerm: "",
    searchResults: {
      currentPage: -1,
      hasMore: false,
      items: [],
      pageSize: SEARCH_PAGE_SIZE
    },
    searchedCentre: { lat: 0, lng: 0 }
  },
  currentRouteInfoTab: "Route",
  showCurrentRouteDetails: false,
  isChoosingPointOnTheMap: false,
  isRouteLoading: false,
  isNavigating: false,
  navigateClicked: false,
  isPointSelection: true,
  routeOptions: {
    avoidStairs: false
  }
};

const _clearSearchResults = (state: WayFindingState["pointSelection"]): void => {
  state.searchResults.currentPage = -1;
  state.searchResults.hasMore = false;
  state.searchResults.items = [];
  state.hasNoResults = false;
};

export const wayFindingSlice = createSlice({
  name: "wayFinding",
  initialState,
  reducers: {
    clearSearchResults: (state) => {
      _clearSearchResults(state.pointSelection);
    },
    updateSearchTerm: (state, action: PayloadAction<string>) => {
      if (state.pointSelection.searchTerm === action.payload) return;
      state.pointSelection.searchTerm = action.payload;
      state.pointSelection.isLoading = Boolean(action.payload);
      _clearSearchResults(state.pointSelection);
    },
    updateWayFindingOrigin: (state, action: PayloadAction<string>) => {
      if (state.wayFindingOrigin === action.payload) return;
      state.wayFindingOrigin = action.payload;
    },
    updateWayFindingDestination: (state, action: PayloadAction<string>) => {
      if (state.wayFindingDestination === action.payload) return;
      state.wayFindingDestination = action.payload;
    },
    showRouteInfoTab: (state, action: PayloadAction<"Route" | "Details">) => {
      if (state.currentRouteInfoTab !== action.payload) state.currentRouteInfoTab = action.payload;
    },
    beginWayFinding: (
      state,
      action: PayloadAction<{
        origin: ItemDetail | LatLng | undefined;
        destination: ItemDetail | LatLng | undefined;
      }>
    ) => {
      if (state.isWayFinding) return;
      state.isWayFinding = true;
      state.destination = action.payload.destination;
      state.origin = action.payload.origin;
    },
    closeItemDetailsWayfinding: state => {
      state.destination = undefined;
    },
    navigateClicked: state => {
      state.navigateClicked = true;
    },
    navigateReset: state => {
      state.navigateClicked = false;
    },
    endWayFinding: state => {
      state.isWayFinding = false;
      state.destination = undefined;
      state.origin = undefined;
      state.pointSelection.searchTerm = "";
      state.currentRoute = undefined;
      state.isChoosingPointOnTheMap = false;
      state.isSelectingDestination = false;
      state.isSelectingOrigin = false;
      state.isSelectingPoint = false;
      _clearSearchResults(state.pointSelection);
    },
    beginLoading: state => {
      state.pointSelection.isLoading = true;
    },
    endLoading: state => {
      state.pointSelection.isLoading = false;
    },
    beginRouteLoading: state => {
      state.isRouteLoading = true;
    },
    endRouteLoading: state => {
      if (state.isRouteLoading) state.isRouteLoading = false;
      AnalyticsHandler.handleEvent(
        new AnalyticsOptionsImpl("Custom click", "WayFinding", "Click", "RouteLoaded")
      );
    },
    selectOrigin: state => {
      if (!state.isWayFinding) return;
      _clearSearchResults(state.pointSelection);
      state.pointSelection.searchTerm = "";
      state.isSelectingDestination = false;
      state.isSelectingOrigin = true;
    },
    selectDestination: state => {
      if (!state.isWayFinding) return;
      state.isSelectingDestination = true;
      state.isSelectingOrigin = false;
    },
    endSelection: state => {
      state.isSelectingDestination = false;
      state.isSelectingOrigin = false;
      state.isChoosingPointOnTheMap = false;
    },
    setSelectedPoint: (state, action: PayloadAction<WayFindingPoint | undefined>) => {
      if (!state.isWayFinding || state.isSelectingDestination || state.isSelectingOrigin) return;
      state.selectedPoint = action.payload;
      state.isSelectingPoint = true;
      closeItemDetails();
    },
    endPointSelection: state => {
      if (!state.isWayFinding) return;
      state.selectedPoint = undefined;
      state.isSelectingPoint = false;
    },
    setOrigin: (state, action: PayloadAction<WayFindingPoint | undefined>) => {
      if (!state.isWayFinding) return;
      state.origin = action.payload;
      state.isChoosingPointOnTheMap = false;
    },
    setDestination: (state, action: PayloadAction<ItemDetail | LatLng | undefined>) => {
      if (!state.isWayFinding) return;
      state.destination = action.payload;
      state.isChoosingPointOnTheMap = false;
    },
    setCurrentRoute: (state, action: PayloadAction<RouteDetail>) => {
      if (!state.isWayFinding) return;
      state.currentRoute = action.payload;
    },
    /**
     * Show the map so that the user can choose an origin or destination.
     */
    beginChoosingPointOnTheMap: (state) => {
      state.isChoosingPointOnTheMap = true;
    },
    toggleShowCurrentRouteDetails: (state, action: PayloadAction<boolean>) => {
      state.showCurrentRouteDetails = action.payload;
    },
    setIsNavigatingState: (state, action: PayloadAction<boolean>) => {
      state.isNavigating = action.payload;
    },
    setIsPointSelectionState: (state, action: PayloadAction<boolean>) => {
      state.isPointSelection = action.payload;
    },
    searchResults: (
      state,
      action: PayloadAction<{
        searchTerm: string;
        pageNumber: number;
        searchResult: PointOfInterest[];
        searchedCentre: LatLng;
      }>
    ) => {
      const ptSel = state.pointSelection;
      if (ptSel.searchTerm !== action.payload.searchTerm) {
        //Text has been updated before this result returned
        return;
      }
      // clean route on search
      state.currentRoute = undefined;
      ptSel.isLoading = false;
      const resultsToAdd = action.payload.searchResult.filter(feature => Boolean(feature.properties.title.trim()));
      if (action.payload.pageNumber === 0) {
        ptSel.searchResults.items = resultsToAdd;
      } else {
        const currentItems = ptSel.searchResults.items;
        ptSel.searchResults.items = [...currentItems, ...resultsToAdd];
      }
      ptSel.searchResults.hasMore = action.payload.searchResult.length === SEARCH_PAGE_SIZE;
      ptSel.searchResults.currentPage = action.payload.pageNumber;
      ptSel.hasNoResults = !ptSel.searchResults.hasMore && ptSel.searchResults.items.length === 0;
      ptSel.searchedCentre = action.payload.searchedCentre;
    },
    setRouteOptions: (state, action: PayloadAction<RouteOptions>) => {
      state.routeOptions = action.payload;
    }
  }
});

export const {
  beginWayFinding,
  endWayFinding,
  updateWayFindingOrigin,
  updateWayFindingDestination,
  selectOrigin,
  selectDestination,
  setSelectedPoint,
  endPointSelection,
  setOrigin,
  setDestination,
  setCurrentRoute,
  endSelection,
  searchResults,
  clearSearchResults,
  updateSearchTerm,
  beginLoading,
  endLoading,
  showRouteInfoTab,
  toggleShowCurrentRouteDetails,
  beginChoosingPointOnTheMap,
  beginRouteLoading,
  endRouteLoading,
  setIsNavigatingState,
  navigateClicked,
  navigateReset,
  closeItemDetailsWayfinding,
  setRouteOptions,
  setIsPointSelectionState
} = wayFindingSlice.actions;


export const swapOriginAndDestination
  = (): AppThunk<Promise<void>> =>
    async (dispatch, getState): Promise<void> => {
      const { origin, destination, routeOptions } = getState().wayFinding;
      if (origin && !destination) {
        dispatch(setDestination(origin));
        dispatch(setOrigin(undefined));
        dispatch(updateWayFindingOrigin(""));
        dispatch(updateWayFindingDestination(getLocationLabel(origin)));
      } else if (!origin && destination) {
        dispatch(setDestination(undefined));
        dispatch(setOrigin(destination));
        dispatch(updateWayFindingDestination(""));
        dispatch(updateWayFindingOrigin(getLocationLabel(destination)));
      } else {
        dispatch(setDestination(origin));
        dispatch(setOrigin(destination));
        await updateRoute(destination, origin, routeOptions, dispatch);
      }
    };

const isDefaultItemDetail = (itemDetail: ItemDetail): boolean => {
  return itemDetail.itemId === "";
};

export const initiateWayfinding
    = (destination: LatLng | ItemDetail | undefined): AppThunk<Promise<void>> =>
      async (dispatch, getState): Promise<void> => {
        const { routeOptions } = getState().wayFinding;
        const currentLocation = getState().geolocator.coordinates;
        let workingDestination = destination;
        if (destination) {
          const itemDetail = destination as ItemDetail;
          if (itemDetail.itemId && isDefaultItemDetail(itemDetail)) {
            workingDestination = undefined;
          }
        }

        if (currentLocation) {
          const myLocation: LatLng = { lat: currentLocation.latitude, lng: currentLocation.longitude };
          const newOrigin = PoiUtil.toItemDetail(
            PoiUtil.createPOI(myLocation, "Current Location (Click to Change)", "")
          );
          dispatch(beginWayFinding({ origin: newOrigin, destination: workingDestination }));
          await updateRoute(workingDestination, newOrigin, routeOptions, dispatch);
        } else {
          dispatch(beginWayFinding({ origin: undefined, destination: workingDestination }));
        }
      };

export const cleanNavigateClicked
    = (): AppThunk<void> =>
      (dispatch): void => {
        const delay = 2000;
        setTimeout(() => {
          dispatch(navigateReset());
        }, delay);
      };

export const displayRoute
    = (origin: WayFindingPoint, destination: WayFindingPoint): AppThunk<Promise<void>> =>
      async (dispatch, getState): Promise<void> => {
        dispatch(beginWayFinding({ origin, destination }));
        const { routeOptions } = getState().wayFinding;
        await updateRoute(origin, destination, routeOptions, dispatch);
      };

export const dismissWayFinding
    = (): AppThunk<void> =>
      (dispatch): void => {
        dispatch(endWayFinding());
        dispatch(removeLayer(routeLayerName));
      };

export const revertDefaultValue
  = (): AppThunk<void> =>
    (dispatch): void => {
      dispatch(dismissWayFinding());
      dispatch(updateWayFindingOrigin(""));
      dispatch(updateWayFindingDestination(""));
      dispatch(endPointSelection());
    };


export const selectedLocation
    = (location: WayFindingPoint | undefined, kind: "origin" | "destination"): AppThunk<Promise<void>> =>
      async (dispatch, getState): Promise<void> => {
        const wfState = getState().wayFinding;
        const { routeOptions } = wfState;
        let { origin, destination } = wfState;
        switch (kind) {
          case "destination":
            destination = location;
            dispatch(setDestination(location));
            break;
          case "origin":
            origin = location;
            dispatch(setOrigin(location));
            break;
        }
        await updateRoute(origin, destination, routeOptions, dispatch);
      };

export const setAvoidStairs
    = (avoidStairs: boolean): AppThunk<Promise<void>> =>
      async (dispatch, getState): Promise<void> => {
        const { routeOptions, destination, origin } = getState().wayFinding;
        const updatedOptions = { ...routeOptions, avoidStairs };
        dispatch(setRouteOptions(updatedOptions));
        await updateRoute(origin, destination, updatedOptions, dispatch);
      };

export const chooseFromTheMap
    = (): AppThunk<void> =>
      (dispatch, getState): void => {
        const { isSelectingDestination, isSelectingOrigin } = getState().wayFinding;
        if (!isSelectingOrigin && !isSelectingDestination) return;
        dispatch(beginChoosingPointOnTheMap());
      };

export async function updateRoute(
  origin: LatLng | ItemDetail | undefined,
  destination: LatLng | ItemDetail | undefined,
  routeOptions: RouteOptions,
  dispatch: Dispatch
): Promise<void> {
  try {
    const startRoute = toRouteArg(origin);
    const endRoute = toRouteArg(destination);
    // remove any selected pin on the map

    dispatch(beginRouteLoading());
    dispatch(endSearching()); //remove wayfinding search resultss
    dispatch(closeItemDetails()); //close any open item details

    const routeDetail = await MazeMapDataService.getRoute(startRoute, endRoute, routeOptions);
    const route = routeDetail.route;
    dispatch(setCurrentRoute(routeDetail));
    dispatch(removeLayer(SEARCH_RESULTS_LAYER_NAME));
    dispatch(removeLayer(dropPinLayerName));
    dispatch(endPointSelection());
    dispatch(removeLayer(routeLayerName));
    dispatch(
      pushLayer({
        name: routeLayerName,
        type: "route",
        layerDetails: { route: route },
        includeInMapControl: false
      })
    );
    const routeBounds = GeoJsonUtil.getBounds(route.features);
    dispatch(
      fitToBounds({
        ne: GeoJsonUtil.toLatLng(routeBounds.getNorthEast()),
        sw: GeoJsonUtil.toLatLng(routeBounds.getSouthWest())
      })
    );
    dispatch(showLayer(routeLayerName));

  } catch (error) {
    console.log(error);
  }
  dispatch(endRouteLoading());
}

function toRouteArg(location: WayFindingPoint | undefined): LatLngRouteArgument | POIIdRouteArgument {
  // check location is not undefined first
  if (location) {
    // now try LatLng
    const latLng = location as LatLng | undefined;
    if (latLng?.lat && latLng.lng) {
      return { lngLat: latLng, zLevel: 0, type: "LatLngRouteArgument" };
    }

    // not a LatLng so must be an ItemDetail
    const itemDetail = location as ItemDetail | undefined;
    if (itemDetail?.itemId && itemDetail.properties?.type !== BUILDING_POI_PROPERTIES) {
      // buildings' poiIds are useless, so use geometry instead
      return { id: itemDetail.itemId, type: "POIIdRouteArgument" };
    }
    if (itemDetail?.geometry) {
      const [lng, lat] = GeoJsonUtil.getGeoCentre(itemDetail.geometry);
      return { lngLat: { lng, lat }, zLevel: itemDetail.zLevel, type: "LatLngRouteArgument" };
    }
  }

  // hopefully shouldn't get here
  throw new Error(`Unable to determine route argument from given WayFindingPoint: ${JSON.stringify(location)}`);
}

export const fetchWayFindingSearchResults
    = (campusId: string, newSearchTerm: string, pageNumber: number, nearTo: LatLng): AppThunk<Promise<void>> =>
      async (dispatch): Promise<void> => {
        try {
          const pois = await MazeMapDataService.getPoisBySearch(
            campusId,
            newSearchTerm,
            pageNumber * SEARCH_PAGE_SIZE,
            SEARCH_PAGE_SIZE,
            nearTo
          );

          dispatch(
            searchResults({
              searchTerm: newSearchTerm,
              pageNumber,
              searchResult: pois,
              searchedCentre: nearTo
            })
          );

          dispatch(endLoading());
        } catch (error) {
          dispatch(endLoading());
          dispatch(setSearchError("Error while performing search in WayFinder"));
          console.log(`Mazemap connection issues: ${error}`);
        }
      };

export const moveToRouteStep
    = (stepIndex: number): AppThunk<void> =>
      (dispatch, getState): void => {
        const defaultRouteZoom = 21;
        const currentState = getState();
        const currentRoute = currentState.wayFinding.currentRoute;
        if (!currentRoute || stepIndex >= currentRoute.steps.length) return;

        const targetStep = currentRoute.steps[stepIndex];

        switch (targetStep.geometry.type) {
          case "Point": {
            const centre = GeoJsonUtil.toLatLng(GeoJsonUtil.toLngLat(targetStep.geometry.coordinates));
            //check if the next step is a line
            const nextStep = currentRoute.steps.find(
              (step, i) => i > stepIndex && step.geometry.type === "LineString"
            );
            let rotation: number | undefined = undefined;
            if (nextStep) {
              const nextLine = nextStep.geometry as GeoJSON.LineString;
              rotation = GeoJsonUtil.getLineBearing(nextLine);
            }
            dispatch(
              moveMapTo({
                centre,
                zLevel: targetStep.zLevel ?? 0,
                zoom: defaultRouteZoom,
                rotation: rotation
              })
            );

            break;
          }
          case "LineString": {
            const pt = GeoJsonUtil.toLatLng(GeoJsonUtil.toLngLat(targetStep.geometry.coordinates[0]));
            const bearing = GeoJsonUtil.getLineBearing(targetStep.geometry);
            dispatch(
              moveMapTo({
                centre: pt,
                zLevel: targetStep.zLevel ?? 0,
                zoom: defaultRouteZoom,
                rotation: bearing
              })
            );
            break;
          }
          default:
            //unhandled
            console.log(targetStep.geometry.type);
        }
      };

export default wayFindingSlice.reducer;
