import React, { ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../feedbackFormModal/FeedbackFormModal.module.scss";
import Modal from "../../components/Modal";
import ModalHeader from "../../components/Modal/ModalHeader";
import ModalCloseButton from "../../components/Modal/ModalCloseButton";
import { RootState } from "../../app/store";
import { closeModal } from "../../store/map/mapSlice";
import {
  TITLE_ABOUT,
  MOBILE_PLATFORM_VERSION,
  MOBILE_PLATFORM,
  TITLE_FEEDBACK
} from "../../constants";
import { ModalType } from "../../app/models/MapState";
import { VERSION_LATEST } from "../../env";

export default function AboutModal(): ReactElement {
  const dispatch = useDispatch();

  const mobilePlatform = window.MOBILE_APP_INFO?.getPlatform();
  const mobileVersion = window.MOBILE_APP_INFO?.getVersion();

  // Set the feedback URL based on the user's device.
  let feedbackUrl;
  if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
    feedbackUrl = "https://www.uq.edu.au/feedback";
  } else {
    feedbackUrl = "https://www.uq.edu.au/feedback?r=https://maps.uq.edu.au/";
  }

  // Global State
  const show = useSelector((state: RootState) => state.map.modal === ModalType.ABOUT);

  return (
    <Modal
      show={show}
      onClose={(): unknown => dispatch(closeModal())}
      portalContainer={document.getElementById("root")}
      overlayPalette={"light"}
      className={styles.mapModal}
    >
      <ModalHeader borderBottom>{TITLE_ABOUT}</ModalHeader>
      <ModalCloseButton closeModal={(): unknown => dispatch(closeModal())} />
      <div className={styles.mapModalBody}>{mobilePlatform}
        {mobilePlatform && <p><strong>{MOBILE_PLATFORM}{":"}</strong> {mobilePlatform}</p>}
        {mobileVersion && <p><strong>{MOBILE_PLATFORM_VERSION}{":"}</strong> {mobileVersion}</p>}
        <p><strong>{"Web Version:"}</strong> {VERSION_LATEST}</p>
        <a
          target={"_blank"}
          rel={"noopener noreferrer"}
          href={feedbackUrl}
          aria-label={TITLE_FEEDBACK}
        >
          {TITLE_FEEDBACK}
        </a>
      </div>
    </Modal>
  );
}