const colours = {
  primary: "#51247a",
  primaryLight: "#a891bc",
  primaryStrong: "#5b0b52",
  textPrimary: "#ffffff",
  textDefault: "#000000",
  textSecondary: "rgba(0, 0, 0, 0.72)",
  backgroundDefault: "#ffffff",
  secondary: "#2ea836",
  secondaryLight: "#2ea83640",
  secondaryLightSolid: "#d5edd6",
  safetyPath: "#ffc800",
  accessiblePath: "#051094"
};

export default colours;
