import { MapState } from "../app/models/MapState";
import { SearchState } from "../app/models/SearchState";
import { LatLng } from "../app/models/LatLng";
import { History } from "history";
import { MapParameters } from "../app/models/MapParameters";
import { WayFindingPoint, WayFindingState } from "../app/models/WayFindingState";
import { ItemDetail } from "../app/models/ItemDetail";
import { GeoJsonUtil } from "./GeoJsonUtil";
import { WayfindingParameters } from "../app/models/WayfindingParameters";
import MazeMapDataService from "./MazeMapDataService";
import { PoiUtil } from "./PoiUtil";
import { Point } from "geojson";

enum WayfindingPointType {
  IDENTIFIER = "identifier",
  LEGACY_POI_ID = "poi",
  POINT = "point"
}

export class UrlProcessing {
  public static async getWayfindingPoints({
    campusId,
    start,
    startType,
    dest,
    destType
  }: MapParameters): Promise<WayfindingParameters> {
    let origin: WayFindingPoint | undefined = undefined;
    let destination: WayFindingPoint | undefined = undefined;

    if (start && startType && campusId) {
      origin = await UrlProcessing.getWayfindingPoint(start, startType as WayfindingPointType);
    }
    if (dest && destType && campusId) {
      destination = await UrlProcessing.getWayfindingPoint(dest, destType as WayfindingPointType);
    }

    return { origin, destination };
  }

  private static async getWayfindingPoint(
    value: string,
    type: WayfindingPointType
  ): Promise<WayFindingPoint | undefined> {
    switch (type) {
      case WayfindingPointType.IDENTIFIER: {
        const identifierPoi = await MazeMapDataService.getPoi(value);
        return PoiUtil.toItemDetail(identifierPoi);
      }
      case WayfindingPointType.LEGACY_POI_ID: {
        const poi = await MazeMapDataService.getPoi(value);
        return PoiUtil.toItemDetail(poi);
      }
      case WayfindingPointType.POINT: {
        const parts = value.split(",");
        const expectedLength = 2;
        if (parts.length !== expectedLength) return undefined;
        const lng = UrlProcessing.toFloat(parts[0]);
        const lat = UrlProcessing.toFloat(parts[1]);
        if (lat && lng) {
          return PoiUtil.toItemDetail(PoiUtil.createSelectedPOI({ lat, lng }));
        }
        break;
      }
    }
    return undefined;
  }

  public static getMapParameters(query: URLSearchParams): MapParameters {
    const zoomParam = query.get("zoom");
    const zLevelParam = query.get("zLevel");
    const latParam = query.get("lat");
    const lngParam = query.get("lng");
    const campusIdentifierParam = query.get("campusId");
    const poiIdParam = query.get("poiId");
    const identifierParam = query.get("identifier");
    const categoryIdParam = query.get("category") ?? query.get("typepois"); // "typepois" was the old name
    const pitchParam = query.get("pitch");
    const isSelectedPointParam = query.get("isSelectedPoint");
    const wfStartParam = query.get("start");
    const wfStartTypeParam = query.get("startType");
    const wfDestParam = query.get("dest");
    const wfDestTypeParam = query.get("destType");

    return {
      campusId: campusIdentifierParam ?? undefined,
      lat: UrlProcessing.toFloat(latParam),
      lng: UrlProcessing.toFloat(lngParam),
      pitch: UrlProcessing.toFloat(pitchParam),
      category: categoryIdParam ?? undefined,
      zLevel: UrlProcessing.toInt(zLevelParam),
      zoom: UrlProcessing.toFloat(zoomParam),
      poiId: poiIdParam ?? undefined,
      identifier: identifierParam ?? undefined,
      isSelectedPoint: UrlProcessing.toBool(isSelectedPointParam),
      start: wfStartParam ?? undefined,
      startType: wfStartTypeParam ?? undefined,
      dest: wfDestParam ?? undefined,
      destType: wfDestTypeParam ?? undefined
    };
  }

  private static toFloat(value: string | null): number | undefined {
    return (value && parseFloat(value)) || undefined;
  }

  private static toInt(value: string | null): number | undefined {
    return (value && parseInt(value)) || undefined;
  }

  private static toBool(value: string | null): boolean | undefined {
    return value ? value.toLowerCase() === "true" : undefined;
  }

  public static setMapParameters(parameters: MapParameters, history: History, current: URLSearchParams): void {
    for (const [k, v] of Object.entries(parameters)) {
      if (v !== undefined && v !== 0) current.set(k, v.toString());
      else current.delete(k);
    }
    history.push({
      search: current.toString()
    });
  }

  public static updateBrowserUrl(
    mapData: MapState,
    searchData: SearchState,
    routeData: WayFindingState,
    selectedCategory: string | undefined,
    stateCentre: LatLng,
    stateZLevel: number,
    history: History<unknown>,
    current: URLSearchParams
  ): void {
    let urlParams: MapParameters = {
      zoom: mapData.zoom,
      campusId: mapData.campusId,
      lat: stateCentre.lat,
      lng: stateCentre.lng,
      zLevel: stateZLevel,
      category: selectedCategory,
      pitch: mapData.pitch,
      start: undefined,
      startType: undefined,
      dest: undefined,
      destType: undefined,
      isSelectedPoint: undefined,
      poiId: undefined,
      identifier: undefined
    };

    if (mapData.openItemDetail) {
      // prefer identifier over poiId
      if (mapData.itemDetail?.properties?.id) {
        urlParams.identifier = mapData.itemDetail.properties.id;
      } else if (mapData.itemDetail?.itemId) {
        urlParams.poiId = mapData.itemDetail.itemId;
      }
    }

    // including WayFinding parameters if its open
    if (routeData.isWayFinding) {
      const { type: startType, value: start } = UrlProcessing.getWayfindingPointParams(routeData.origin);
      const { type: destType, value: dest } = UrlProcessing.getWayfindingPointParams(routeData.destination);
      urlParams = { ...urlParams, startType, start, destType, dest };
    }

    this.setMapParameters(urlParams, history, current);
  }

  public static getRouteShareUrl(
    location: Location,
    origin: WayFindingPoint,
    destination: WayFindingPoint,
    campusId: string
  ): string {
    const host = location.origin;
    const { type: startType, value: start } = UrlProcessing.getWayfindingPointParams(origin);
    const { type: destType, value: dest } = UrlProcessing.getWayfindingPointParams(destination);

    const wfParams = {
      campusId: campusId,
      startType,
      start,
      destType,
      dest
    };

    const qry = new URLSearchParams();
    for (const [k, v] of Object.entries(wfParams)) {
      if (v) qry.append(k, v.toString());
    }

    return `${host}/?${qry}`;
  }

  private static getWayfindingPointParams(point?: WayFindingPoint): { type?: string; value?: string } {
    if (point) {
      const pointItemDetail = point as ItemDetail | undefined;
      const pointItemDetailPoint = pointItemDetail?.geometry as Point | undefined;
      const pointLatLng = point as LatLng | undefined;

      if (pointItemDetail?.properties?.id) {
        return {
          type: "identifier",
          value: pointItemDetail.properties.id
        };
      }

      if (pointItemDetail?.itemId) {
        return {
          type: "poi",
          value: pointItemDetail.itemId
        };
      }

      if (pointItemDetail?.geometry && pointItemDetailPoint?.coordinates) {
        const [x, y] = pointItemDetailPoint.coordinates;
        return {
          type: "point",
          value: `${x},${y}`
        };
      }

      if (pointLatLng) {
        const { lat, lng } = pointLatLng;
        return {
          type: "point",
          value: `${lng},${lat}`
        };
      }
    }
    return { type: undefined, value: undefined };
  }

  public static getCategoryShareUrl(location: Location, map: MapState, categoryID: string): string {
    // Lat and Lng will populate into the URL from the category selection, so it is not needed in mapParams
    const mapParams: MapParameters = {
      campusId: map.campusId,
      category: categoryID,
      zLevel: map.zLevel,
      zoom: 18
    };

    const qryString = new URLSearchParams(mapParams as Record<string, string>).toString();
    return `${location.origin}/?${qryString}`;
  }

  public static getItemShareUrl(location: Location, item?: ItemDetail): string {
    // If there is no item for some reason, just throw back the current URL.
    // That should at least bring the user back to the same view they were currently looking at anyway.
    if (!item) return location.toString();

    const host = location.origin;
    const mapParams: MapParameters = {
      campusId: item.campusId,
      zLevel: item.zLevel,
      zoom: 18
    };

    if (item.properties?.id) {
      // preferred method - use identifier (A.K.A. PublicId assigned by us)
      mapParams.identifier = item.properties.id;
    } else if (item.itemId) {
      // legacy method - use poiID (bad because the poiID is assigned by MazeMap and may not be constant)
      mapParams.poiId = item.itemId;
    } else {
      // No identifier or poiId.... most likely a selected point.
      const position = GeoJsonUtil.getGeoCentre(item.geometry);
      const centre = GeoJsonUtil.toLngLat(position);
      mapParams.lat = centre.lat;
      mapParams.lng = centre.lng;
      mapParams.isSelectedPoint = item.title === "Selected Point" ? true : undefined;
    }

    const qryString = new URLSearchParams(mapParams as Record<string, string>).toString();
    return `${host}/?${qryString}`;
  }
}
