import axios from "axios";
import { BuildingPoiI, Category, ComputerPoiI, ParkingPoiI } from "../util/types";
import { API_URL } from "../env";
import { LineString } from "geojson";
import { FeedbackData } from "../app/models/FeedbackData";

const apiHost = API_URL;

const Api = {
  getParkingData: async (campusId: string): Promise<ParkingPoiI[]> => {
    return axios
      .get<{
      data: { origin: string; results: ParkingPoiI[] };
    }>(`${apiHost}/parking`, { params: { campusId } })
      .then(response => {
        return response.data.data.results;
      });
  },
  getBuildingData: async (campusId: string): Promise<BuildingPoiI[]> => {
    return axios
      .get<{
      data: { origin: string; results: BuildingPoiI[] };
    }>(`${apiHost}/building`, { params: { campusId } })
      .then(response => response.data.data.results);
  },
  getComputerData: async (campusId: string): Promise<ComputerPoiI[]> => {
    return axios
      .get<{
      data: { origin: string; results: ComputerPoiI[] };
    }>(`${apiHost}/computer`, { params: { campusId } })
      .then(response => {
        return response.data.data.results;
      });
  },
  getCategory: async (categoryId: string): Promise<Category | null> => {
    return axios
      .get<{ data: Category }>(`${apiHost}/categories/${categoryId}`)
      .then(response => response.data.data)
      .catch(error => {
        console.log(error);
        return null;
      });
  },
  getLiveCategories: async (): Promise<Category[] | null> => {
    return axios
      .get<{ data: Category[] }>(`${apiHost}/categories/live`)
      .then(response => response.data.data)
      .catch(error => {
        console.log(error);
        return null;
      });
  },
  getSafePath: async (): Promise<GeoJSON.FeatureCollection<LineString> | null> => {
    return axios
      .get<{ data: GeoJSON.FeatureCollection<LineString> }>(`${apiHost}/feature/safepath`)
      .then(response => response.data.data)
      .catch(error => {
        console.log(error);
        return null;
      });
  },
  getAccessiblePath: async (): Promise<GeoJSON.FeatureCollection<LineString> | null> => {
    return axios
      .get<{ data: GeoJSON.FeatureCollection<LineString> }>(`${apiHost}/feature/accessiblepath`)
      .then(response => response.data.data)
      .catch(error => {
        console.log(error);
        return null;
      });
  },
  getBusynessPoints: async (
    campusId: string
  ): Promise<{
    heat: GeoJSON.FeatureCollection<GeoJSON.Point, { heatValue: number; z: number }>;
    maximumValue: number;
  }> => {
    return axios
      .get<{
      data: {
        heat: GeoJSON.FeatureCollection<GeoJSON.Point, { heatValue: number; z: number }>;
        maximumValue: number;
      };
    }>(`${apiHost}/busyness/points`, { params: { campusid: campusId } })
      .then(response => {
        return response.data.data;
      })
      .catch(error => {
        console.log(error);
        return {
          heat: { type: "FeatureCollection", features: [] },
          maximumValue: 0
        };
      });
  },
  getBusynessPolygons: async (
    campusId: string
  ): Promise<{
    heat: GeoJSON.FeatureCollection<GeoJSON.Polygon, { heatValue: number; z: number }>;
    maximumValue: number;
  }> => {
    return axios
      .get<{
      data: {
        heat: GeoJSON.FeatureCollection<GeoJSON.Polygon, { heatValue: number; z: number }>;
        maximumValue: number;
      };
    }>(`${apiHost}/busyness/polygons`, { params: { campusid: campusId } })
      .then(response => {
        return response.data.data;
      })
      .catch(error => {
        console.log(error);
        return {
          heat: { type: "FeatureCollection", features: [] },
          maximumValue: 10
        };
      });
  },

  sendFeedback: (feedback: FeedbackData): void => {
    axios
      .post<{
      data: unknown;
    }>(`${apiHost}/feedback`, { params: { data: feedback } })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log(error);
        return {
          data: feedback
        };
      });
  }
};

export default Api;
