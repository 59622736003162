import React, { ReactElement } from "react";
import classnames from "classnames";
import "./ModalFooter.scss";

export enum Alignments {
  left = "left",
  right = "right",
  center = "center"
}

export type AlignmentsType = keyof typeof Alignments;

interface ModalFooterProps {
  alignment?: AlignmentsType;
  children?: React.ReactNode;
  className?: string;
}

export default function ModalFooter(props: ModalFooterProps): ReactElement {
  // Props
  const { alignment = "right", children, className: classNameProp } = props;

  const extendedClasses = classnames(
    "uq-modal-footer",
    { [`uq-modal-footer--${alignment}`]: alignment === Alignments.left || alignment === Alignments.center },
    classNameProp
  );

  return <footer className={extendedClasses}>{children}</footer>;
}
