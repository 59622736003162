import React, { createRef, ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { closeItemDetails, setAndFlyToCampus } from "../../store/map/mapSlice";
import {
  CATEGORY_RESULTS_LAYER_NAME,
  updateSearchTerm
} from "../../store/search/searchSlice";
import { closeItemDetailsWayfinding, revertDefaultValue } from "../../store/wayFinding/wayFindingSlice";
import Dropdown from "../dropdown";
import { removeLayer } from "../layers/__layerSlice";
import { dropPinLayerName } from "../../features/map/dropPin";

interface DropdownOption {
  label: string;
  value: string | number;
}

export default function CampusDropdown(): ReactElement {
  const dispatch = useDispatch();
  const campuses = useSelector((state: RootState) => state.map.campuses);
  const campusId = useSelector((state: RootState) => state.map.campusId);
  const currentLocation = campuses.find(campus => campusId === campus.properties.id);
  const currentCampusName = currentLocation?.properties.name ?? "";

  const ref = createRef<HTMLDivElement>();
  const handleSwitch = (option: DropdownOption): void => {
    dispatch(setAndFlyToCampus(option.value.toString()));
    dispatch(updateSearchTerm(""));
    dispatch(closeItemDetails());
    dispatch(closeItemDetailsWayfinding());
    dispatch(revertDefaultValue());
    dispatch(removeLayer(dropPinLayerName));
    dispatch(removeLayer(CATEGORY_RESULTS_LAYER_NAME));

  };

  return (
    <Dropdown
      onClick={(option): void => {
        handleSwitch(option);
      }}
      header={currentCampusName}
      options={campuses.map(campus => ({
        value: campus.properties.id,
        label: campus.properties.name
      }))}
      ref={ref}
    />
  );
}
