import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import { moveMapTo } from "../../store/map/mapSlice";

export interface IUserCoordinates {
  readonly accuracy: number;
  readonly altitude: number | null;
  readonly altitudeAccuracy: number | null;
  readonly heading: number | null;
  readonly latitude: number;
  readonly longitude: number;
  readonly speed: number | null;
}

export interface IPositionError {
  readonly code: number;
  readonly message: string;
  readonly PERMISSION_DENIED: number;
  readonly POSITION_UNAVAILABLE: number;
  readonly TIMEOUT: number;
}

export interface geolocateState {
  value: number;
  coordinates: IUserCoordinates | null;
  error: IPositionError | null;
  watch: boolean;
  angle: number | null;
  /**
     * Indicate that the watch is pending until the first coordinates are received.
     */
  pending: boolean;
  /**
     * Indicate map position should follow users location
     */
  following: boolean;
}

const initialState: geolocateState = {
  value: 0,
  coordinates: null,
  error: null,
  watch: false,
  angle: null,
  pending: false,
  following: false
};

export const geolocateSlice = createSlice({
  name: "geolocate",
  initialState,
  reducers: {
    updateError: (state, action: PayloadAction<IPositionError>) => {
      state.error = action.payload;
      state.pending = false;
    },
    updatePosition: (state, action: PayloadAction<IUserCoordinates>) => {
      // console.log(`### STATE ->
      //          updatePosition: lat(${action.payload.latitude}) lng(${action.payload.longitude})`);

      state.coordinates = action.payload;
      state.pending = false;
      state.error = null;
    },
    updateWatch: (state, action: PayloadAction<boolean>) => {
      state.watch = action.payload;
      state.pending = action.payload;
    },
    updateAngle: (state, action: PayloadAction<number | null>) => {
      // console.log('### STATE -> updateAngle Integer: ', action.payload);
      state.angle = action.payload;
    },
    updateFollowing: (state, action: PayloadAction<boolean>) => {
      state.following = action.payload;
    }
  }
});

export const { updatePosition, updateWatch, updateError, updateAngle, updateFollowing } = geolocateSlice.actions;
export const selectPosition = (state: RootState): IUserCoordinates | null => state.geolocator.coordinates;
export const selectWatch = (state: RootState): boolean => state.geolocator.watch;
// export const selectError = (state: RootState): IPositionError | null => state.geolocator.error;
export const selectAngle = (state: RootState): number | null => state.geolocator.angle;
export default geolocateSlice.reducer;

export const moveToCurrentLocation = (): AppThunk<void> => (dispatch, getState): void => {
  const state = getState();
  const location = state.geolocator.coordinates;
  if (location)
    dispatch(
      moveMapTo({
        centre: { lat: location.latitude, lng: location.longitude }
      })
    );
};

export const newLocation
    = (location: IUserCoordinates): AppThunk<void> =>
      (dispatch, getState): void => {
        const state = getState();
        dispatch(updatePosition(location));
        if (state.geolocator.following)
          dispatch(
            moveMapTo({
              centre: { lat: location.latitude, lng: location.longitude }
            })
          );
      };

export const newAngle
    = (angle: number | null): AppThunk<void> =>
      (dispatch, getState): void => {
        const state = getState();
        const currentAngle = state.geolocator.angle;

        if (currentAngle === null && angle === null) {
          dispatch(updateAngle(0));
        } else if (currentAngle !== null && angle !== null) {
          const angleInt = Math.ceil(angle);
          // console.log(`### STATE -> newAngle -> currentAngle: ${currentAngle} new angleInt: ${angleInt}`);
          if (angleInt !== currentAngle) {
            dispatch(updateAngle(angleInt));
          }
        }
      };
