import { MapEx } from "MazeMapTypes";
import React, { ReactElement, ReactNode } from "react";
import MapBoxSource from "../source/MapBoxSource";
import MapBoxLayer, { Paint } from "./MapBoxLayer";
import { GeoJSONSourceRaw } from "mapbox-gl";
import { _LAYER_ACCESSIBLE, _LAYER_WELL_LIT } from "../../store/layers/layerSlice";

interface PathsLayerProps {
  map: MapEx;
  geojson: GeoJSON.FeatureCollection;
  layerName: string;
  visible: boolean;
  linePaint: mapboxgl.LinePaint;
  lineLayout: mapboxgl.LineLayout;
  minZoom?: number;
  maxZoom?: number;
}

export class PathsLayer extends React.Component<PathsLayerProps> {
  private readonly dataSource: GeoJSONSourceRaw;

  private readonly pathsFeatureLayerName: string;

  constructor(props: PathsLayerProps) {
    super(props);

    this.dataSource = {
      type: "geojson",
      data: props.geojson
    };
    this.pathsFeatureLayerName = `${props.layerName}features`;
  }

  public shouldComponentUpdate(): boolean {
    return true;
  }

  private updateDataSource(): void {
    const { geojson } = this.props;
    this.dataSource.data = geojson;
  }

  public componentWillUnmount(): void {
    this.clearFeatures();
  }

  private clearFeatures(): void {
    this.dataSource.data = { type: "FeatureCollection", features: [] };
  }

  public componentDidUpdate(): void {
    this.updateLayerVisibility();
  }

  /**
     * Find the index of the first symbol layer in the map style
     * may fail if the map is not loaded yet.
     */
  private readonly getFirstSymbolLayer = (): string => {
    const { map } = this.props;

    let firstSymbolId = "";
    try {
      const layers = map.getStyle().layers;
      firstSymbolId = layers.find(layer => layer.type === "symbol")?.id ?? "";
    } catch (ex) {
      console.log(ex);
    }
    return firstSymbolId;
  };

  public render(): ReactNode {
    const { layerName, map, visible, lineLayout, linePaint, minZoom, maxZoom } = this.props;
    const src = `${layerName}src`;
    const bgLayerName = `${layerName}bg`;
    const visiblilityValue = visible ? "visible" : "none";
    let bgOpacity = 0;

    if (layerName === _LAYER_WELL_LIT) {
      bgOpacity = 0.3;
    }

    const firstSymbolId = this.getFirstSymbolLayer();
    if (firstSymbolId === "") return null;
    this.updateDataSource();
    return (
      <MapBoxSource
        map={map}
        id={src}
        geoJsonSource={this.dataSource}
        render={(): ReactElement => <>
          <MapBoxLayer
            map={map}
            id={layerName}
            sourceId={src}
            type={"line"}
            paint={{ ...linePaint }}
            layout={{ visibility: visiblilityValue, ...lineLayout }}
            // before={firstSymbolId}
            minZoom={minZoom}
            maxZoom={maxZoom}
          />
          {(layerName === _LAYER_WELL_LIT || layerName === _LAYER_ACCESSIBLE) && (
            <MapBoxLayer
              map={map}
              id={bgLayerName}
              sourceId={src}
              type={"heatmap"} //HACK: use a heatmap with a single value to create the dark background layer.
              layout={{ visibility: visiblilityValue }}
              paint={
                {
                  "heatmap-color": `rgba(0,0,0,${bgOpacity})`
                } as Paint
              }
              before={layerName}
              minZoom={minZoom}
              maxZoom={maxZoom}
            />
          )}
        </>}
      />
    );
  }

  private updateLayerVisibility(): void {
    try {
      const { map, visible, layerName } = this.props;
      const visiblilityValue = visible ? "visible" : "none";
      map.setLayoutProperty(layerName, "visibility", visiblilityValue);
      map.setLayoutProperty(`${layerName}bg`, "visibility", visiblilityValue);
    } catch (ex) {
      console.log(ex);
    }
  }
}
