import * as Icons from "./CategoryIconAssets";

import { MapEx } from "MazeMapTypes";
import { loadImage } from "./typeIcons";

export const categoryIconPrefix = "category-";
export const categoryPrimaryIconPrefix = "category-primary-";

const defaultIconPrimary: string = Icons.location_icon;
const defaultIcon: string = Icons.location_icon_white;

enum POICategory {
  SERVICES = "Services",
  MEETING_ROOM = "Meeting Room",
  OFFICE = "Office",
  STAIRS = "Stairs",
  // CIRCULATION_SPACE = "Circulation Space",
  // LIFT = "Lift",
  KITCHENETTE = "Kitchenette",
  TOILETS_FEMALE = "Toilets - Female",
  TOILETS_MALE = "Toilets - Male",
  TOILETS_ACCESSIBLE = "Toilets - Accessible",
  SHOWERS = "Showers",
  TOILETS_ALL_GENDER = "Toilets - All Gender",
  CAR_PARKING = "Car Parking",
  LANDMARKS = "Landmarks",
  BICYCLE_STORAGE_ROOMS = "Bicycle Storage Rooms",
  HAND_SANITISER = "Hand Sanitiser",
  RETAIL_AND_SPECIALTY = "Retail & Specialty",
  GENERAL_TEACHING_ROOM = "General Teaching Room",
  STUDY_SPOTS_COMMON = "Study Spots - Common",
  WATER_REFILL_STATIONS = "Water Refill Stations",
  // SKYBRIDGE = "Skybridge",
  FOOD_AND_BEVERAGE = "Food & Beverage",
  TRANSPORT = "Transport",
  MICROWAVES = "Microwaves",
  LECTURE_THEATRE = "Lecture Theatre",
  BIKE_RACKS = "Bike Racks",
  LIBRARIES = "Libraries",
  MUSEUMS = "Museums",
  PARENTING_ROOM = "Parenting Room",
  EMERGENCY_POINTS = "Emergency Points",
  COMPUTER_LAB_TEACHING = "Computer Lab - Teaching",
  MOTORCYCLE_PARKING = "Motorcycle Parking",
  DRY_LAB = "Dry Lab",
  WET_LAB = "Wet Lab",
  BANKS_AND_ATMS = "Banks & ATMs",
  BICYCLE_REPAIR_STATIONS = "Bicycle Repair Stations",
  PAYPHONES = "Payphones",
  BIKE_BOXES = "Bike Boxes",
  LIBRARY_ASK_US_SERVICE = "Library - Ask Us Service",
  LIBRARY_BOOK_RETURN = "Library Book Return",
  PRINTERS_STUDENT_ACCESS = "Printers (Student Access)",
  SECURITY_OFFICE = "Security Office",
  ENTRY = "Entry",
  BABY_CHANGE_ROOM = "Baby Change Room",
  STUDY_SPOTS_BOOKABLE = "Study Spots - Bookable",
  BENCH_EXERCISES = "Bench Exercises",
  CHILD_CARE_FACILITY = "Child Care Facility",
  COMPUTERS_OPEN_ACCESS = "Computers - Open Access",
  ESPORTS = "eSports",
  FAITH_AND_RELIGION = "Faith & Religion",
  FUNCTION_VENUE = "Function Venue",
  INFO_POINT = "Info Point",
  RIDEABLES = "Rideables",
  RUNNING_EXERCISE = "Running Exercise",
  SCHOOLS_AND_FACULTIES = "Schools & Faculties",
  SPORTS_AND_RECREATION = "Sports & Recreation",
  STRETCHING_EXERCISES = "Stretching Exercises",
  STUDY_SPOTS_TEMPORARY = "Study Spots - Temporary",
  UQ_SAFETY_BUS_STOPS = "UQ Safety Bus Stops",
  TOILETS_AMBULANT = "Toilets - Ambulant",
  DEFIBRILLATORS = "Defibrillators",
  FEMININE_HYGIENE_DISPENSERS = "Feminine Hygiene Dispensers",
  UNKNOWN = "Other"
}

const poiCatIdToEnum: { [id: string]: POICategory | undefined } = {
  33831: POICategory.BABY_CHANGE_ROOM, // St. Lucia
  33833: POICategory.BABY_CHANGE_ROOM, // Herston
  33832: POICategory.BABY_CHANGE_ROOM, // Gatton
  36001: POICategory.BABY_CHANGE_ROOM, // Toowong
  36135: POICategory.BABY_CHANGE_ROOM, // Long Pocket
  36134: POICategory.BABY_CHANGE_ROOM, // Dutton Park
  32661: POICategory.BANKS_AND_ATMS, // St. Lucia
  33922: POICategory.BANKS_AND_ATMS, // Gatton
  33935: POICategory.BANKS_AND_ATMS, // Herston
  35991: POICategory.BANKS_AND_ATMS, // Toowong
  36116: POICategory.BANKS_AND_ATMS, // Long Pocket
  36117: POICategory.BANKS_AND_ATMS, // Dutton Park
  32874: POICategory.BENCH_EXERCISES, // St. Lucia
  35899: POICategory.BENCH_EXERCISES, // Herston
  35934: POICategory.BENCH_EXERCISES, // Gatton
  36003: POICategory.BENCH_EXERCISES, // Toowong
  36139: POICategory.BENCH_EXERCISES, // Long Pocket
  36138: POICategory.BENCH_EXERCISES, // Dutton Park
  33029: POICategory.BICYCLE_REPAIR_STATIONS, // St. Lucia
  33923: POICategory.BICYCLE_REPAIR_STATIONS, // Gatton
  33941: POICategory.BICYCLE_REPAIR_STATIONS, // Herston
  35992: POICategory.BICYCLE_REPAIR_STATIONS, // Toowong
  36118: POICategory.BICYCLE_REPAIR_STATIONS, // Long Pocket
  36119: POICategory.BICYCLE_REPAIR_STATIONS, // Dutton Park
  33030: POICategory.BICYCLE_STORAGE_ROOMS, // St. Lucia
  33939: POICategory.BICYCLE_STORAGE_ROOMS, // Herston
  35932: POICategory.BICYCLE_STORAGE_ROOMS, // Gatton
  35985: POICategory.BICYCLE_STORAGE_ROOMS, // Toowong
  36105: POICategory.BICYCLE_STORAGE_ROOMS, // Long Pocket
  36104: POICategory.BICYCLE_STORAGE_ROOMS, // Dutton Park
  33028: POICategory.BIKE_BOXES, // St. Lucia
  33945: POICategory.BIKE_BOXES, // Herston
  35933: POICategory.BIKE_BOXES, // Gatton
  35994: POICategory.BIKE_BOXES, // Toowong
  36123: POICategory.BIKE_BOXES, // Long Pocket
  36122: POICategory.BIKE_BOXES, // Dutton Park
  33027: POICategory.BIKE_RACKS, // St. Lucia
  33924: POICategory.BIKE_RACKS, // Gatton
  33940: POICategory.BIKE_RACKS, // Herston
  35981: POICategory.BIKE_RACKS, // Toowong
  36096: POICategory.BIKE_RACKS, // Long Pocket
  36097: POICategory.BIKE_RACKS, // Dutton Park
  32875: POICategory.CAR_PARKING, // St. Lucia
  33920: POICategory.CAR_PARKING, // Gatton
  33929: POICategory.CAR_PARKING, // Herston
  35973: POICategory.CAR_PARKING, // Toowong
  36063: POICategory.CAR_PARKING, // Long Pocket
  36079: POICategory.CAR_PARKING, // Dutton Park
  31449: POICategory.CHILD_CARE_FACILITY, // St. Lucia
  33823: POICategory.CHILD_CARE_FACILITY, // Gatton
  35900: POICategory.CHILD_CARE_FACILITY, // Herston
  36004: POICategory.CHILD_CARE_FACILITY, // Toowong
  36141: POICategory.CHILD_CARE_FACILITY, // Long Pocket
  36140: POICategory.CHILD_CARE_FACILITY, // Dutton Park
  31451: POICategory.COMPUTER_LAB_TEACHING, // St. Lucia
  33806: POICategory.COMPUTER_LAB_TEACHING, // Herston
  33826: POICategory.COMPUTER_LAB_TEACHING, // Gatton
  35987: POICategory.COMPUTER_LAB_TEACHING, // Toowong
  36109: POICategory.COMPUTER_LAB_TEACHING, // Long Pocket
  36108: POICategory.COMPUTER_LAB_TEACHING, // Dutton Park
  32992: POICategory.COMPUTERS_OPEN_ACCESS, // St. Lucia
  33825: POICategory.COMPUTERS_OPEN_ACCESS, // Gatton
  35902: POICategory.COMPUTERS_OPEN_ACCESS, // Herston
  36005: POICategory.COMPUTERS_OPEN_ACCESS, // Toowong
  36143: POICategory.COMPUTERS_OPEN_ACCESS, // Long Pocket
  36142: POICategory.COMPUTERS_OPEN_ACCESS, // Dutton Park
  31452: POICategory.DRY_LAB, // St. Lucia
  33804: POICategory.DRY_LAB, // Herston
  33822: POICategory.DRY_LAB, // Gatton
  35989: POICategory.DRY_LAB, // Toowong
  36113: POICategory.DRY_LAB, // Long Pocket
  36112: POICategory.DRY_LAB, // Dutton Park
  32876: POICategory.EMERGENCY_POINTS, // St. Lucia
  33925: POICategory.EMERGENCY_POINTS, // Gatton
  33944: POICategory.EMERGENCY_POINTS, // Herston
  35986: POICategory.EMERGENCY_POINTS, // Toowong
  36107: POICategory.EMERGENCY_POINTS, // Long Pocket
  36106: POICategory.EMERGENCY_POINTS, // Dutton Park
  33037: POICategory.ESPORTS, // St. Lucia
  35903: POICategory.ESPORTS, // Herston
  35946: POICategory.ESPORTS, // Gatton
  36006: POICategory.ESPORTS, // Toowong
  36145: POICategory.ESPORTS, // Long Pocket
  36144: POICategory.ESPORTS, // Dutton Park
  33082: POICategory.FAITH_AND_RELIGION, // St. Lucia
  35904: POICategory.FAITH_AND_RELIGION, // Herston
  35942: POICategory.FAITH_AND_RELIGION, // Gatton
  36007: POICategory.FAITH_AND_RELIGION, // Toowong
  36146: POICategory.FAITH_AND_RELIGION, // Long Pocket
  36147: POICategory.FAITH_AND_RELIGION, // Dutton Park
  54498: POICategory.FEMININE_HYGIENE_DISPENSERS, // St. Lucia
  53462: POICategory.FEMININE_HYGIENE_DISPENSERS, // Herston
  54658: POICategory.FEMININE_HYGIENE_DISPENSERS, // Gatton
  54659: POICategory.FEMININE_HYGIENE_DISPENSERS, // Toowong
  54660: POICategory.FEMININE_HYGIENE_DISPENSERS, // Long Pocket
  54661: POICategory.FEMININE_HYGIENE_DISPENSERS, // Dutton Park
  31754: POICategory.FOOD_AND_BEVERAGE, // St. Lucia
  33911: POICategory.FOOD_AND_BEVERAGE, // Gatton
  33931: POICategory.FOOD_AND_BEVERAGE, // Herston
  35975: POICategory.FOOD_AND_BEVERAGE, // Toowong
  36080: POICategory.FOOD_AND_BEVERAGE, // Long Pocket
  36081: POICategory.FOOD_AND_BEVERAGE, // Dutton Park
  32877: POICategory.FUNCTION_VENUE, // St. Lucia
  35905: POICategory.FUNCTION_VENUE, // Herston
  35947: POICategory.FUNCTION_VENUE, // Gatton
  36008: POICategory.FUNCTION_VENUE, // Toowong
  36148: POICategory.FUNCTION_VENUE, // Toowong
  36149: POICategory.FUNCTION_VENUE, // Dutton Park
  31456: POICategory.GENERAL_TEACHING_ROOM, // St. Lucia
  33805: POICategory.GENERAL_TEACHING_ROOM, // Herston
  33824: POICategory.GENERAL_TEACHING_ROOM, // Gatton
  35966: POICategory.GENERAL_TEACHING_ROOM, // Toowong
  36050: POICategory.GENERAL_TEACHING_ROOM, // Long Pocket
  36072: POICategory.GENERAL_TEACHING_ROOM, // Dutton Park
  32282: POICategory.HAND_SANITISER, // St. Lucia
  35906: POICategory.HAND_SANITISER, // Herston
  35936: POICategory.HAND_SANITISER, // Gatton
  35964: POICategory.HAND_SANITISER, // Toowong
  36043: POICategory.HAND_SANITISER, // Long Pocket
  36069: POICategory.HAND_SANITISER, // Dutton Park
  33951: POICategory.INFO_POINT, // St. Lucia
  35907: POICategory.INFO_POINT, // Herston
  35937: POICategory.INFO_POINT, // Gatton
  36009: POICategory.INFO_POINT, // Toowong
  36151: POICategory.INFO_POINT, // Long Pocket
  36150: POICategory.INFO_POINT, // Dutton Park
  31454: POICategory.KITCHENETTE, // St. Lucia
  33803: POICategory.KITCHENETTE, // Herston
  33814: POICategory.KITCHENETTE, // Herston
  35971: POICategory.KITCHENETTE, // Toowong
  36060: POICategory.KITCHENETTE, // Long Pocket
  36077: POICategory.KITCHENETTE, // Dutton Park
  32878: POICategory.LANDMARKS, // St. Lucia
  33926: POICategory.LANDMARKS, // Gatton
  33938: POICategory.LANDMARKS, // Herston
  35978: POICategory.LANDMARKS, // Toowong
  36091: POICategory.LANDMARKS, // Long Pocket
  36090: POICategory.LANDMARKS, // Dutton Park
  31455: POICategory.LECTURE_THEATRE, // St. Lucia
  33820: POICategory.LECTURE_THEATRE, // Gatton
  33807: POICategory.LECTURE_THEATRE, // Herston
  35980: POICategory.LECTURE_THEATRE, // Toowong
  36095: POICategory.LECTURE_THEATRE, // Long Pocket
  32889: POICategory.LIBRARIES, // St. Lucia
  33921: POICategory.LIBRARIES, // Gatton
  33933: POICategory.LIBRARIES, // Herston
  35982: POICategory.LIBRARIES, // Toowong
  36098: POICategory.LIBRARIES, // Long Pocket
  36094: POICategory.LIBRARIES, // Dutton Park
  32564: POICategory.LIBRARY_ASK_US_SERVICE, // St. Lucia
  35908: POICategory.LIBRARY_ASK_US_SERVICE, // Herston
  35940: POICategory.LIBRARY_ASK_US_SERVICE, // Gatton
  35995: POICategory.LIBRARY_ASK_US_SERVICE, // Toowong
  36125: POICategory.LIBRARY_ASK_US_SERVICE, // Long Pocket
  36124: POICategory.LIBRARY_ASK_US_SERVICE, // Dutton Park
  33517: POICategory.LIBRARY_BOOK_RETURN, // St. Lucia
  35909: POICategory.LIBRARY_BOOK_RETURN, // Herston
  35943: POICategory.LIBRARY_BOOK_RETURN, // Gatton
  35996: POICategory.LIBRARY_BOOK_RETURN, // Toowong
  36127: POICategory.LIBRARY_BOOK_RETURN, // Long Pocket
  36126: POICategory.LIBRARY_BOOK_RETURN, // Dutton Park
  30517: POICategory.MEETING_ROOM, // St. Lucia
  33813: POICategory.MEETING_ROOM, // Gatton
  33799: POICategory.MEETING_ROOM, // Herston
  35963: POICategory.MEETING_ROOM, // Toowong
  36041: POICategory.MEETING_ROOM, // Long Pocket
  36068: POICategory.MEETING_ROOM, // Dutton Park
  32890: POICategory.MICROWAVES, // St. Lucia
  35910: POICategory.MICROWAVES, // Herston
  35939: POICategory.MICROWAVES, // Gatton
  35977: POICategory.MICROWAVES, // Toowong
  36089: POICategory.MICROWAVES, // Long Pocket
  36088: POICategory.MICROWAVES, // Dutton Park
  32879: POICategory.MOTORCYCLE_PARKING, // St. Lucia
  33928: POICategory.MOTORCYCLE_PARKING, // Gatton
  33934: POICategory.MOTORCYCLE_PARKING, // Herston
  35988: POICategory.MOTORCYCLE_PARKING, // Toowong
  36111: POICategory.MOTORCYCLE_PARKING, // Long Pocket
  36110: POICategory.MOTORCYCLE_PARKING, // Dutton Park
  33026: POICategory.MUSEUMS, // St. Lucia
  33916: POICategory.MUSEUMS, // Gatton
  33936: POICategory.MUSEUMS, // Herston
  35983: POICategory.MUSEUMS, // Toowong
  36100: POICategory.MUSEUMS, // Long Pocket
  36101: POICategory.MUSEUMS, // Dutton Park
  33842: POICategory.PARENTING_ROOM, // St. Lucia
  33843: POICategory.PARENTING_ROOM, // Herston
  33888: POICategory.PARENTING_ROOM, // Gatton
  35984: POICategory.PARENTING_ROOM, // Toowong
  36102: POICategory.PARENTING_ROOM, // Long Pocket
  36103: POICategory.PARENTING_ROOM, // Dutton Park
  32880: POICategory.PAYPHONES, // St. Lucia
  33942: POICategory.PAYPHONES, // Herston
  35941: POICategory.PAYPHONES, // Gatton
  35993: POICategory.PAYPHONES, // Toowong
  36120: POICategory.PAYPHONES, // Long Pocket
  36121: POICategory.PAYPHONES, // Dutton Park
  33518: POICategory.PRINTERS_STUDENT_ACCESS, // St. Lucia
  35911: POICategory.PRINTERS_STUDENT_ACCESS, // Herston
  35944: POICategory.PRINTERS_STUDENT_ACCESS, // Gatton
  35997: POICategory.PRINTERS_STUDENT_ACCESS, // Toowong
  36129: POICategory.PRINTERS_STUDENT_ACCESS, // Long Pocket
  36128: POICategory.PRINTERS_STUDENT_ACCESS, // Dutton Park
  32660: POICategory.RETAIL_AND_SPECIALTY, // St. Lucia
  33917: POICategory.RETAIL_AND_SPECIALTY, // Gatton
  33932: POICategory.RETAIL_AND_SPECIALTY, // Herston
  35965: POICategory.RETAIL_AND_SPECIALTY, // Toowong
  36045: POICategory.RETAIL_AND_SPECIALTY, // Long pocket
  36070: POICategory.RETAIL_AND_SPECIALTY, // Dutton Park
  33031: POICategory.RIDEABLES, // St. Lucia
  35912: POICategory.RIDEABLES, // Herston
  35948: POICategory.RIDEABLES, // Gatton
  36010: POICategory.RIDEABLES, // Toowong
  36153: POICategory.RIDEABLES, // Long Pocket
  36152: POICategory.RIDEABLES, // Dutton Park
  32881: POICategory.RUNNING_EXERCISE, // St. Lucia
  35913: POICategory.RUNNING_EXERCISE, // Herston
  35949: POICategory.RUNNING_EXERCISE, // Gatton
  36011: POICategory.RUNNING_EXERCISE, // Toowong
  36154: POICategory.RUNNING_EXERCISE, // Long Pocket
  36155: POICategory.RUNNING_EXERCISE, // Dutton Park
  36026: POICategory.SCHOOLS_AND_FACULTIES, // St. Lucia
  35914: POICategory.SCHOOLS_AND_FACULTIES, // Herston
  35950: POICategory.SCHOOLS_AND_FACULTIES, // Gatton
  36012: POICategory.SCHOOLS_AND_FACULTIES, // Gatton
  36157: POICategory.SCHOOLS_AND_FACULTIES, // Long Pocket
  36156: POICategory.SCHOOLS_AND_FACULTIES, // Dutton Park
  33036: POICategory.SECURITY_OFFICE, // St. Lucia
  35915: POICategory.SECURITY_OFFICE, // Herston
  33912: POICategory.SECURITY_OFFICE, // Gatton
  35999: POICategory.SECURITY_OFFICE, // Toowong
  36131: POICategory.SECURITY_OFFICE, // Long Pocket
  36130: POICategory.SECURITY_OFFICE, // Dutton Park
  30519: POICategory.SHOWERS, // St. Lucia
  33819: POICategory.SHOWERS, // Gatton
  33802: POICategory.SHOWERS, // Herston
  35970: POICategory.SHOWERS, // Toowong
  36058: POICategory.SHOWERS, // Long Pocket
  36076: POICategory.SHOWERS, // Dutton Park
  32882: POICategory.SPORTS_AND_RECREATION, // St. Lucia
  33918: POICategory.SPORTS_AND_RECREATION, // Gatton
  35916: POICategory.SPORTS_AND_RECREATION, // Herston
  36013: POICategory.SPORTS_AND_RECREATION, // Toowong
  36159: POICategory.SPORTS_AND_RECREATION, // Long Pocket
  36158: POICategory.SPORTS_AND_RECREATION, // Long Pocket
  33083: POICategory.STRETCHING_EXERCISES, // St. Lucia
  35917: POICategory.STRETCHING_EXERCISES, // Herston
  35951: POICategory.STRETCHING_EXERCISES, // Gatton
  36014: POICategory.STRETCHING_EXERCISES, // Toowong
  36161: POICategory.STRETCHING_EXERCISES, // Long Pocket
  36160: POICategory.STRETCHING_EXERCISES, // Dutton Park
  33457: POICategory.STUDY_SPOTS_BOOKABLE, // St. Lucia
  33887: POICategory.STUDY_SPOTS_BOOKABLE, // Gatton
  33937: POICategory.STUDY_SPOTS_BOOKABLE, // Herston
  36002: POICategory.STUDY_SPOTS_BOOKABLE, // Toowong
  36137: POICategory.STUDY_SPOTS_BOOKABLE, // Long Pocket
  36136: POICategory.STUDY_SPOTS_BOOKABLE, // Dutton Park
  31453: POICategory.STUDY_SPOTS_COMMON, // St. Lucia
  33886: POICategory.STUDY_SPOTS_COMMON, // Gatton
  33800: POICategory.STUDY_SPOTS_COMMON, // Herston
  35968: POICategory.STUDY_SPOTS_COMMON, // Toowong
  36054: POICategory.STUDY_SPOTS_COMMON, // Long Pocket
  36074: POICategory.STUDY_SPOTS_COMMON, // Dutton Park
  33452: POICategory.STUDY_SPOTS_TEMPORARY, // St. Lucia
  35918: POICategory.STUDY_SPOTS_TEMPORARY, // Herston
  35952: POICategory.STUDY_SPOTS_TEMPORARY, // Gatton
  36015: POICategory.STUDY_SPOTS_TEMPORARY, // Toowong
  36163: POICategory.STUDY_SPOTS_TEMPORARY, // Long Pocket
  36162: POICategory.STUDY_SPOTS_TEMPORARY, // Dutton Park
  31563: POICategory.TOILETS_ACCESSIBLE, // St. Lucia
  33817: POICategory.TOILETS_ACCESSIBLE, // Gatton
  33801: POICategory.TOILETS_ACCESSIBLE, // Herston
  35969: POICategory.TOILETS_ACCESSIBLE, // Toowong
  36056: POICategory.TOILETS_ACCESSIBLE, // Long Pocket
  36075: POICategory.TOILETS_ACCESSIBLE, // Dutton Park
  31564: POICategory.TOILETS_ALL_GENDER, // St. Lucia
  33818: POICategory.TOILETS_ALL_GENDER, // Gatton
  33906: POICategory.TOILETS_ALL_GENDER, // Herston
  35979: POICategory.TOILETS_ALL_GENDER, // Toowong
  36093: POICategory.TOILETS_ALL_GENDER, // Long Pocket
  36092: POICategory.TOILETS_ALL_GENDER, // Dutton Park
  33828: POICategory.TOILETS_AMBULANT, // St. Lucia
  36018: POICategory.TOILETS_AMBULANT, // Herston
  36019: POICategory.TOILETS_AMBULANT, // Gatton
  36020: POICategory.TOILETS_AMBULANT, // Toowong
  36084: POICategory.TOILETS_AMBULANT, // Long Pocket
  36085: POICategory.TOILETS_AMBULANT, // Dutton Park
  30486: POICategory.TOILETS_FEMALE, // St. Lucia
  33812: POICategory.TOILETS_FEMALE, // Gatton
  33797: POICategory.TOILETS_FEMALE, // Herston
  34230: POICategory.TOILETS_FEMALE, // Toowong
  36048: POICategory.TOILETS_FEMALE, // Long Pocket
  36071: POICategory.TOILETS_FEMALE, // Dutton Park
  30485: POICategory.TOILETS_MALE, // St. Lucia
  33811: POICategory.TOILETS_MALE, // Gatton
  33798: POICategory.TOILETS_MALE, // Herston
  35967: POICategory.TOILETS_MALE, // Toowong
  36052: POICategory.TOILETS_MALE, // Long Pocket
  36073: POICategory.TOILETS_MALE, // Dutton Park
  32883: POICategory.TRANSPORT, // St. Lucia
  33919: POICategory.TRANSPORT, // Gatton
  33943: POICategory.TRANSPORT, // Herston
  35976: POICategory.TRANSPORT, // Toowong
  36087: POICategory.TRANSPORT, // Long Pocket
  36086: POICategory.TRANSPORT, // Dutton Park
  32886: POICategory.UQ_SAFETY_BUS_STOPS, // St. Lucia
  35919: POICategory.UQ_SAFETY_BUS_STOPS, // Herston
  35953: POICategory.UQ_SAFETY_BUS_STOPS, // Gatton
  36016: POICategory.UQ_SAFETY_BUS_STOPS, // Toowong
  36165: POICategory.UQ_SAFETY_BUS_STOPS, // Long Pocket
  36164: POICategory.UQ_SAFETY_BUS_STOPS, // Dutton Park
  32888: POICategory.WATER_REFILL_STATIONS, // St. Lucia
  33927: POICategory.WATER_REFILL_STATIONS, // Gatton
  33930: POICategory.WATER_REFILL_STATIONS, // Herston
  35972: POICategory.WATER_REFILL_STATIONS, // Toowong
  36062: POICategory.WATER_REFILL_STATIONS, // Long Pocket
  36078: POICategory.WATER_REFILL_STATIONS, // Dutton Park
  33462: POICategory.WET_LAB, // St. Lucia
  33808: POICategory.WET_LAB, // Herston
  33816: POICategory.WET_LAB, // Gatton
  35990: POICategory.WET_LAB, // Toowong
  36115: POICategory.WET_LAB, // Long Pocket
  36114: POICategory.WET_LAB, // Dutton Park
  45939: POICategory.DEFIBRILLATORS // St. Lucia
};

const getPoiCatFromId = (id: string): POICategory => {
  return poiCatIdToEnum[id] ?? POICategory.UNKNOWN;
};

// -> primary color icons / used when poi selected
const getPrimaryIconForCategory = (category: POICategory): string => {
  switch (category) {
    case POICategory.MEETING_ROOM:
      return Icons.office_icon;
    case POICategory.OFFICE:
      return Icons.office_icon;
    case POICategory.KITCHENETTE:
      return Icons.kitchenette_icon;
    case POICategory.TOILETS_FEMALE:
      return Icons.femaletoilets_icon;
    case POICategory.TOILETS_MALE:
      return Icons.maletoilets_icon;
    case POICategory.TOILETS_ACCESSIBLE:
      return Icons.accessible_facilities_icon;
    case POICategory.SHOWERS:
      return Icons.shower_icon;
    case POICategory.TOILETS_ALL_GENDER:
      return Icons.all_gender_toilets_icon;
    case POICategory.CAR_PARKING:
      return Icons.car_park_icon;
    case POICategory.LANDMARKS:
      return Icons.landmark_icon;
    case POICategory.BICYCLE_STORAGE_ROOMS:
      return Icons.bike_storage_icon;
    case POICategory.HAND_SANITISER:
      return Icons.hand_sanitizer_icon;
    case POICategory.RETAIL_AND_SPECIALTY:
      return Icons.retail_icon;
    case POICategory.GENERAL_TEACHING_ROOM:
      return Icons.teaching_room_icon;
    case POICategory.STUDY_SPOTS_COMMON:
      return Icons.study_space_icon;
    case POICategory.WATER_REFILL_STATIONS:
      return Icons.water_refill_icon;
    case POICategory.FOOD_AND_BEVERAGE:
      return Icons.food_beverage_icon;
    case POICategory.TRANSPORT:
      return Icons.transport_icon;
    case POICategory.MICROWAVES:
      return Icons.microwave_icon;
    case POICategory.LECTURE_THEATRE:
      return Icons.lecture_theatres_icon;
    case POICategory.BIKE_RACKS:
      return Icons.bike_rack_icon;
    case POICategory.LIBRARIES:
      return Icons.library_icon;
    case POICategory.MUSEUMS:
      return Icons.museum_icon;
    case POICategory.PARENTING_ROOM:
      return Icons.parenting_facility_icon;
    case POICategory.EMERGENCY_POINTS:
      return Icons.emergency_point_icon;
    case POICategory.COMPUTER_LAB_TEACHING:
      return Icons.computer_lab_teaching_icon;
    case POICategory.MOTORCYCLE_PARKING:
      return Icons.motorcycle_parking_icon;
    case POICategory.DRY_LAB:
      return Icons.dry_lab_icon;
    case POICategory.WET_LAB:
      return Icons.wet_lab_icon;
    case POICategory.BANKS_AND_ATMS:
      return Icons.bank_atm_icon;
    case POICategory.BICYCLE_REPAIR_STATIONS:
      return Icons.bike_repair_icon;
    case POICategory.PAYPHONES:
      return Icons.payphone_icon;
    case POICategory.BIKE_BOXES:
      return Icons.bike_box_icon;
    case POICategory.LIBRARY_ASK_US_SERVICE:
      return Icons.askus_icon;
    case POICategory.LIBRARY_BOOK_RETURN:
      return Icons.book_return_icon;
    case POICategory.PRINTERS_STUDENT_ACCESS:
      return Icons.printer_icon;
    case POICategory.SECURITY_OFFICE:
      return Icons.security_office_icon;
    case POICategory.BABY_CHANGE_ROOM:
      return Icons.parenting_facility_icon;
    case POICategory.STUDY_SPOTS_BOOKABLE:
      return Icons.study_space_icon;
    case POICategory.BENCH_EXERCISES:
      return Icons.bench_exercise_icon;
    case POICategory.CHILD_CARE_FACILITY:
      return Icons.parenting_facility_icon;
    case POICategory.COMPUTERS_OPEN_ACCESS:
      return Icons.computer_lab_icon;
    case POICategory.ESPORTS:
      return Icons.esports_icon;
    case POICategory.FAITH_AND_RELIGION:
      return Icons.faith_and_religion_icon;
    case POICategory.FUNCTION_VENUE:
      return Icons.function_venue_icon;
    case POICategory.INFO_POINT:
      return Icons.info_point_icon;
    case POICategory.RIDEABLES:
      return Icons.citycycle_icon;
    case POICategory.RUNNING_EXERCISE:
      return Icons.running_exercise_icon;
    case POICategory.SCHOOLS_AND_FACULTIES:
      return Icons.schools_and_faculties_icon;
    case POICategory.SPORTS_AND_RECREATION:
      return Icons.sport_and_fitness_icon;
    case POICategory.STRETCHING_EXERCISES:
      return Icons.stretching_exercise_icon;
    case POICategory.STUDY_SPOTS_TEMPORARY:
      return Icons.study_space_icon;
    case POICategory.UQ_SAFETY_BUS_STOPS:
      return Icons.safety_bus_stop_icon;
    case POICategory.TOILETS_AMBULANT:
      return Icons.ambulent_toilet;
    case POICategory.DEFIBRILLATORS:
      return Icons.defibrillator_icon;
    case POICategory.FEMININE_HYGIENE_DISPENSERS:
      return Icons.feminine_hygiene_dispensers_icon;
    default:
      return defaultIconPrimary;
  }
};

const getIconForCategory = (category: POICategory): string => {
  switch (category) {
    case POICategory.MEETING_ROOM:
      return Icons.office_icon_white;
    case POICategory.OFFICE:
      return Icons.office_icon_white;
    case POICategory.KITCHENETTE:
      return Icons.kitchenette_icon_white;
    case POICategory.TOILETS_FEMALE:
      return Icons.femaletoilets_icon_white;
    case POICategory.TOILETS_MALE:
      return Icons.maletoilets_icon_white;
    case POICategory.TOILETS_ACCESSIBLE:
      return Icons.accessible_facilities_icon_white;
    case POICategory.SHOWERS:
      return Icons.shower_icon_white;
    case POICategory.TOILETS_ALL_GENDER:
      return Icons.all_gender_toilets_icon_white;
    case POICategory.CAR_PARKING:
      return Icons.car_park_icon_white;
    case POICategory.LANDMARKS:
      return Icons.landmark_icon_white;
    case POICategory.BICYCLE_STORAGE_ROOMS:
      return Icons.bike_storage_icon_white;
    case POICategory.HAND_SANITISER:
      return Icons.hand_sanitizer_icon_white;
    case POICategory.RETAIL_AND_SPECIALTY:
      return Icons.retail_icon_white;
    case POICategory.GENERAL_TEACHING_ROOM:
      return Icons.teaching_room_icon_white;
    case POICategory.STUDY_SPOTS_COMMON:
      return Icons.study_space_icon_white;
    case POICategory.WATER_REFILL_STATIONS:
      return Icons.water_refill_icon_white;
    case POICategory.FOOD_AND_BEVERAGE:
      return Icons.food_beverage_icon_white;
    case POICategory.TRANSPORT:
      return Icons.transport_icon_white;
    case POICategory.MICROWAVES:
      return Icons.microwave_icon_white;
    case POICategory.LECTURE_THEATRE:
      return Icons.lecture_theatres_icon_white;
    case POICategory.BIKE_RACKS:
      return Icons.bike_rack_icon_white;
    case POICategory.LIBRARIES:
      return Icons.library_icon_white;
    case POICategory.MUSEUMS:
      return Icons.museum_icon_white;
    case POICategory.PARENTING_ROOM:
      return Icons.parenting_facility_icon_white;
    case POICategory.EMERGENCY_POINTS:
      return Icons.emergency_point_icon_white;
    case POICategory.COMPUTER_LAB_TEACHING:
      return Icons.computer_lab_teaching_icon_white;
    case POICategory.MOTORCYCLE_PARKING:
      return Icons.motorcycle_parking_icon_white;
    case POICategory.DRY_LAB:
      return Icons.dry_lab_icon_white;
    case POICategory.WET_LAB:
      return Icons.wet_lab_icon_white;
    case POICategory.BANKS_AND_ATMS:
      return Icons.bank_atm_icon_white;
    case POICategory.BICYCLE_REPAIR_STATIONS:
      return Icons.bike_repair_icon_white;
    case POICategory.PAYPHONES:
      return Icons.payphone_icon_white;
    case POICategory.BIKE_BOXES:
      return Icons.bike_box_icon_white;
    case POICategory.LIBRARY_ASK_US_SERVICE:
      return Icons.askus_icon_white;
    case POICategory.LIBRARY_BOOK_RETURN:
      return Icons.book_return_icon_white;
    case POICategory.PRINTERS_STUDENT_ACCESS:
      return Icons.printer_icon_white;
    case POICategory.SECURITY_OFFICE:
      return Icons.security_office_icon_white;
    case POICategory.BABY_CHANGE_ROOM:
      return Icons.parenting_facility_icon_white;
    case POICategory.STUDY_SPOTS_BOOKABLE:
      return Icons.study_space_icon_white;
    case POICategory.BENCH_EXERCISES:
      return Icons.bench_exercise_icon_white;
    case POICategory.CHILD_CARE_FACILITY:
      return Icons.parenting_facility_icon_white;
    case POICategory.COMPUTERS_OPEN_ACCESS:
      return Icons.computer_lab_icon_white;
    case POICategory.ESPORTS:
      return Icons.esports_icon_white;
    case POICategory.FAITH_AND_RELIGION:
      return Icons.faith_and_religion_icon_white;
    case POICategory.FUNCTION_VENUE:
      return Icons.function_venue_icon_white;
    case POICategory.INFO_POINT:
      return Icons.info_point_icon_white;
    case POICategory.RIDEABLES:
      return Icons.citycycle_icon_white;
    case POICategory.RUNNING_EXERCISE:
      return Icons.running_exercise_icon_white;
    case POICategory.SCHOOLS_AND_FACULTIES:
      return Icons.schools_and_faculties_icon_white;
    case POICategory.SPORTS_AND_RECREATION:
      return Icons.sport_and_fitness_icon_white;
    case POICategory.STRETCHING_EXERCISES:
      return Icons.stretching_exercise_icon_white;
    case POICategory.STUDY_SPOTS_TEMPORARY:
      return Icons.study_space_icon_white;
    case POICategory.UQ_SAFETY_BUS_STOPS:
      return Icons.safety_bus_stop_icon_white;
    case POICategory.TOILETS_AMBULANT:
      return Icons.ambulent_toilet;
    case POICategory.DEFIBRILLATORS:
      return Icons.defibrillator_icon_white;
    case POICategory.FEMININE_HYGIENE_DISPENSERS:
      return Icons.feminine_hygiene_dispensers_icon_white;
    default:
      return defaultIcon;
  }
};

export const ensureCategoryIconsAddedToMap = (map: MapEx): Promise<unknown> => {
  return Promise.all(Object.keys(poiCatIdToEnum).flatMap(id => {
    const category = getPoiCatFromId(id);

    const primaryImageKey = `${categoryPrimaryIconPrefix}${id}`;
    const primaryElement = getPrimaryIconForCategory(category);

    const imageKey = `${categoryIconPrefix}${id}`;
    const element = getIconForCategory(category);

    return [
      loadImage(map, primaryImageKey, primaryElement),
      loadImage(map, imageKey, element)
    ];
  }));
};

export const getCategoryIcon = (id: string): string => {
  return getIconForCategory(getPoiCatFromId(id));
};

export const getPrimaryCategoryIcon = (id: string): string => {
  return getPrimaryIconForCategory(getPoiCatFromId(id));
};

export const categoryIconExists = (id: string): boolean => {
  return Boolean(poiCatIdToEnum[id]);
};
