import React, { ReactElement } from "react";
import { useDispatch } from "react-redux";
import { swapOriginAndDestination
} from "../../../store/wayFinding/wayFindingSlice";
import IconButton from "../../../components/IconButton";
import { SWAP_ICON } from "../../../constants";

interface SwapIconButtonProps {
  icon: ReactElement;
  className: string;
}
export default function SwapIconButton(props: SwapIconButtonProps): ReactElement {
  const dispatch = useDispatch();
  const { icon: iconProps, className: classNameProps } = props;

  return (
    <div>
      <IconButton
        icon={iconProps}
        onClick={(): void => {
          dispatch(swapOriginAndDestination());
        }}
        ariaLabel={SWAP_ICON}
        className={classNameProps}
      />
    </div>
  );
}