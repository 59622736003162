import React, { ReactElement } from "react";
import styles from "./itemDescriptionTitle.module.scss";
import { ItemDetail } from "../../../app/models/ItemDetail";

interface itemTitleProps {
  itemDetail?: ItemDetail;
}

export default function ItemDescriptionTitle(props: itemTitleProps): ReactElement {
  const { itemDetail: itemDetail } = props;
  return (
    <>
      <p className={styles.primaryItemTitle}>{itemDetail?.title}</p>
      {itemDetail?.buildingName && (
        <>
          <span className={styles.primaryItemName}>{itemDetail.buildingName}</span>
          <br />
        </>
      )}
    </>
  );
}
