const diff = (obj1: Record<string, unknown>, obj2: Record<string, unknown>): Record<string, unknown> => {
  const toMutate: Record<string, unknown> = {};
  Array.from(new Set([...Object.keys(obj1), ...Object.keys(obj2)])).forEach(key => {
    if (obj1[key] !== obj2[key]) {
      toMutate[key] = obj2[key];
    }
  });
  return toMutate;
};

export default diff;
