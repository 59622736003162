import React, { ReactElement } from "react";
import styles from "./routeDetails.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  moveToRouteStep,
  showRouteInfoTab,
  toggleShowCurrentRouteDetails
} from "../../../store/wayFinding/wayFindingSlice";
import { RootState } from "../../../app/store";
import { routeFeatureIcons } from "../routeFeatureIcons";
import RoutePath from "../../../assets/wayfinding/RoutePath.svg";
import { ROUTE_IMG } from "../../../constants";


export default function RouteDetailsWayFinding(): ReactElement {
  const dispatch = useDispatch();
  const currentRoute = useSelector((state: RootState) => state.wayFinding.currentRoute);
  const showTab = useSelector((state: RootState) => state.wayFinding.currentRouteInfoTab);
  const isLandscape = useSelector((state: RootState) => state.settings.useLandscapeLayout);

  const tabs = ["Route", "Details"] as ("Route" | "Details")[];

  function handleStepClick(stepIndex: number): void {
    if (isLandscape) {
      dispatch(toggleShowCurrentRouteDetails(false));
    }
    dispatch(moveToRouteStep(stepIndex));
  }

  function handleTabChange(value: "Route" | "Details"): void {
    dispatch(showRouteInfoTab(value));
  }

  const tabDetails = (): ReactElement[] | false => {
    return showTab === "Details" && currentRoute !== undefined && currentRoute.instructions.map((item, index) => {
      return (
        <p key={`instruction-${index}`} className={styles.routeDetailsDescription}>
          <span>{`Step ${index + 1}.`}</span>
          <span className={styles.body3} style={{ color: "rgba(0, 0, 0, 0.87)" }}>
            {item.instruction}
          </span>
        </p>
      );
    });
  };

  const tabRoute = (): ReactElement[] | false => {
    return (
      showTab === "Route"
      && currentRoute !== undefined
      && currentRoute.steps.map((item, index) => {
        return (
          <React.Fragment key={`step${index}-${item.step}`}>
            <div onClick={(): void => handleStepClick(index)} className={styles.routeDetailsListItem}>
              <p className={styles.routeDetailsIconContainer}>
                <img
                  src={routeFeatureIcons[item.routePoint ?? ""]}
                  className={styles.routeDetailsIcon}
                  alt={item.routePoint}
                />
              </p>
              <span className={styles.routeDetailsListItemText}>{item.step}</span>
            </div>
            {index !== currentRoute.steps.length - 1 && (
              <p className={styles.routeDetailDots}>
                <img src={RoutePath} className={styles.routeDetailsIcon} alt={ROUTE_IMG} />
              </p>
            )}
          </React.Fragment>
        );
      })
    );
  };
  
  return (
    <div className={styles.instructions}>
      <ul className={styles.tabs}>
        {tabs.map(label => {
          return (
            <li
              tabIndex={1}
              onClick={(): void => handleTabChange(label)}
              onKeyPress={(): void => handleTabChange(label)}
              className={showTab === label ? styles.active : undefined}
              key={label}
            >
              {label}
            </li>
          );
        })}
      </ul>
      <div className={styles.tabWrap}>
        {/* showTab === 'Details'*/}
        {tabDetails()}
        {/*showTab === 'Route'*/}
        {tabRoute()}
      </div>
    </div>
  );
}
