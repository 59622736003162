import { IProvideLiveData } from "./IProvideLiveData";
import Api from "./ApiService";
import { ILiveDataSet } from "./ILiveDataSet";
import { ComputerPoiI, ItemStatusDetailI, ItemStatusI } from "../util/types";
import { getCalcLiveData } from "../features/live/liveDataUtil";
import { LiveDataCategoryType } from "./LiveDataCategoryType";

export class LiveComputers implements IProvideLiveData {

  public static categoryName = "computers";

  public static categoryId = LiveDataCategoryType.COMPUTERS;

  public async getLiveData(campusId: string): Promise<ILiveDataSet> {
    const computerData = await Api.getComputerData(campusId);

    const computerStatusData = computerData.map((computerPoi: ComputerPoiI): ItemStatusI => {
      let status: ItemStatusDetailI | undefined;

      if (computerPoi.status) {
        const free = computerPoi.status.avail;
        const total = computerPoi.status.total;
        const used = total - free;

        status = {
          total,
          free,
          used
        };
      }
      return {
        poiId: computerPoi.poiId,
        name: computerPoi.title,
        status
      };
    });
    const liveData = getCalcLiveData(computerStatusData, LiveComputers.categoryName);
    return { data: liveData.data, totals: liveData.totals, categoryId: LiveComputers.categoryId };
  }
}
