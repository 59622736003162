import React, { ReactNode } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { initialState, setSetting, setSettings, SiteFeatureKey, SiteFeatures } from "./settingsSlice";
import _ from "lodash";
import Modal from "../../components/Modal";
import styles from "../feedbackFormModal/FeedbackFormModal.module.scss";
import ToggleSwitch from "../../components/ToggleSwitch";
import classnames from "classnames";

export interface AppSettingsProps extends PropsFromRedux {
  showSettings?: boolean;
  onDrawerClose(): void;
  version?: string;
}

class AppSettings extends React.Component<AppSettingsProps & RouteComponentProps> {
  constructor(props: AppSettingsProps & RouteComponentProps) {
    super(props);
    const params = new URLSearchParams(props.location.search);
    const changes = AppSettings.getStoreDiff(params, props.siteFeatures);
    props.dispatch(setSettings(changes));
  }

  public shouldComponentUpdate(): boolean {
    return true;
  }

  // Set app settings based on url once
  private static getStoreDiff(
    source: URLSearchParams,
    destination: SiteFeatures
  ): { [k in SiteFeatureKey]?: boolean } {
    const changes: { [k in SiteFeatureKey]?: boolean } = {};

    for (const k of Object.keys(destination) as SiteFeatureKey[]) {
      if (source.has(k)) changes[k] = source.get(k) === "true";
    }
    return changes;
  }

  private handleToggle(k: SiteFeatureKey, v: boolean): void {
    const { location, dispatch, history } = this.props;

    const newValue = !v;
    const params = new URLSearchParams(location.search);
    dispatch(setSetting({ key: k, value: newValue }));

    if (newValue !== initialState.siteFeatures[k]) {
      params.set(k, newValue.toString());
    } else {
      params.delete(k);
    }
    history.push({ search: params.toString() });
  }

  public render(): ReactNode {
    const { siteFeatures, showSettings, onDrawerClose, version } = this.props;
    return !showSettings ? null : (
      <Modal
        show={Boolean(true)}
        onClose={onDrawerClose}
        portalContainer={document.getElementById("root")}
        overlayPalette={"dark"}
        className={classnames(styles.mapModal, styles.settingsModal)}
      >
        <div className={styles.settingsWrap}>
          <h2>{`Version: ${version}`}</h2>
          <p>{"Experimental Site Features v2"}</p>
          <ul className={styles.settingsList}>
            {Object.entries(siteFeatures).map(([siteFeature, v], index) => {
              return (
                <li key={siteFeature} className={styles.settingsListItem}>
                  <p>
                    <span>{`${_.startCase(siteFeature)} (${siteFeature}=true|false)`}</span>
                  </p>
                  <div>
                    <ToggleSwitch
                      id={index}
                      onChange={(): void => this.handleToggle(siteFeature as SiteFeatureKey, v)}
                      checked={v}
                    />
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (
  state: RootState
): {
  settings: typeof state.settings;
  siteFeatures: typeof state.settings.siteFeatures;
} => {
  return {
    settings: state.settings,
    siteFeatures: state.settings.siteFeatures
  };
};

function mapDispatchToProps(dispatch: Dispatch): {
  dispatch: Dispatch;
} {
  return {
    dispatch,
    ...bindActionCreators({}, dispatch)
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(AppSettings));

type PropsFromRedux = ConnectedProps<typeof connector>;
