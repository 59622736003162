import React, { forwardRef, MutableRefObject, useState } from "react";
import classnames from "classnames";
import styles from "./dropdown.module.scss";
import { useOnClickOutside } from "../../hooks";

interface DropdownOption {
  label: string;
  value: string | number;
}

interface DropdownProps {
  onClick(option: DropdownOption): void;
  header: string;
  options: DropdownOption[];
  classList?: string | null;
}

type RefType = ((instance: HTMLDivElement | null) => void) | MutableRefObject<HTMLDivElement | null> | null;

const Dropdown = forwardRef<HTMLDivElement, DropdownProps>((props: DropdownProps, ref: RefType) => {
  const [isOpened, setIsOpened] = useState(false);
  const { onClick, header, options, classList } = props;

  // Hook
  useOnClickOutside(ref as {
    current: HTMLDivElement;
  }, () => {
    switchVisibility(false);
  });

  const switchVisibility = (isOpened: boolean): void => {
    setIsOpened(isOpened);
  };

  return (
    <div className={styles.mapDropdown} ref={ref}>
      <div
        tabIndex={1}
        onClick={(): void => {
          switchVisibility(!isOpened);
        }}
        onKeyPress={(): void => {
          switchVisibility(!isOpened);
        }}
        className={classnames(`${isOpened ? styles.open : styles.close}`, styles.mapDropdownHeader)}
      >
        {header}
      </div>
      {isOpened && (
        <ul className={classnames(styles.mapDropdownList, classList)}>
          {options.map(option => {
            return (
              <li
                key={option.value}
                onClick={(): void => {
                  onClick(option);
                  switchVisibility(false);
                }}
                className={
                  header === `Floor ${option.value}` || header === option.label
                    ? styles.active
                    : "none"
                }
                onKeyPress={(): void => {
                  onClick(option);
                }}
                tabIndex={1}
              >
                {option.label}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
});

Dropdown.displayName = "Dropdown";

export default Dropdown;
