import React, { ReactElement, useEffect } from "react";
import { CustomMap } from "../customMap/CustomMap";
import { connect, ConnectedProps } from "react-redux";
import { fetchCategories, moveMapTo, processUrl, setCampuses } from "./../../store/map/mapSlice";
import { ActionCreatorWithPayload, bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import MazeMapDataService from "../../services/MazeMapDataService";
import Layers from "../layers/Layers";
import UserMarker from "../orientationPanel/UserMarker";
import SelectedItemHighlighter from "./SelectedItemHighlighter";
import { LatLng } from "../../app/models/LatLng";
import { MapParameters } from "../../app/models/MapParameters";
import { setDropPin } from "./dropPin";
import { fetchCategoryResults, fetchCategoryResultsForMap } from "../../store/search/searchSlice";
import { beginWayFinding, displayRoute } from "../../store/wayFinding/wayFindingSlice";
import { AppThunk, RootState } from "../../app/store";
import { WayFindingPoint } from "../../app/models/WayFindingState";
import { MapDataService } from "../../services/MapDataService";
import { useMap } from "./MapProvider";
import { Campus, PointOfInterest } from "../../types/UQMapsTypes";

export type MapProps = PropsFromRedux;

function updateStoreWithInitialValues(
  dispatch: Dispatch,
  campuses: Campus[],
  canonicalParameters: MapParameters,
  fetchCategories: (mapData: MapDataService, campusId: string) => void
): void {
  dispatch(setCampuses(campuses));
  dispatch(processUrl(canonicalParameters));
  fetchCategories(MazeMapDataService, canonicalParameters.campusId ?? campuses[0].properties.id);
}

function Map(props: MapProps): ReactElement {
  const {
    dispatch,
    moveMapTo,
    fetchCategories,
    fetchCategoryResults,
    setDropPin,
    displayRoute,
    fetchCategoryResultsForMap
  } = props;
  const { map, params } = useMap() ?? {};
  const id = "map1";

  // initialise redux store with initial state values.
  useEffect(() => {
    if (!params) return;
    const { campuses, canonicalParameters } = params;
    updateStoreWithInitialValues(dispatch, campuses, canonicalParameters, fetchCategories);
  }, [params, dispatch, fetchCategories]);

  // set initial pin, category, and wayfinding state.
  // TODO: do these need to be initialised after the map is loaded or can we do it beforehand?
  useEffect(() => {
    if (!map || !params) return;

    const { poi, defaultCampusId, category, wayfindingParameters } = params;

    if (poi) {
      setDropPin(poi);
    }

    if (category) {
      fetchCategoryResults(defaultCampusId, category.id, category.title, 0);
      fetchCategoryResultsForMap(defaultCampusId, category.id);
    }

    if (wayfindingParameters.origin && wayfindingParameters.destination) {
      // start wayfinding and display route (origin and destination supplied)
      displayRoute(wayfindingParameters.origin, wayfindingParameters.destination);
    } else if (wayfindingParameters.origin || wayfindingParameters.destination) {
      // open wayfinding (origin or destination supplied)
      dispatch(
        beginWayFinding({
          origin: wayfindingParameters.origin,
          destination: wayfindingParameters.destination
        })
      );
    }
  }, [map, params, dispatch, displayRoute, setDropPin, fetchCategoryResults, fetchCategoryResultsForMap]);

  return !map ? (
    <></>
  ) : (
    <>
      <CustomMap id={id} map={map} onMapViewPortChanged={moveMapTo} />
      <Layers map={map} />
      <UserMarker map={map} />
      <SelectedItemHighlighter map={map} />
    </>
  );
}

const mapStateToProps = (
  state: RootState
): {
  campusId: string;
  campuses: Campus[];
} => {
  return {
    campusId: state.map.campusId ?? "",
    campuses: state.map.campuses
  };
};

function mapDispatchToProps(dispatch: Dispatch): {
  dispatch: Dispatch;
  setDropPin(dropPin?: PointOfInterest): AppThunk<void>;
  processUrl: ActionCreatorWithPayload<MapParameters>;
  fetchCategories(mapData: MapDataService, campusId: string): AppThunk<Promise<void>>;
  fetchCategoryResults(
    campusId: string,
    categoryId: string,
    categoryTitle: string,
    pageNumber: number
  ): AppThunk<Promise<void>>;
  fetchCategoryResultsForMap(campusId: string, categoryId: string): AppThunk<void>;
  displayRoute(origin: WayFindingPoint, destination: WayFindingPoint): AppThunk<Promise<void>>;
  moveMapTo: ActionCreatorWithPayload<{
    centre?: LatLng;
    zoom?: number;
    zLevel?: number;
    rotation?: number;
    pitch?: number;
  }>;
} {
  return {
    dispatch,
    ...bindActionCreators(
      {
        moveMapTo,
        setDropPin,
        fetchCategoryResultsForMap,
        processUrl,
        fetchCategoryResults,
        displayRoute,
        fetchCategories
      },
      dispatch
    )
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Map);

type PropsFromRedux = ConnectedProps<typeof connector>;
