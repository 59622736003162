import React, { ReactElement, useEffect, useRef, useState, useCallback, ReactNode } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import styles from "./MapsApp.module.scss";
import AppSettings from "./features/settings/AppSettings";
import { STAGE, VERSION } from "./env";
import Map from "./features/map/Map";
import { TrackingService } from "./features/tracking/TrackingService";
import MobileRouteModal from "./features/wayFinding/mobileRoutePanel/mobileRoutePanel";
import LayersSelectionModal from "./features/layersSelectionModal";
import FeedbackFormModal from "./features/feedbackFormModal";
import CopyrightPanel from "./features/copyrightPanel";
import { RootState } from "./app/store";
import AboutModal from "./features/aboutModal";
import SearchField from "./features/search/searchField";
import MapControls from "./features/mapControls";
import SearchResults from "./features/search/searchResults";
import IconButton from "./components/IconButton";
import { ReactComponent as NavMenu } from "../src/assets/Icons/navigation-menu.svg";
import { ReactComponent as MapSearch } from "../src/assets/Icons/maps-search.svg";
import { ReactComponent as ArrowBack } from "../src/assets/Icons/arrow-left.svg";
import WayFinding from "./features/wayFinding/wayFinding";
import {
  revertDefaultValue,
  endPointSelection
} from "./store/wayFinding/wayFindingSlice";
import classnames from "classnames";
import RouteDetailsWayFinding from "./features/wayFinding/routeDetails";
import { PROGRESS, SEARCH_BACK, SWITCH_VIEW } from "./constants";
import { LatLng } from "./app/models/LatLng";
import { ItemDetail } from "./app/models/ItemDetail";
import { LngLat } from "mapbox-gl";
import PointSelectionModal from "./features/wayFinding/pointSelection";
import RouteSummaryWayFinding from "./features/wayFinding/routeSummary";
import WayFindingLightView from "./features/wayFinding/wayFindingLightView";
import AlertNotification from "./features/alertNotification";
import { MapProvider } from "./features/map/MapProvider";
import { removeLayer } from "./store/layers/layerSlice";
import { dropPinLayerName } from "./features/map/dropPin";
import ItemDescriptionDesktop from "./features/ItemDescription/itemDescriptionDesktop";
import ItemDescriptionMobile from "./features/ItemDescription/itemDescriptionMobile";


export function getLocationLabel(pos: LatLng | ItemDetail | undefined): string {
  const itemD = pos as ItemDetail;
  const ll = pos;
  if (itemD.title) return itemD.title;
  if (itemD.description) return itemD.description;
  if (itemD.buildingName) return itemD.buildingName;
  if (ll) return `(${(ll as LngLat).lng}, ${(ll as LngLat).lat})`;
  return "";
}

export default function MapsApp(): ReactElement {
  // Local State
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [lightView, setLightView] = useState(false);
  const isLandscape = useSelector((state: RootState) => state.settings.useLandscapeLayout);
  const isCampusesLoading = useSelector((state: RootState) => state.map.isCampusesLoading);
  const [panelOpen, setPanelOpen] = useState(!isLandscape);

  const dispatch = useDispatch();
  const store = useStore<RootState>();
  const initialCampusId = store.getState().map.campusId;

  // Global State
  const openItemDetail = useSelector((state: RootState) => state.map.openItemDetail);
  const wayFinding = useSelector((state: RootState) => state.wayFinding);
  const currentRoute = wayFinding.currentRoute;
  const isRouteLoading = wayFinding.isRouteLoading;
  const isWayFinding = wayFinding.isWayFinding;

  const { sidebar, controls, search } = useSelector((s: RootState) => s.settings.siteFeatures);

  const clickPanelSection = useCallback((visibility: boolean) => {
    setPanelOpen(visibility);
  }, []);
  
  useEffect(() => {
    setPanelOpen(!isLandscape);
  }, [isLandscape]);

  function viewButton(): ReactElement {
    if (panelOpen) {
      return (
        <>
          <MapSearch className={styles.mapIcon} />
          &nbsp;&nbsp;{"View Map"}
        </>
      );
    }
    return (
      <>
        <NavMenu className={styles.listIcon} />
        &nbsp;&nbsp;{"View List"}
      </>
    );
  }

  useEffect(() => {
    if (isRouteLoading && isLandscape) {
      setLightView(true);
      setPanelOpen(false);
    }
    if (!isLandscape) {
      setLightView(false);
    }
    if (panelOpen && isLandscape) {
      dispatch(endPointSelection());
      dispatch(removeLayer(dropPinLayerName));
    }
  }, [dispatch, panelOpen, isRouteLoading, isLandscape, lightView]);

  const panelToggleClass = panelOpen ? "" : styles.hide;

  const searchFieldClass = classnames(
    styles.searchFinding,
    panelToggleClass,
    isWayFinding ? styles.searchWayFinding : null,
    isWayFinding && isLandscape && lightView ? styles.wayFindingLightView : null
  );

  const searchPaneClass = classnames(
    styles.searchPane,
    panelToggleClass,
    !openItemDetail ? styles.openItemDetail : null,
    lightView && isLandscape ? styles.wayFindingLightViewPane : null
  );
  const searchResultsClass = classnames(
    styles.searchResults,
    isWayFinding ? styles.searchResultsWayFinding : null
  );

  function backArrowButton(): ReactElement {
    return (
      <IconButton
        ariaLabel={SEARCH_BACK}
        className={styles.arrowLeftIcon}
        onClick={(): void => {
          dispatch(revertDefaultValue());
          setLightView(false);
          if (isLandscape) {
            setPanelOpen(false);
          }
        }}
      >
        <ArrowBack />
      </IconButton>
    );
  }

  function stageButton(): ReactElement | null {
    if (STAGE && STAGE !== "PROD") {
      return (
        <div className={styles.stage}>
          <button onClick={(): void => setDrawerOpen(!drawerOpen)}>{STAGE}</button>
        </div>
      );
    }
    return null;
  }

  function routeDetailsDesktop(): ReactElement | false {
    return (
      !isLandscape
      && Boolean(currentRoute) && (
        <div className={styles.routeDetails}>
          <RouteSummaryWayFinding routeSummaryClassName={styles.summaryShare} />
          <RouteDetailsWayFinding />
        </div>
      )
    );
  }

  const searchResultsRef = useRef<HTMLDivElement>(null);
  function searchResultsView(): ReactElement | false {
    return (
      !currentRoute
      && !isRouteLoading && (
        <SearchResults
          panelOpen={panelOpen}
          clickPanelSection={clickPanelSection}
          searchType={isWayFinding ? "wayfinding" : "search"}
          ref={searchResultsRef}
        />
      )
    );
  }

  function searchResultsLoading(): ReactElement {
    return (
      <div className={styles.loadingButtonWrap}>
        <IconButton ariaLabel={PROGRESS} className={classnames("uq-loading-spinner", styles.loadingIndicator)} />
      </div>
    );
  }

  function togglePanelButton(classNameProp: string, icon?: ReactElement): ReactNode {
    return sidebar && (
      <IconButton
        onClick={(): void => clickPanelSection(!panelOpen)}
        icon={icon}
        ariaLabel={SWITCH_VIEW}
        className={classNameProp}
      />
    );
  }

  return (
    <>
      <div className={styles.mapsApp}>
        <MapProvider initialCampusId={initialCampusId}>
          <Map />
        </MapProvider>
        <>
          {search && <div className={searchFieldClass}>
            {!isWayFinding && <SearchField panelOpen={panelOpen} clickPanelSection={clickPanelSection} />}
            {isWayFinding && backArrowButton()}
            {isWayFinding && !lightView && (
              <WayFinding
                setPanelOpen={setPanelOpen}
                setLightView={setLightView}
                resultsRef={searchResultsRef}
              />
            )}
            {isWayFinding && lightView && isLandscape && (
              <WayFindingLightView
                lightView={lightView}
                setPanelOpen={setPanelOpen}
                setLightView={setLightView}
              />
            )}
          </div>}
          {search && <div className={searchPaneClass}>
            <div className={searchResultsClass}>
              {openItemDetail && !isWayFinding && (
                isLandscape
                  ? <ItemDescriptionMobile setPanelOpen={setPanelOpen} />
                  : <ItemDescriptionDesktop setPanelOpen={setPanelOpen} />
              )}
              {(!openItemDetail || (Boolean(openItemDetail) && isWayFinding)) && searchResultsView()}
              {Boolean(isRouteLoading) && searchResultsLoading()}
              {!isRouteLoading && routeDetailsDesktop()}
              {/*mobile view open/close left panel*/}
              {!lightView && !isWayFinding && togglePanelButton(styles.viewIcon, viewButton())}
            </div>
          </div>}
        </>
        {/*desktop view open/close left panel */}
        {!isLandscape && togglePanelButton(classnames(styles.slidePaneButton, panelToggleClass))}
        {!lightView && controls && !isCampusesLoading && <MapControls />}
        <CopyrightPanel />
      </div>
      {/*Modals*/}
      {currentRoute && Boolean(currentRoute) && isLandscape && (
        <MobileRouteModal
          panelOpen={panelOpen}
          setPanelOpen={setPanelOpen}
          setLightView={setLightView}
        />
      )}
      <PointSelectionModal />
      <FeedbackFormModal />
      <AboutModal />
      <LayersSelectionModal />

      <TrackingService />
      <AlertNotification />
      <AppSettings version={VERSION} showSettings={drawerOpen} onDrawerClose={(): void => setDrawerOpen(false)} />
      {stageButton()}
    </>
  );
}
