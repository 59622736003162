import React, { ReactElement, useEffect, useRef } from "react";
import styles from "./wayFinding.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { ReactComponent as SwapIcon } from "../../../assets/Swap.svg";
import {
  selectedLocation,
  chooseFromTheMap,
  setIsNavigatingState,
  updateWayFindingOrigin,
  updateWayFindingDestination,
  endSelection,
  endPointSelection
} from "../../../store/wayFinding/wayFindingSlice";
import { ItemDetail } from "../../../app/models/ItemDetail";
import WayFindingField from "../wayFindingField";
import { getLocationLabel } from "../../../MapsApp";
import SwapIconButton from "../swapIconButton";
import { removeLayer } from "../../../store/layers/layerSlice";
import { updateWatch } from "../../tracking/geolocateSlice";

import AvoidStairsButton from "../avoidStairsButton";
import { CATEGORY_RESULTS_LAYER_NAME, SEARCH_RESULTS_LAYER_NAME } from "../../../store/search/searchSlice";
import { dropPinLayerName } from "../../map/dropPin";

interface WayFindingProps {
  setLightView(value: boolean): void;
  setPanelOpen(value: boolean): void;
  resultsRef: any;
}

export default function WayFinding(props: WayFindingProps): ReactElement {
  const dispatch = useDispatch();
  const { setLightView, setPanelOpen, resultsRef } = props;
  const inputOrigin = useRef<HTMLInputElement>(null);
  const inputDestination = useRef<HTMLInputElement>(null);
  const wayFinding = useSelector((state: RootState) => state.wayFinding);
  const isWayFinding = wayFinding.isWayFinding;
  const stateValueOrigin = wayFinding.origin;
  const stateValueDestination = wayFinding.destination;
  const wayFindingOrigin = wayFinding.wayFindingOrigin;
  const wayFindingDestination = wayFinding.wayFindingDestination;

  useEffect(() => {
    if (wayFinding.isSelectingOrigin || wayFinding.isSelectingDestination) {
      dispatch(chooseFromTheMap());
    }
    if (isWayFinding && stateValueOrigin) {
      dispatch(updateWayFindingOrigin((stateValueOrigin as ItemDetail).title));
      if (!inputDestination.current?.value) {
        (inputDestination as React.RefObject<HTMLInputElement> | null)?.current?.focus();
        setLightView(false);
        setPanelOpen(true);
      }
      if (inputDestination.current?.value && stateValueDestination) {
        dispatch(endSelection());
      }
    }
    if (isWayFinding && stateValueDestination) {
      dispatch(updateWayFindingDestination((stateValueDestination as ItemDetail).title));
    }
    if (isWayFinding && stateValueDestination && stateValueOrigin) {
      dispatch(removeLayer(CATEGORY_RESULTS_LAYER_NAME));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    isWayFinding,
    wayFinding.isSelectingOrigin,
    wayFinding.isSelectingDestination,
    stateValueDestination,
    stateValueOrigin
  ]);



  useEffect(() => {
    if (!isWayFinding || !resultsRef.current) {
      return;
    }

    function onKeyDown(event: KeyboardEvent): void {
      if (
        event.key && (event.key === "ArrowDown" || event.key === "ArrowUp")
      ) {
        const focusableItems = (
          Array.from(resultsRef.current.querySelectorAll("li")) as HTMLElement[]
        );
        if (!focusableItems.length) {
          return;
        }

        const currentActiveElement = document.activeElement;
        const currentActiveElementIndex = focusableItems.findIndex(
          (activeElement) => activeElement === currentActiveElement
        );

        if (event.key === "ArrowUp") {
          if (currentActiveElementIndex === -1 || currentActiveElementIndex === 0) {
            focusableItems[focusableItems.length - 1].focus();
          } else {
            focusableItems[currentActiveElementIndex - 1].focus();
          }
        }
        if (event.key === "ArrowDown") {
          if (currentActiveElementIndex === -1 || currentActiveElementIndex === focusableItems.length - 1) {
            focusableItems[0].focus();
          } else {
            focusableItems[currentActiveElementIndex + 1].focus();
          }
        }
      }
    }
    document.addEventListener("keydown", onKeyDown);
    return (): void => {
      document.removeEventListener("keydown", onKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultsRef?.current, isWayFinding, wayFinding.isSelectingOrigin, wayFinding.isSelectingDestination]);


  useEffect(() => {
    if (wayFinding.pointSelection.searchTerm) {
      if (stateValueOrigin) {
        dispatch(updateWayFindingOrigin(getLocationLabel(stateValueOrigin)));
      }
      if (stateValueDestination) updateWayFindingDestination(getLocationLabel(stateValueDestination));
    }
  }, [dispatch, wayFinding.pointSelection.searchTerm, stateValueDestination, stateValueOrigin, inputOrigin]);

  function onChangeValue(field: "origin" | "destination"): void {
    dispatch(updateWatch(false));
    dispatch(setIsNavigatingState(false));
    dispatch(removeLayer("route"));
    dispatch(selectedLocation(undefined, field));
    dispatch(endPointSelection());
    dispatch(removeLayer(dropPinLayerName));
    dispatch(removeLayer(CATEGORY_RESULTS_LAYER_NAME));
    dispatch(removeLayer(SEARCH_RESULTS_LAYER_NAME));
  }

  return (
    <>
      <div className={styles.searchWay}>
        <div>
          <WayFindingField
            kind={"origin"}
            autoFocus
            ref={inputOrigin}
            value={wayFindingOrigin}
            setValue={(value): void => {
              if (wayFindingOrigin !== value) {
                stateValueOrigin && onChangeValue("origin");
                dispatch(updateWayFindingOrigin(value));
              }
            }}
          />
          <WayFindingField
            kind={"destination"}
            ref={inputDestination}
            value={wayFindingDestination}
            setValue={(value): void => {
              if (wayFindingDestination !== value) {
                stateValueDestination && onChangeValue("destination");
                dispatch(updateWayFindingDestination(value));
              }
            }}
          />
        </div>
        <SwapIconButton className={styles.swapIcon} icon={<SwapIcon />} />
      </div>
      <AvoidStairsButton />
    </>
  );
}
