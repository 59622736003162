import { IProvideLiveData } from "./IProvideLiveData";
import Api from "./ApiService";
import { ILiveDataSet } from "./ILiveDataSet";
import { BuildingPoiI, ItemStatusDetailI, ItemStatusI } from "../util/types";
import { getCalcLiveData } from "../features/live/liveDataUtil";
import { LiveDataCategoryType } from "./LiveDataCategoryType";

export class LiveBuildings implements IProvideLiveData {

  public static categoryName = "building";

  public static categoryId = LiveDataCategoryType.BUILDINGS;

  public async getLiveData(campusId: string): Promise<ILiveDataSet> {
    const buildingData = await Api.getBuildingData(campusId);

    const buildingStatusData = buildingData.map((buildingPoi: BuildingPoiI): ItemStatusI => {
      let status: ItemStatusDetailI | undefined;

      if (buildingPoi.status) {
        const total = buildingPoi.status.availability;
        const used = parseInt(buildingPoi.status.inside || "0");
        const free = total - used;

        if (total >= 0 && used >= 0) {
          status = {
            total,
            free,
            used
          };
        }
      }
      return {
        poiId: buildingPoi.poiId,
        name: buildingPoi.title,
        status
      };
    });

    const liveData = getCalcLiveData(buildingStatusData, LiveBuildings.categoryName);

    return { data: liveData.data, totals: liveData.totals, categoryId: LiveBuildings.categoryId };
  }
}
