import { ItemDetail } from "../app/models/ItemDetail";
import { GeoJsonUtil } from "./GeoJsonUtil";
import { LatLng } from "../app/models/LatLng";
import { IndoorPOIProperties, PointOfInterest, POIProperties } from "../types/UQMapsTypes";

export class PoiUtil {

  public static toItemDetail(poi: PointOfInterest): ItemDetail {

    // attempt to parse JSON-encoded version if present, in case
    // passing through MapBox data sources has mangled the data.
    if (poi.properties.propertiesJson) {
      try {
        poi.properties = JSON.parse(poi.properties.propertiesJson);
      } catch (e) {
        console.error("unable to parse propertiesJson from", poi, e);
        // then fallback to original poi.properties
      }
    }

    const partialProps = poi.properties as Partial<POIProperties>;
    const partialIndoorProps = poi.properties as Partial<IndoorPOIProperties>;

    return {
      campusId: partialProps.campusId ?? "",
      itemId: partialProps.id ?? "",
      title: partialProps.title ?? "Unnamed Feature",
      description: partialProps.description ?? "",
      zLevel: partialIndoorProps.floorZLevel ?? 0,
      floorName: partialIndoorProps.floorLabel ?? "",
      geometry: poi.geometry,
      buildingName: partialIndoorProps.buildingName,
      properties: poi.properties
    };
  }

  public static createPOI(location: LatLng, title: string, description: string, zLevel?: number): PointOfInterest {
    return {
      type: "Feature",
      properties: {
        title: title,
        floorZLevel: zLevel ?? 0,
        floorLabel: `${zLevel ?? 0}`,
        description: description
      } as POIProperties,
      geometry: GeoJsonUtil.toPoint([location.lng, location.lat])
    };
  }

  public static createSelectedPOI(location: LatLng, zLevel?: number): PointOfInterest {
    return this.createPOI(location, "Selected Point", "Map point selected by click", zLevel);
  }

}
