import React, { ReactElement, useState, useEffect, useCallback } from "react";
import styles from "./mobileRoutePanel.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  moveToRouteStep,
  toggleShowCurrentRouteDetails,
  setIsNavigatingState
} from "../../../store/wayFinding/wayFindingSlice";
import { RootState } from "../../../app/store";
import Modal from "../../../components/Modal";
import RouteSummaryWayFinding from "../routeSummary";
import { moveToCurrentLocation, updateFollowing } from "../../tracking/geolocateSlice";

import ModalFooter from "../../../components/Modal/ModalFooter";
import classnames from "classnames";
import RouteDetailsWayFinding from "../routeDetails";
import { removeLayer } from "../../../store/layers/layerSlice";
import { ROUTE_TITLE } from "../../../constants";

interface MobileRoutePanelProps {
  setLightView(value: boolean): void;
  setPanelOpen(value: boolean): void;
  panelOpen: boolean;
}

export default function MobileRoutePanel(props: MobileRoutePanelProps): ReactElement {
  const dispatch = useDispatch();
  const { setLightView: setLightViewProps, setPanelOpen: setPanelOpenProps, panelOpen } = props;
  const wayFindingState = useSelector((state: RootState) => state.wayFinding);
  const isNavigating = wayFindingState.isNavigating;
  const showCurrentRouteDetails = wayFindingState.showCurrentRouteDetails;
  const currentRoute = wayFindingState.currentRoute;
  const watching = useSelector((state: RootState) => state.geolocator.watch);
  const routeExist = Boolean(currentRoute);
  const [show, setShow] = useState(routeExist);

  function handleStartClick(): void {
    if (!isNavigating) {
      if (watching) {
        dispatch(moveToCurrentLocation());
        dispatch(updateFollowing(true));
      } else {
        dispatch(moveToRouteStep(0));
      }
    }
    dispatch(toggleShowCurrentRouteDetails(false));
    dispatch(setIsNavigatingState(!isNavigating));
  }

  const backToWayFindingEdit = useCallback(() => {
    setShow(false);
    dispatch(removeLayer("route"));
    setLightViewProps(false);
    setPanelOpenProps(true);
  }, [dispatch, setLightViewProps, setPanelOpenProps]);

  const handleModalRouteClose = useCallback(() => {
    if (!showCurrentRouteDetails) {
      backToWayFindingEdit();
    } else {
      dispatch(toggleShowCurrentRouteDetails(false));
    }
  }, [dispatch, showCurrentRouteDetails, backToWayFindingEdit]);

  useEffect(() => {
    panelOpen ? setShow(false) : setShow(true);
  }, [panelOpen]);

  const onClick = useCallback(() => {
    dispatch(toggleShowCurrentRouteDetails(true));
  }, [dispatch]);

  function footer(className?: string): ReactElement {
    return (
      <ModalFooter className={classnames(styles.buttons, className)}>
        <button
          className={classnames("uq-button uq-button--small uq-button--purple")}
          onClick={(): void => (!isNavigating ? handleStartClick() : backToWayFindingEdit())}
        >
          {!isNavigating ? "Start" : "End"}
        </button>
        {!showCurrentRouteDetails && (
          <button
            className={classnames("uq-button uq-button--small uq-button--text")}
            onClick={onClick}
          >
            {"Details"}
          </button>
        )}
      </ModalFooter>
    );
  }

  return (
    <Modal
      show={show}
      onClose={handleModalRouteClose}
      portalContainer={document.getElementById("root")}
      showOverlay={false}
      className={classnames(styles.pointSelectionModal, showCurrentRouteDetails ? styles.routeDetails : null)}
      classNameCloseButton={classnames(
        styles.modalCloseRoute,
        showCurrentRouteDetails ? styles.modalCloseRouteLight : null
      )}
    >
      <em
        className={styles.expandButton}
        onClick={(): void => {
          dispatch(toggleShowCurrentRouteDetails(!showCurrentRouteDetails));
        }}
      />
      <div className={styles.pointSelectionModalBody}>
        {showCurrentRouteDetails && (
          <>
            <p className={classnames(styles.body1, styles.fs16, styles.headingRoute)}>{ROUTE_TITLE}</p>
            <div className={styles.routeSummary}>
              <RouteSummaryWayFinding />
              {footer(styles.routeSummary)}
              <RouteDetailsWayFinding />
            </div>
          </>
        )}
        {!showCurrentRouteDetails && <RouteSummaryWayFinding showShareIcon={false} />}
      </div>
      {!showCurrentRouteDetails && footer()}
    </Modal>
  );
}
