import React, { ReactElement, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import ModalOverlay, { Palettes, PalettesType } from "./ModalOverlay";
import ModalContent from "./ModalContent";
import "./Modal.scss";
import ModalCloseButton from "./ModalCloseButton";

interface ModalProps {
  show: boolean;
  children: React.ReactNode;
  overlayPalette?: PalettesType;
  ignoreOnEsc?: boolean;
  ignoreOnClickOverlay?: boolean;
  onClose?(): void;
  portalContainer?: HTMLElement | null;
  className?: string;
  showOverlay?: boolean;
  showCloseButton?: boolean;
  classNameCloseButton?: string;
}

export default function Modal(props: ModalProps): ReactElement {
  // Props
  const {
    show,
    children,
    className: classNameProp,
    overlayPalette = Palettes.light,
    ignoreOnEsc = false,
    ignoreOnClickOverlay = false,
    onClose = null,
    portalContainer: portalContainerProp,
    showOverlay = true,
    showCloseButton = true,
    classNameCloseButton
  } = props;

  // Moved our from default values, since getElementById possibly returns null.
  // Check if portal container is not null
  const portalContainer = portalContainerProp ?? document.body;

  useEffect(() => {
    function onKeyDown(event: KeyboardEvent): void {
      onClose && !ignoreOnEsc && event.key === "Escape" && onClose();
    }
    document.addEventListener("keydown", onKeyDown);
    return (): void => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [ignoreOnEsc, onClose]);

  // Events
  const onOverlayClick = (): void => {
    if (onClose && !ignoreOnClickOverlay) onClose();
  };

  const modalContentRef = useRef<HTMLDivElement>(null);

  // Focus Trap
  useEffect(() => {
    function onKeyDown(event: KeyboardEvent): void {
      if (
        event.key !== "Tab"
                || (event.shiftKey && !modalContentRef.current)
                || modalContentRef.current === null
      ) {
        return;
      }
      event.preventDefault();

      const focusableElSelector = [
        "input:not([disabled])",
        "select:not([disabled])",
        "textarea:not([disabled])",
        "embed",
        "iframe",
        "object",
        "a[href]",
        "area[href]",
        "button:not([disabled])",
        "audio[controls]",
        "video[controls]",
        "*[tabindex]:not([aria-disabled])",
        "*[contenteditable]",
        "[tabindex]"
      ].join();
      const focusableItems = (
        Array.from(modalContentRef.current.querySelectorAll(focusableElSelector)) as HTMLElement[]
      ).filter(element => element.tabIndex > -1);
      const currentActiveElement = document.activeElement;
      const currentActiveElementIndex = focusableItems.findIndex(
        activeElement => activeElement === currentActiveElement
      );
      if (event.shiftKey) {
        if (currentActiveElementIndex === -1 || currentActiveElementIndex === 0) {
          focusableItems[focusableItems.length - 1].focus();
        } else {
          focusableItems[currentActiveElementIndex - 1].focus();
        }
      } else if (currentActiveElementIndex === -1 || currentActiveElementIndex === focusableItems.length - 1) {
        focusableItems[0].focus();
      } else {
        focusableItems[currentActiveElementIndex + 1].focus();
      }
    }

    document.addEventListener("keydown", onKeyDown);
    return (): void => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [show, modalContentRef]);

  return ReactDOM.createPortal(
    show && (
      <div aria-modal ref={modalContentRef}>
        {showOverlay && <ModalOverlay palette={overlayPalette} onClick={onOverlayClick} />}
        <ModalContent className={classNameProp} palette={overlayPalette}>
          {onClose && showCloseButton && <ModalCloseButton className={classNameCloseButton} closeModal={onClose} />}
          {children}
        </ModalContent>
      </div>
    ),
    portalContainer
  );
}
