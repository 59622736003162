/*
Adapted from - https://github.com/alex3165/react-mapbox-gl
*/

import * as React from "react";
import MapBoxGl, { GeoJSONSource, GeoJSONSourceRaw, Layer, Map } from "mapbox-gl";
import { ReactElement, ReactNode, useCallback, useEffect, useState } from "react";

export interface Props {
  id: string;
  geoJsonSource: GeoJSONSourceRaw;
  map: Map;
  onSourceAdded?(source: GeoJSONSource): void;
  onSourceLoaded?(source: GeoJSONSource): void;
  render?(source: GeoJSONSource): ReactNode;
}

export interface LayerWithBefore extends Layer {
  before?: string;
}

export function MapBoxSource(props: Props): ReactElement {
  const { id, geoJsonSource, map, onSourceAdded, onSourceLoaded, render } = props;

  const [source, setSource] = useState<GeoJSONSource | null>(null);

  useEffect(() => {
    setSource(null);
  }, [id]);

  const onData = useCallback(() => {
    const src = map.getSource(id) as GeoJSONSource;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!src || !map.isSourceLoaded(id)) {
      setSource(null);
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (src) {
      onSourceLoaded?.(src);
      setSource(src);
    }
    // Will fix datasource being empty
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (src && geoJsonSource?.data) {
      src.setData(geoJsonSource.data);
    }
    map.off("sourcedata", onData);
  }, [map, id, geoJsonSource, onSourceLoaded]);

  useEffect(() => {
    return (): void => removeMapSource(map, id);
  }, [map, id]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!map.getSource(id)) {
      map.on("sourcedata", onData);
      map.addSource(id, geoJsonSource);
      onSourceAdded?.(map.getSource(id) as GeoJSONSource);
      return (): void => {
        map.off("sourcedata", onData);
      };
    }
    if (geoJsonSource.data)
      (map.getSource(id) as GeoJSONSource).setData(geoJsonSource.data);

  }, [map, id, geoJsonSource, onData, onSourceAdded]);

  return <>{source && render?.(source)}</>;
}
MapBoxSource.displayName = "MapBoxSource";


function removeMapSource(map: MapBoxGl.Map, id: string): void {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!map.getSource(id)) {
    return;
  }

  const layers: Layer[] = map.getStyle().layers ?? [];
  const adjustedLayers = layers
    .filter(layer => layer.source === id);

  for (const layer of adjustedLayers) {
    map.removeLayer(layer.id);
  }
  map.removeSource(id);
}

export default MapBoxSource;
