import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LiveDataState } from "../../app/models/LiveDataState";
import { IProvideLiveData } from "../../services/IProvideLiveData";
import { AppThunk } from "../../app/store";
import { ILiveDataSet } from "../../services/ILiveDataSet";
import { LiveData } from "../../app/models/LiveData";

const initialState: LiveDataState = {
  liveData: {},
  liveCategoryData: {}
};

export const liveDataSlice = createSlice({
  name: "liveData",
  initialState,
  reducers: {
    pushLiveData: (state, action: PayloadAction<ILiveDataSet>) => {
      for (const key in action.payload.data) {
        state.liveData[key] = action.payload.data[key];
      }
      state.liveCategoryData[action.payload.categoryId] = action.payload.totals as LiveData;
    },
    clearLiveData: state => {
      state.liveData = {};
    }
  }
});

export const { pushLiveData } = liveDataSlice.actions;

export default liveDataSlice.reducer;

export const updateLiveData
    = (provider: IProvideLiveData): AppThunk<Promise<void>> =>
      async (dispatch, getState): Promise<void> => {
        const campusId = getState().map.campusId;
        const result = await provider.getLiveData(campusId ?? "");
        dispatch(pushLiveData(result));
      };
