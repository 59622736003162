import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { fitToBounds, setZLevel, zLevelForLevelZero } from "../../store/map/mapSlice";
import { GeoJsonUtil } from "../../services/GeoJsonUtil";
import { AnyLayer, GeoJSONSource, MapMouseEvent } from "mapbox-gl";
import { AppDispatch, RootState } from "../../app/store";
import { connect, ConnectedProps } from "react-redux";
import { MapEx } from "MazeMapTypes";
import { TYPE_ICON_PREFIX } from "../../services/typeIcons";
import LiveDataLayer from "../live/LiveDataLayer";
import { dropPinLayerName, setDropPin } from "../map/dropPin";
import { removeLayer, replaceLayer } from "../../store/layers/layerSlice";
import { ItemDetail } from "../../app/models/ItemDetail";
import { Feature } from "geojson";
import {
  CommonPOIProperties,
  INDOOR_POI_PROPERTIES,
  IndoorPOIProperties,
  MixedPOIList,
  PointOfInterest,
  SIMPLE_CATEGORY_POI_PROPERTIES
} from "../../types/UQMapsTypes";
import MazeMapDataService from "../../services/MazeMapDataService";
import colours from "../../colours";


// Note that features added to the map source are of the type
// PointOfInterest. This means that in the "paint" specifications below,
// we need to be careful that the fields exist. We will use these
// constants to make sure they exist in the features and cause compile
// errors if not.
const ID: keyof CommonPOIProperties = "id";
const ZLEVEL: keyof IndoorPOIProperties = "floorZLevel";

const COLOURS = {
  marker: colours.primary,
  markerText: colours.primary,

  selectedMarker: colours.secondary,
  otherFloorMarker: colours.primaryLight,

  clusterMarker: colours.primary,
  clusterMarkerText: colours.textPrimary
};

function getCurrentZ(mapZLevel?: number): number {
  return mapZLevel === zLevelForLevelZero ? 0 : mapZLevel ?? 0;
}

function isSelectedItemExpr(selectedId?: string, isSelected?: boolean): unknown[] {
  // note: coerces empty string in selectedId to ID "0".
  return [
    "all",
    ["==", ["coalesce", ["get", ID], "0"], selectedId ? selectedId : "0"],
    isSelected ?? true
  ];
}

function isSameFloorExpr(mapZLevel: number): unknown[] {
  return ["all", ["has", ZLEVEL], ["==", ["get", ZLEVEL], getCurrentZ(mapZLevel)]];
}

interface SelectableLayerProps extends PropsFromRedux {
  map: MapEx;
  name: string;
  items: MixedPOIList;
  cluster?: boolean;
  visible: boolean;
}

function SelectableLayer(props: SelectableLayerProps): ReactElement {
  const { dispatch, map, name, items, cluster, visible } = props;
  const { selectedItem, selectedItemOpen, mapZLevel } = props;

  const [source, setSource] = useState<GeoJSONSource | undefined>(undefined);
  const promiseRef = useRef<number>(0);

  const sourceName = `${name}src`;
  const layerName = name;
  const clusteredLayerName = `${name}_clustered`;
  const clusterCountLayerName = `${name}_clusterCount`;
  const iconLayerName = `${name}_icon`;
  const textLayerName = `${name}_text`;

  const layers = useMemo(() => [
    layerName, clusteredLayerName, clusterCountLayerName, iconLayerName, textLayerName
  ], [clusterCountLayerName, clusteredLayerName, iconLayerName, layerName, textLayerName]);

  const itemId = selectedItem?.itemId;
  const visibility = visible ? "visible" : "none";

  // invalidate promises on unmount
  useEffect(() => {
    return (): void => {
      promiseRef.current = -1;
    };
  }, []);

  // cleanup all layers on unmount
  useEffect(() => {
    return (): void => {
      for (const layer of layers) {
        if (map.getLayer(layer) as AnyLayer | undefined) {
          map.removeLayer(layer);
        }
      }
    };
  }, [layers, map]);

  // update visibility when changed
  useEffect(() => {
    for (const layer of layers) {
      if (map.getLayer(layer) as AnyLayer | null) {
        map.setLayoutProperty(layer, "visibility", visibility);
      }
    }
  }, [layers, map, visibility]);

  // add or update items to a new source
  useEffect(() => {
    const features = GeoJsonUtil.convertFeaturesToPoints(items);
    const collection = { type: "FeatureCollection", features: features } as const;

    let src = map.getSource(sourceName) as GeoJSONSource | undefined;
    if (!src) {
      // note clusterMaxZoom is capped to maxzoom-1.
      // see: https://github.com/mapbox/mapbox-gl-js/issues/8464
      map.addSource(sourceName, {
        type: "geojson",
        data: collection,
        cluster: cluster,
        maxzoom: 21,
        clusterMaxZoom: 19,
        clusterRadius: 70
      });
      src = map.getSource(sourceName) as GeoJSONSource;
      // TODO: re-fetch rich data
    } else {
      src.setData(collection);
    }

    setSource(src);
    return (): void => {
      src?.setData({ type: "FeatureCollection", features: [] });
    };
  }, [cluster, items, map, sourceName]);


  // HELPER FUNCTIONS

  const getBestMatchFeature = useCallback(
    async (features: MixedPOIList):
    Promise<PointOfInterest | undefined> => {
      const currentZ = getCurrentZ(mapZLevel);

      const pois = await Promise.all(
        features.map(async (feature): Promise<PointOfInterest> => {
          if (feature.properties.type === SIMPLE_CATEGORY_POI_PROPERTIES) {
            return MazeMapDataService.getPoi(feature.properties.id);
          }
          return feature as PointOfInterest;
        })
      );

      // Find on on the same floor
      const sameZLevel = pois.find(
        x => x.properties.type === INDOOR_POI_PROPERTIES && x.properties.floorZLevel === currentZ
      );
      if (sameZLevel) {
        return sameZLevel;
      }
      const descComparer = (a: Feature, b: Feature): number => {
        return b.properties?.zLevel ?? 0 - a.properties?.zLevel ?? 0;
      };

      // Find one on a lower floor
      const lowerZLevel = pois
        .sort(descComparer)
        .find(x => x.properties.type === INDOOR_POI_PROPERTIES && x.properties.floorZLevel < currentZ);
      if (lowerZLevel) {
        return lowerZLevel;
      }

      return pois[0]; // just return any one
    }, [mapZLevel]);


  // EVENT LISTENERS

  const onMapMoved = useCallback(async (): Promise<void> => {
    // this keeps track of the number of dispatched promises. in case
    // multiple are dispatched before the first returns, this ensures
    // only the latest one will update the map data.
    const thisIndex = ++promiseRef.current;

    const simple = map.queryRenderedFeatures(undefined, {
      layers: [layerName],
      filter: ["==", ["get", "type"], SIMPLE_CATEGORY_POI_PROPERTIES]
    });
    if (!simple.length) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const ids = Array.from(new Set(simple.map(x => x.properties!.id)));

    const rich: Partial<Record<string, Promise<PointOfInterest>>> = {};
    for (const id of ids) {
      rich[id] = MazeMapDataService.getPoi(id);
    }

    const newItems = await Promise.all(items.map(async x => {
      const promise = rich[x.properties.id];
      return promise ? await promise : x;
    }));

    if (promiseRef.current !== thisIndex) {
      // another promise has been dispatched, this is outdated.
      // ignore this promise's results.
      return;
    }

    dispatch(
      replaceLayer({
        name: layerName,
        type: "selectable",
        layerDetails: {
          items: newItems,
          cluster: cluster ?? false
        },
        includeInMapControl: false
      })
    );
  }, [map, layerName, items, dispatch, cluster]);

  const onMapClicked = useCallback(async (e: MapMouseEvent): Promise<void> => {
    e.preventDefault();
    const features = map.queryRenderedFeatures(e.point, {
      layers: [layerName]
    }) as unknown as MixedPOIList;

    if (layerName !== dropPinLayerName) {
      dispatch(removeLayer(dropPinLayerName));
    }

    const best = await getBestMatchFeature(features);
    if (!best) {
      return;
    }

    dispatch(setDropPin(best));
    if (best.properties.type === INDOOR_POI_PROPERTIES) {
      if (best.properties.floorZLevel !== getCurrentZ(mapZLevel)) {
        dispatch(setZLevel(best.properties.floorZLevel));
      }
    }
  }, [dispatch, getBestMatchFeature, layerName, map, mapZLevel]);


  const onClusterClicked = useCallback((e: MapMouseEvent): void => {
    e.preventDefault();
    const features = map.queryRenderedFeatures(e.point, {
      layers: [clusteredLayerName]
    });
    const clusterId = features[0]?.properties?.cluster_id;
    const pointCount = features[0]?.properties?.point_count;
    source?.getClusterLeaves(
      clusterId,
      pointCount,
      0,
      function (err, children) {
        if (err) {
          console.error("error retrieving cluster leaves", err);
        }
        console.log({ l: "cluster", e, children });
        const newBounds = GeoJsonUtil.getBounds(children);
        dispatch(fitToBounds({
          ne: GeoJsonUtil.toLatLng(newBounds.getNorthEast()),
          sw: GeoJsonUtil.toLatLng(newBounds.getSouthWest())
        }));
      }
    );
  }, [clusteredLayerName, dispatch, map, source]);

  useEffect(() => {
    map.on("moveend", onMapMoved);
    map._clickDispatcher.onLayerClick(layerName, onMapClicked);
    map._clickDispatcher.onLayerClick(clusteredLayerName, onClusterClicked);

    return (): void => {
      map.off("moveend", onMapMoved);
      map._clickDispatcher.offLayerClick(layerName, onMapClicked);
      map._clickDispatcher.offLayerClick(clusteredLayerName, onClusterClicked);
    };
  }, [clusteredLayerName, layerName, map, map._clickDispatcher, onClusterClicked, onMapClicked, onMapMoved]);


  // LAYERS

  // CLUSTER COUNT LAYER
  useEffect(() => {
    if (!map.getLayer(clusterCountLayerName) as boolean) {
      map.addLayer({
        id: clusterCountLayerName,
        type: "symbol",
        source: sourceName,
        filter: ["==", "cluster", true],
        layout: {
          "text-field": ["get", "point_count"],
          "text-size": 14,
          "text-font": ["Roboto Black", "Open Sans Semibold", "Arial Unicode MS Bold"],
          // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          "text-offset": [0, 0.1],
          "text-allow-overlap": true,
          visibility: visibility
        },
        paint: {
          "text-color": COLOURS.clusterMarkerText
        }
      });
    }
  }, [clusterCountLayerName, map, sourceName, visibility]);


  // CLUSTERED LAYER
  useEffect(() => {
    if (!map.getLayer(clusteredLayerName) as boolean) {
      map.addLayer(
        {
          id: clusteredLayerName,
          source: sourceName,
          type: "circle",
          paint: {
            "circle-color": COLOURS.clusterMarker,
            "circle-radius": 14,
            "circle-stroke-width": 0
          },
          filter: ["==", "cluster", true],
          layout: {
            visibility: visibility
          }
        },
        clusterCountLayerName
      );
    }
  }, [clusterCountLayerName, clusteredLayerName, map, sourceName, visibility]);


  // TEXT LAYER
  useEffect(() => {
    if (!map.getLayer(textLayerName) as boolean) {
      map.addLayer({
        id: textLayerName,
        source: sourceName,
        type: "symbol",
        layout: {
          "text-field": ["get", "title"],
          "text-size": 12,
          // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          "text-offset": [0, 2],
          visibility: visibility,
          "text-allow-overlap": false
        },
        paint: {
          "text-color": COLOURS.markerText,
          "text-halo-width": 2,
          "text-halo-color": "rgba(255,255,255,.8)"
        },
        filter: ["!=", "cluster", true]
      });
    }
  }, [map, sourceName, textLayerName, visibility]);


  // ICON LAYER
  useEffect(() => {
    const ICON_SIZE = 0.7;
    if (map.getLayer(iconLayerName) as AnyLayer | null) {
      map.removeLayer(iconLayerName);
    }
    // iconImgKey & primaryIconImgKey -> are the icon keys resolved previously in the poi
    // customisation
    map.addLayer(
      {
        id: iconLayerName,
        source: sourceName,
        type: "symbol",
        layout: {
          visibility: visibility,
          "icon-image": [
            "case",
            isSelectedItemExpr(itemId, selectedItemOpen),
            ["get", "primaryIconImgKey"],
            ["has", "iconImgKey"],
            ["get", "iconImgKey"],
            ["concat", TYPE_ICON_PREFIX, "poi"]
          ],
          "icon-size": ICON_SIZE,
          "icon-allow-overlap": true,
          "icon-anchor": "center"
        },
        paint: {
          "icon-color": [
            "case",
            isSelectedItemExpr(itemId, selectedItemOpen),
            COLOURS.marker,
            COLOURS.clusterMarkerText
          ]
        },
        filter: ["!=", "cluster", true]
      },
      textLayerName
    );
  }, [iconLayerName, itemId, map, selectedItemOpen, sourceName, textLayerName, visibility]);


  // SELECTION LAYER
  useEffect(() => {
    if (map.getLayer(layerName) as AnyLayer | null) {
      map.removeLayer(layerName);
    }

    map.addLayer(
      {
        id: layerName,
        source: sourceName,
        type: "circle",
        paint: {
          "circle-color": [
            "case",
            isSelectedItemExpr(itemId, selectedItemOpen),
            COLOURS.clusterMarkerText,
            isSameFloorExpr(mapZLevel),
            COLOURS.marker,
            COLOURS.otherFloorMarker
          ],
          "circle-radius": 14,
          "circle-stroke-width": 1,
          "circle-stroke-color": [
            "case",
            isSelectedItemExpr(itemId, selectedItemOpen),
            COLOURS.marker,
            isSameFloorExpr(mapZLevel),
            COLOURS.marker,
            COLOURS.clusterMarkerText
          ]
        },
        layout: { visibility },
        filter: ["!=", "cluster", true]
      },
      iconLayerName
    );
  }, [iconLayerName, layerName, map, mapZLevel, itemId,
    selectedItemOpen, sourceName, visibility]);

  return visible && source
    ? <LiveDataLayer name={name} map={map} items={items} before={layerName} />
    : <></>;
}


const mapStateToProps = (
  state: RootState
): {
  selectedItem?: ItemDetail;
  selectedItemOpen: boolean;
  mapZLevel: number;
} => {
  return {
    selectedItem: state.map.itemDetail,
    selectedItemOpen: state.map.openItemDetail,
    mapZLevel: state.map.zLevel
  };
};

const mapDispatchToProps = (
  dispatch: AppDispatch
): {
  dispatch: AppDispatch;
} => {
  return { dispatch };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(SelectableLayer);

type PropsFromRedux = ConnectedProps<typeof connector>;
