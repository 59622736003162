import React, { ReactElement } from "react";
import { LiveData } from "../../app/models/LiveData";
import { ReactComponent as OvalIcon } from "../../assets/oval.svg";
import styles from "./LiveDataIndicator.module.scss";
import { calculateUsedPercentage, getLiveDataColourForUsed } from "./liveDataUtil";

type LiveDataIndicatorProps = LiveData;

export default function LiveDataIndicator(props: LiveDataIndicatorProps): ReactElement {
  const { phrase } = props;

  const dataPercent = calculateUsedPercentage(props);
  const colour: string = getLiveDataColourForUsed(dataPercent);

  // minimum percentage
  // const minPercentage = 0.05;
  // const arcSize = dataPercent >= 0 && dataPercent < minPercentage ? minPercentage : dataPercent;

  // const dataPercent = calculateUsedPercentage(this.props);
  // let colour: string = getLiveDataColourForFree(dataPercent);

  return (
    <>
      <span className={styles.liveData}>
        <OvalIcon style={{ color: colour }} />
      </span>
      <span className={styles.liveDataCircle}>
        <OvalIcon style={{ color: colour }} />
      </span>
      {phrase}
    </>
  );
}
