import React, { ReactElement } from "react";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import styles from "./mapControls.module.scss";
import { setRotationAndPitch, showModal } from "../../store/map/mapSlice";
import { RootState } from "../../app/store";
import CampusDropdown from "../campusDropdown";
import IconButton from "../../components/IconButton";
import { ReactComponent as NorthIcon } from "../../assets/north_icon.svg";
import { ReactComponent as LayersIcon } from "../../assets/layers.svg";
import { ReactComponent as GpsIconLocked } from "../../assets/icons8_target_locked.svg";
import { ReactComponent as GpsIcon } from "../../assets/icons8_target_1.svg";
import { AnalyticsHandler } from "../../services/analytics/google-analytics/AnalyticsHandler";
import { AnalyticsOptionsImpl } from "../../services/analytics/google-analytics/AnalyticsOptions";
import { moveToCurrentLocation, updateFollowing, updateWatch } from "../tracking/geolocateSlice";
import FloorDropdown from "../floorDropdown/floorDropdown";
import { MobileOnlyView } from "react-device-detect";
import { TITLE_LAYERS, TITLE_NAVIGATION, TITLE_ROTATION } from "../../constants";
import { ModalType } from "../../app/models/MapState";

export default function MapControls(): ReactElement {
  const dispatch = useDispatch();
  const rotation = useSelector((state: RootState) => state.map.rotation);
  const watchPending = useSelector((state: RootState) => state.geolocator.pending);
  const watching = useSelector((state: RootState) => state.geolocator.watch);
  const isWayFinding = useSelector((state: RootState) => state.wayFinding.isWayFinding);
  const dueNorthAngle = -(rotation ?? 0);

  const handleToggleModalLayerSelection = (): void => {
    AnalyticsHandler.handleEvent(
      new AnalyticsOptionsImpl("Custom click", "LayerSelection", "Click", "LayerSelection")
    );
    dispatch(showModal(ModalType.LAYERS));
  };

  const resetNorth = (): void => {
    dispatch(setRotationAndPitch({ pitch: 0, rotation: 0 }));
    dispatch(updateFollowing(false));
  };

  const centreMapOnUser = (): void => {
    if (watchPending) {
      return;
    }
    if (!watching) {
      //Set the location the first time
      dispatch(updateWatch(true));
    } else {
      //Centre on the user when clicked
      dispatch(moveToCurrentLocation());
    }

    // Always Start following users around map
    dispatch(updateFollowing(true));
  };

  const isWayFindingOn = isWayFinding ? styles.controlsIconsWayFinding : "";
  return (
    <div className={styles.mapControls}>
      <div className={classnames(styles.mapControlsDropdowns, isWayFindingOn)}>
        <CampusDropdown />
        <FloorDropdown />
      </div>
      <div className={classnames(styles.mapControlsIcons, isWayFindingOn)}>
        <div className={styles.rotateControlWrap}>
          <IconButton
            icon={<NorthIcon />}
            ariaLabel={TITLE_ROTATION}
            styles={{ transform: `rotate(${dueNorthAngle}deg)` }}
            className={classnames(styles.rotateControl, styles.layerControlNoBackground)}
            onClick={resetNorth}
          />
        </div>
        <div className={styles.layerControlWrap}>
          <IconButton
            icon={<LayersIcon />}
            ariaLabel={TITLE_LAYERS}
            className={classnames(styles.layerControl, styles.locationIcon)}
            onClick={handleToggleModalLayerSelection}
          />
        </div>
        <MobileOnlyView>
          <div className={styles.gpsControlWrap}>
            <IconButton
              ariaLabel={TITLE_NAVIGATION}
              onClick={centreMapOnUser}
              icon={watching ? <GpsIconLocked className={watchPending ? "gpsPending" : ""} /> : <GpsIcon />}
              className={classnames(styles.gpsControl, watchPending ? "gpsPending" : "")}
            />
          </div>
        </MobileOnlyView>
      </div>

    </div>
  );
}
