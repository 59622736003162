import React, { ReactElement } from "react";
import styles from "./itemDescriptionDesktop.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import LiveDataIndicator from "../../live/LiveDataIndicator";
import ItemDescriptionTitle from "../itemDescriptionTitle";
import ItemDescriptionButtons from "../itemDescriptionButtons";
import RiverArtWork from "../../../assets/brisbane-river-artwork.jpg";
import { UQ_ART } from "../../../constants";

interface ItemDescriptionDesktopProps {
  setPanelOpen(value: boolean): void;
}

export default function ItemDescriptionDesktop(props: ItemDescriptionDesktopProps): ReactElement {
  const { setPanelOpen: setPanelOpenProps } = props;
  const itemDetail = useSelector((state: RootState) => state.map.itemDetail);
  const liveData = useSelector((state: RootState) => state.live.liveData);
  const live = itemDetail && liveData[itemDetail.itemId];

  return (
    <div className={styles.itemDescriptionWrap}>
      <div className={styles.itemDescriptionImage}>
        <img src={RiverArtWork} alt={UQ_ART} />
      </div>
      <ItemDescriptionTitle itemDetail={itemDetail} />
      <ItemDescriptionButtons
        itemDetail={itemDetail}
        setPanelOpen={(value: boolean): void => setPanelOpenProps(value)}
      />
      {itemDetail?.description && (
        <>
          <p className={styles.overviewOutlined}>{"Details"}</p>
          <div className={styles.overviewText}>
            {itemDetail.description.split("\n").map(paragraph => (
              <p key={paragraph} className={styles.descriptionItemText}>
                {paragraph}
              </p>
            ))}
          </div>
        </>
      )}
      {live && (
        <p>
          <LiveDataIndicator {...live} />
        </p>
      )}
    </div>
  );
}
