import React, { ReactElement } from "react";
import styles from "./pointSelection.module.scss";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../components/Modal";
import { endPointSelection, selectedLocation } from "../../../store/wayFinding/wayFindingSlice";
import { RootState } from "../../../app/store";
import classnames from "classnames";
import IconButton from "../../../components/IconButton";
import { ItemDetail } from "../../../app/models/ItemDetail";
import { DESTINATION, DESTINATION_SHORT, ORIGIN, ORIGIN_SHORT } from "../../../constants";
import { getLocationLabel } from "../../../MapsApp";
import { removeLayer } from "../../../store/layers/layerSlice";
import { dropPinLayerName } from "../../map/dropPin";

export default function PointSelection(): ReactElement {
  const dispatch = useDispatch();
  const selectedPoint = useSelector((state: RootState) => state.wayFinding.selectedPoint);
  const isSelectingPoint = useSelector((state: RootState) => state.wayFinding.isSelectingPoint);

  function handleSelection(selection: "origin" | "destination"): void {
    dispatch(selectedLocation(selectedPoint, selection));
    dispatch(endPointSelection());
  }

  const poiSelectionButton = (
    nameButton: string,
    currentField: "origin" | "destination",
    classIcon?: string | undefined
  ): ReactElement => {
    return (
      <IconButton
        icon={
          <>
            <em className={classnames(styles.searchWayImpulse, classIcon)} />
            {nameButton}
          </>
        }
        onClick={(): void =>
          handleSelection(currentField)
        }
        ariaLabel={nameButton}
        className={styles.pointSelectionButton}
      />
    );
  };

  return selectedPoint ? (
    <Modal
      show={isSelectingPoint}
      onClose={(): void => {
        dispatch(endPointSelection());
        dispatch(removeLayer(dropPinLayerName)); //remove any selected pin on the map
      }}
      portalContainer={document.getElementById("root")}
      showOverlay={false}
      className={styles.pointSelectionModal}
      classNameCloseButton={styles.classNameCloseButton}
    >
      <div className={styles.pointSelectionModalBody}>
        <p className={styles.body1}>{getLocationLabel(selectedPoint)}</p>
        <p className={styles.body2}>{(selectedPoint as ItemDetail).description}</p>
        <p className={styles.pointSelectionButtons}>
          {poiSelectionButton(ORIGIN, ORIGIN_SHORT)}
          {poiSelectionButton(DESTINATION, DESTINATION_SHORT, styles.searchWayImpulseBottom)}
        </p>
      </div>
    </Modal>
  ) : <></>;
}
