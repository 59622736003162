import { LatLng } from "./LatLng";
import { ItemDetail } from "./ItemDetail";
import { FeedbackData } from "./FeedbackData";
import { Campus, Category } from "../../types/UQMapsTypes";

export interface MapState {
  campuses: Campus[];
  categories: Category[];
  hasProcessedUrl: boolean;
  campusId?: string;
  centre: LatLng;
  zoom: number;
  pitch: number;
  itemDetail?: ItemDetail;
  openItemDetail: boolean;
  zLevel: number;
  rotation?: number;
  bounds?: { ne: LatLng; sw: LatLng };
  urlCopyOpen: boolean;
  style: string;
  feedbackOptions: { value: string; name: string }[];
  feedbackData: FeedbackData;
  notificationMessage: string;
  modal: ModalType;
  isCampusesLoading: boolean;
}

export enum ModalType {
  NONE = "",
  ABOUT = "ABOUT",
  FEEDBACK = "FEEDBACK",
  LAYERS = "LAYERS"
}

