import React, { ReactElement, KeyboardEvent, useCallback } from "react";
import "./IconButton.scss";
import classnames from "classnames";

interface IconButtonProps {
  className?: string;
  icon?: React.ReactElement;
  onClick?(event?: React.FormEvent<Element> | undefined): void;
  onFocus?(event?: React.FormEvent<Element> | undefined): void;
  onMouseOver?(event?: React.FormEvent<Element> | undefined): void;
  children?: React.ReactElement;
  ariaLabel?: string;
  tabIndex?: number;
  styles?: React.CSSProperties;
}

export default function IconButton(props: IconButtonProps): ReactElement {
  // Props
  const {
    icon,
    children,
    className: classNameProp,
    onClick: onClickProp,
    onFocus: onFocusProp,
    onMouseOver: onMouseOverProp,
    ariaLabel: ariaLabelProp,
    tabIndex: tabIndexProp,
    styles: stylesProp
  } = props;

  // Events

  const onClick = useCallback(() => {
    onClickProp?.();
  }, [onClickProp]);

  const onFocus = useCallback(() => {
    onFocusProp?.();
  }, [onFocusProp]);

  const onMouseOver = useCallback(() => {
    onMouseOverProp?.();
  }, [onMouseOverProp]);


  const onKeyPress = useCallback((event: KeyboardEvent<HTMLButtonElement>) => {
    onClickProp?.();
    event.preventDefault();
  }, [onClickProp]);

  const element = icon ?? children;
  const _children = React.isValidElement(element)
    ? React.cloneElement(element, {
      "aria-hidden": false,
      focusable: true
    })
    : null;

  return (
    <button
      tabIndex={tabIndexProp}
      onClick={onClick}
      onFocus={onFocus}
      onMouseOver={onMouseOver}
      onKeyPress={(event): void => onKeyPress(event)}
      aria-label={ariaLabelProp}
      className={classnames("uq-button--link", classNameProp)}
      style={stylesProp}
    >
      {_children}
    </button>
  );
}
