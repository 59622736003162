import React, { ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./FeedbackFormModal.module.scss";
import ModalHeader from "../../components/Modal/ModalHeader";
import { sendFeedback, setFeedbackVal, openNotification, closeModal } from "../../store/map/mapSlice";
import Modal from "../../components/Modal";
import {
  TITLE_FEEDBACK,
  FEEDBACK_RATE_APP,
  FEEDBACK_OPTIONAL_DETAILS,
  FEEDBACK_SELECT_DEFAULT,
  FEEDBACK_RECEIVED,
  TITLE_SUBMIT,
  PLACEHOLDER_DETAILS
} from "../../constants";
import { RootState } from "../../app/store";
import { ModalType } from "../../app/models/MapState";
import RatingFeedback from "../ratingFeedback";

export default function FeedbackFormModal(): ReactElement {
  const dispatch = useDispatch();

  const handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault();
    dispatch(dispatch(closeModal()));
    dispatch(openNotification(FEEDBACK_RECEIVED));
    dispatch(sendFeedback());
  };

  function onChangeSelect(value: string): void {
    if (value) dispatch(setFeedbackVal({ feedbackOption: value }));
  }
  function onChangeTextarea(value: string): void {
    if (value) dispatch(setFeedbackVal({ feedbackContent: value }));
  }

  // Global State
  const feedbackOptions = useSelector((state: RootState) => state.map.feedbackOptions);
  const show = useSelector((state: RootState) => state.map.modal === ModalType.FEEDBACK);

  return (
    <Modal
      show={show}
      onClose={(): unknown => dispatch(closeModal())}
      portalContainer={document.getElementById("root")}
      overlayPalette={"light"}
      className={styles.mapModal}
    >
      <ModalHeader borderBottom>{TITLE_FEEDBACK}</ModalHeader>
      <div className={styles.mapModalBody}>
        <div className={styles.mapModalBodySection}>
          <p className={styles.label}>{FEEDBACK_RATE_APP}</p>
          <RatingFeedback />
        </div>
        <form onSubmit={handleSubmit} className={styles.mapFeedbackSection}>
          <label htmlFor={"feedbackSelect"}>{FEEDBACK_OPTIONAL_DETAILS}</label>
          <select
            className={styles.feedbackSelect}
            name={"otherFeedbackOptions"}
            autoComplete={"off"}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>): void => onChangeSelect(event.target.value)}
          >
            <option className={styles.feedbackOption} value={"DEFAULT"}>
              {FEEDBACK_SELECT_DEFAULT}
            </option>
            {feedbackOptions.map(feedbackOption => (
              <option value={feedbackOption.value} key={feedbackOption.value}>
                {feedbackOption.name}
              </option>
            ))}
          </select>
          <textarea
            id={"feedbackSelect"}
            autoComplete={"off"}
            rows={4}
            placeholder={PLACEHOLDER_DETAILS}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>): void => onChangeTextarea(event.target.value)}
          />
          <button className={"uq-button uq-button--purple"} type={"submit"}>
            {TITLE_SUBMIT}
          </button>
        </form>
      </div>
    </Modal>
  );
}