import React, { KeyboardEvent, ReactElement, useCallback } from "react";
import styles from "./avoidStairsButton.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { AVOID_STAIRS } from "../../../constants";
import { setAvoidStairs } from "../../../store/wayFinding/wayFindingSlice";
import classnames from "classnames";
import { RootState } from "../../../app/store";
interface AvoidStairsButtonProps {
  lightView?: boolean;
}

export default function AvoidStairsButton(props: AvoidStairsButtonProps): ReactElement {
  const dispatch = useDispatch();
  const avoidStairs = useSelector((state: RootState) => state.wayFinding.routeOptions.avoidStairs);
  const { lightView } = props;

  const onKeyPress = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    dispatch(setAvoidStairs(!avoidStairs));
    event.preventDefault();
  }, [dispatch, avoidStairs]);

  const onClick = useCallback(() => {
    dispatch(setAvoidStairs(!avoidStairs));
  }, [dispatch, avoidStairs]);

  return (
    <div className={classnames(styles.searchWayFindingButtons, lightView ? styles.lightView : "")}>
      <label>
        <input
          type={"checkbox"}
          checked={avoidStairs}
          onChange={onClick}
          onKeyPress={(event): void => onKeyPress(event)}
          className={classnames("uq-checkbox--map", avoidStairs ? "active" : null)}
        />
        <span>{AVOID_STAIRS}</span>
      </label>
    </div>
  );
}
