import storage from "redux-persist/lib/storage";
import { TransformMapState } from "./TransformMapState";
import { PersistConfig } from "redux-persist/es/types";
import { MapState } from "./models/MapState";

export const mapPersistconfig: PersistConfig<MapState, MapState, MapState, MapState> = {
  key: "map",
  storage: storage,
  whitelist: ["campusId"],
  transforms: [TransformMapState]
};
