import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import { MapParameters } from "../../app/models/MapParameters";
import { MapState, ModalType } from "../../app/models/MapState";
import { LatLng } from "../../app/models/LatLng";
import { PoiUtil } from "../../services/PoiUtil";
import { ItemDetail } from "../../app/models/ItemDetail";
import Api from "../../services/ApiService";
import { MapDataService } from "../../services/MapDataService";
import { FeedbackData } from "../../app/models/FeedbackData";
import { Campus, Category, PointOfInterest } from "../../types/UQMapsTypes";
import { GeoJsonUtil } from "../../services/GeoJsonUtil";
import MazeMapDataService from "../../services/MazeMapDataService";

export const DEFAULT_MAP_STYLE = "mazemap://v2020";
export const SAFEPATH_MAP_STYLE = "N/A";
export const DEFAULT_CAMPUS_ID = "406"; // St. Lucia

/**
 * Mazemap uses -1 for level 0 (i.e. ground floor or outdoors)
 */
export const zLevelForLevelZero = -1;

export const defaultZoomLevel = 15;

const defaultItemDetails: ItemDetail = {
  campusId: "",
  itemId: "",
  title: "",
  description: "",
  zLevel: 0,
  floorName: "",
  geometry: { type: "Point", coordinates: [] }
};

const initialState: MapState = {
  campusId: DEFAULT_CAMPUS_ID,
  centre: { lat: 0, lng: 0 },
  pitch: 0,
  zoom: defaultZoomLevel,
  campuses: [],
  isCampusesLoading: true,
  hasProcessedUrl: false,
  categories: [],
  itemDetail: defaultItemDetails,
  openItemDetail: false,
  zLevel: 0,
  urlCopyOpen: false,
  style: DEFAULT_MAP_STYLE,
  feedbackData: { rating: 0, feedbackOption: "", feedbackContent: "" },
  feedbackOptions: [
    { value: "INCORRECT_INFORMATION", name: "Incorrect Information" },
    { value: "OUT_OF_ORDER", name: "Out of Order" },
    { value: "ROUTE_FEEDBACK", name: "Route Feedback" },
    { value: "SUGGESTION", name: "Suggestion" }
  ],
  notificationMessage: "",
  modal: ModalType.NONE
};

function applyCentreAndZoom(state: MapState, newCentre?: LatLng, newZoom?: number, zLevel?: number): void {
  if (newCentre && (state.centre.lat !== newCentre.lat || state.centre.lng !== newCentre.lng))
    state.centre = { ...newCentre };
  if (newZoom && state.zoom !== newZoom) state.zoom = newZoom;
  if (zLevel && state.zLevel !== zLevel) state.zLevel = zLevel;
}

export const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    setCampusId: (state, action: PayloadAction<string>) => {
      state.campusId = action.payload;

      // show a notification
      const campus = state.campuses.find(campus => campus.properties.id === action.payload);
      if (campus) {
        state.notificationMessage = campus.properties.name;
      }
    },
    setCenterCoordinates: (state, action: PayloadAction<LatLng>) => {
      state.centre = { lat: action.payload.lat, lng: action.payload.lng };
    },
    processUrl: (state, action: PayloadAction<MapParameters>) => {
      if (state.hasProcessedUrl) return;
      if (action.payload.lat && state.centre.lat !== action.payload.lat) {
        state.centre.lat = action.payload.lat;
      }
      if (action.payload.lng && state.centre.lng !== action.payload.lng) {
        state.centre.lng = action.payload.lng;
      }
      if (action.payload.zoom && state.zoom !== action.payload.zoom) {
        state.zoom = action.payload.zoom;
      }
      if (action.payload.zLevel && state.zLevel !== action.payload.zLevel) {
        state.zLevel = action.payload.zLevel;
      }
      if (action.payload.campusId && state.campusId !== action.payload.campusId) {
        state.campusId = action.payload.campusId;
      }
      if (action.payload.pitch && state.pitch !== action.payload.pitch) {
        state.pitch = action.payload.pitch;
      }
      state.hasProcessedUrl = true;
    },
    moveMapTo: (
      state,
      action: PayloadAction<{
        centre?: LatLng;
        zoom?: number;
        zLevel?: number;
        rotation?: number;
        pitch?: number;
      }>
    ) => {
      applyCentreAndZoom(state, action.payload.centre, action.payload.zoom, action.payload.zLevel);
      state.rotation = action.payload.rotation;
      if (action.payload.pitch) state.pitch = action.payload.pitch;
    },
    setZoom: (state, action: PayloadAction<number>) => {
      if (state.zoom !== action.payload) state.zoom = action.payload;
    },
    setZLevel: (state, action: PayloadAction<number>) => {
      if (state.zLevel !== action.payload) state.zLevel = action.payload;
    },
    setCampuses: (state, action: PayloadAction<Campus[]>) => {
      state.campuses = action.payload;
      state.isCampusesLoading = false;
    },
    setItemDetails: (state, action: PayloadAction<PointOfInterest>) => {
      // selects POI without changing state.map.zLevel.
      state.itemDetail = PoiUtil.toItemDetail(action.payload);
      state.openItemDetail = true;
    },
    closeItemDetails: state => {
      state.openItemDetail = false;
      state.itemDetail = defaultItemDetails;
    },
    setCategoryDetails: (state, action: PayloadAction<Category[]>) => {
      state.categories = action.payload;
    },
    setMapRotation: (state, action: PayloadAction<number>) => {
      state.rotation = action.payload;
    },
    setRotationAndPitch: (state, action: PayloadAction<{ rotation: number; pitch: number }>) => {
      state.pitch = action.payload.pitch;
      state.rotation = action.payload.rotation;
    },
    fitToBounds: (
      state,
      action: PayloadAction<{
        ne: LatLng;
        sw: LatLng;
      }>
    ) => {
      state.bounds = action.payload;
    },
    setUrlCopyOpen: (state, action: PayloadAction<boolean>) => {
      state.urlCopyOpen = action.payload;
    },
    setStyle: (state, action: PayloadAction<string>) => {
      state.style = action.payload;
    },
    setFeedbackVal: (state, action: PayloadAction<FeedbackData>) => {
      const newFeedbackData = action.payload;
      state.feedbackData = { ...state.feedbackData, ...newFeedbackData };
    },
    openNotification: (state, action: PayloadAction<string>) => {
      state.notificationMessage = action.payload;
    },
    closeNotification: state => {
      state.notificationMessage = "";
    },
    showModal: (state, action: PayloadAction<ModalType>) => {
      state.modal = action.payload;
    },
    closeModal: state => {
      state.modal = ModalType.NONE;
    }
  }
});

export const fetchCategories
    = (mapData: MapDataService, campusId: string): AppThunk<Promise<void>> =>
      async (dispatch): Promise<void> => {
        const categories = await mapData.getCategories(campusId);
        categories.sort((a, b) => a.title.localeCompare(b.title));
        dispatch(setCategoryDetails(categories));
      };

export const sendFeedback = (): AppThunk<Promise<void>> => async (dispatch, getState): Promise<void> => {
  await Api.sendFeedback(getState().map.feedbackData);
  dispatch(setFeedbackVal({ rating: 0, feedbackOption: "", feedbackContent: "" }));
};

export const setAndFlyToCampus = (campusId: string): AppThunk<void> => async (dispatch, getState): Promise<void> => {
  const selectedCampus = getState().map.campuses.find(campus => campus.properties.id === campusId);
  if (selectedCampus) {
    // calculate the centre of the campus shape and send the user to that point
    const centrePosition = GeoJsonUtil.getCentre(selectedCampus);
    const centreLngLat = GeoJsonUtil.toLngLat(centrePosition);
    dispatch(setCenterCoordinates(centreLngLat));
    // if the user is zoomed in past the default zoom, also reset to default zoom
    const currentZoom = getState().map.zoom;
    if (currentZoom > defaultZoomLevel) {
      dispatch(setZoom(defaultZoomLevel));
    }
  }
  dispatch(setCampusId(campusId));
  await dispatch(fetchCategories(MazeMapDataService, campusId));
};

export const {
  setCampusId,
  setCenterCoordinates,
  setCampuses,
  setZoom,
  processUrl,
  moveMapTo,
  setItemDetails,
  closeItemDetails,
  setCategoryDetails,
  setMapRotation,
  fitToBounds,
  setZLevel,
  setRotationAndPitch,
  setUrlCopyOpen,
  setStyle,
  setFeedbackVal,
  closeNotification,
  openNotification,
  showModal,
  closeModal
} = mapSlice.actions;

export default mapSlice.reducer;
