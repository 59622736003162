import React, { ReactElement } from "react";
import "./ToggleSwitch.scss";

interface ToggleSwitchProps {
  onChange(): void;
  checked: boolean;
  id: number;
}
export default function ToggleSwitch(props: ToggleSwitchProps): ReactElement {
  const { onChange, checked, id: idProps } = props;
  return (
    <div className={"toggle-switch"}>
      <input
        type={"checkbox"}
        className={"toggle-switch-checkbox"}
        name={"toggleSwitch"}
        id={`orderSwitch${idProps}`}
        onChange={onChange}
        checked={checked}
      />
      <label className={"toggle-switch-label"} htmlFor={`orderSwitch${idProps}`}>
        <span className={"toggle-switch-inner"} />
        <span className={"toggle-switch-switch"} />
      </label>
    </div>
  );
}