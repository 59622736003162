import {
  MazeMapCampus,
  MazeMapInstructions,
  MazeMapPOI,
  MazeMapPOIType,
  MazeMapRouteArgument,
  SearchControllerOptions,
  SlimMazeMapPOI
} from "MazeMapTypes";
import { LngLat } from "mapbox-gl";
import { RouteUtil } from "./RouteUtil";
import { LatLngRouteArgument, MapDataService, POIIdRouteArgument, RouteArgumemt } from "./MapDataService";
import { RouteOptions } from "../app/models/RouteOptions";
import { MAZEMAP_CAMPUS_COLLECTION_ID } from "../constants";
import {
  BUILDING_POI_PROPERTIES,
  Campus,
  Category,
  CommonPOIProperties,
  INDOOR_POI_PROPERTIES,
  OUTDOOR_POI_PROPERTIES,
  PointOfInterest,
  RouteDetail,
  SIMPLE_CATEGORY_POI_PROPERTIES,
  SimpleCategoryPOI
} from "../types/UQMapsTypes";
import { LatLng } from "../app/models/LatLng";

const MazeMapDataService: MapDataService = {
  getCampus: async (campusId: string): Promise<Campus> => {
    try {
      const campusIdNum = stringToNumber(campusId);
      const mazemapCampus = await Mazemap.Data.getCampus(campusIdNum);
      return convertCampus(mazemapCampus);
    } catch (e) {
      throw new Error(`Cannot get campus with ID: "${campusId}"`);
    }
  },

  getCampuses: async (): Promise<Campus[]> => {
    const mazemapCampuses = await Mazemap.Data.getCampuses(MAZEMAP_CAMPUS_COLLECTION_ID);
    return mazemapCampuses.map(campus => convertCampus(campus));
  },

  getCategory: async (categoryId: string): Promise<Category> => {
    try {
      const categoryIdNum = stringToNumber(categoryId);
      const category = await Mazemap.Data.getTypeById(categoryIdNum);
      return {
        id: category.id?.toString() ?? "NO_ID",
        title: category.title ?? "Unnamed Category",
        type: "Category"
      };
    } catch (e) {
      throw new Error(`No category found for ID: "${categoryId}"`);
    }
  },

  getCategories: async (campusId: string): Promise<Category[]> => {
    let categoryIds: number[] | undefined = undefined;
    try {
      const campusIdNum = stringToNumber(campusId);
      const campus = await Mazemap.Data.getCampus(campusIdNum);
      categoryIds = campus.properties.poiTypesSelectableInApp;
    } catch (e) {
      throw new Error(`Cannot get campus with ID: "${campusId}"`);
    }
    if (!categoryIds?.length) return [];
    return Promise.all(categoryIds.map(catId => MazeMapDataService.getCategory(catId.toString())));
  },

  getPoi: async (id: string): Promise<PointOfInterest> => {
    const mazeMapPoi = await getMazeMapPOI(id);
    return convertPOI(mazeMapPoi);
  },

  getPoiAt: async (lngLat: LngLat, zLevel: number): Promise<PointOfInterest | undefined> => {
    const feature = await Mazemap.Data.getPoiAt(lngLat, zLevel);

    // no POI found here...
    if (!feature) return undefined;

    if (!feature.properties.title) {
      // fix for ITSADSSD-36326 POIs with no title.
      return undefined;
    }
    return convertPOI(feature);
  },

  getPoisForCategory: async (
    categoryId: string,
    campusId: string,
    start: number,
    rows: number
  ): Promise<PointOfInterest[]> => {
    try {
      const campusIdNum = stringToNumber(campusId);
      const categoryIdNum = stringToNumber(categoryId);

      // this is a kludge in attempt to page MazeMap's rubbish categories API.

      // first get all results
      const { features: allResults } = await Mazemap.Data.getPoisOfTypeAsGeoJSON({
        poiTypeId: categoryIdNum,
        campusId: campusIdNum
      });

      // extract results for the page we want to return
      const slicedResults = allResults.slice(start, start + rows);

      // now fetch rich versions for the selected subset
      return await Promise.all(
        slicedResults.map(async s => {
          if (!s.properties.poiId) throw new Error("No poiId");
          const mazeMapPoi = await Mazemap.Data.getPoi(s.properties.poiId);
          const poi = convertPOI(mazeMapPoi);

          // ensure that the category in question is listed first. This will ensure that the correct icon will be
          // displayed in the search results pane in cases where a POI belongs to multiple categories.
          if (poi.properties.categories) {
            const category = poi.properties.categories.find(x => x.id === categoryId);
            if (category) {
              const otherCategories = poi.properties.categories.filter(x => x !== category);
              poi.properties.categories = [category, ...otherCategories];
            }
          }
          return poi;
        })
      );
    } catch (e) {
      throw new Error(`Cannot get category POIs for ID "${categoryId}", with campus ID: "${campusId}"`);
    }
  },

  getAllCategoryLocations: async (categoryId: string, campusId: string): Promise<SimpleCategoryPOI[]> => {
    try {
      const campusIdNum = stringToNumber(campusId);
      const categoryIdNum = stringToNumber(categoryId);

      // fetch the category and all POIs for the category.
      const [category, featuresCollection] = await Promise.all([
        MazeMapDataService.getCategory(categoryId),
        Mazemap.Data.getPoisOfTypeAsGeoJSON({
          poiTypeId: categoryIdNum,
          campusId: campusIdNum
        })
      ]);

      // map into desired result format
      return featuresCollection.features.map(f => ({
        type: "Feature",
        geometry: f.geometry,
        properties: {
          // this is a bit tricky, ideally we would want to use our own identifier however it isn't available here
          // without doing a full POI lookup which would be very slow over this whole list. So instead, we'll jam
          // in the MazeMap PoiId and it when performing a rich lookup later it should handle it fine thanks to the
          // legacy fallback.
          id: f.properties.poiId?.toString() ?? "NO_ID",
          category,
          type: SIMPLE_CATEGORY_POI_PROPERTIES
        }
      }));
    } catch (e) {
      throw new Error(`Cannot get category POIs for ID "${categoryId}", with campus ID: "${campusId}"`);
    }
  },

  getPoisBySearch: async (
    campusId: string,
    searchTerm: string,
    start: number,
    rows: number,
    nearTo: LatLng
  ): Promise<PointOfInterest[]> => {
    try {
      const campusIdNum = stringToNumber(campusId);

      const options: SearchControllerOptions = {
        campusid: campusIdNum,
        rows: rows,
        start: start,
        withcampus: false,
        withbuilding: true,
        withpois: true,
        withtype: false,
        resultsFormat: "geojson",
        boostbydistance: true,
        searchdiameter: 50,
        ...nearTo
      };
      const searchController = new Mazemap.Search.SearchController(options);
      const searchResult = await searchController.search(searchTerm);
      console.log(searchResult);
      if (searchResult.results?.features) {
        return await Promise.all(searchResult.results.features.map(poi => convertSlimPOI(poi)));
      }
    } catch (e) {
      console.log("Search error occurred", e);
    }
    return [];
  },

  getRoute: async (
    origin: POIIdRouteArgument | LatLngRouteArgument,
    destination: POIIdRouteArgument | LatLngRouteArgument,
    options?: RouteOptions
  ): Promise<RouteDetail> => {
    const [src, dst] = await Promise.all([convertRouteArgument(origin), convertRouteArgument(destination)]);

    const mappedOptions: Mazemap.Data.RouteOptions = {
      avoidStairs: options?.avoidStairs
    };

    const [route, instructions] = await Promise.all([
      Mazemap.Data.getRouteJSON(src, dst, mappedOptions),
      (async (): Promise<MazeMapInstructions | undefined> => {
        try {
          return await Mazemap.Data.getDirections(src, dst, mappedOptions);
        } catch (error) {
          // Instructions only works on campus.
          // TODO: as with the steps, we may be able to make some from the geometry.
          return undefined;
        }
      })()
    ]);

    const routeDetail = RouteUtil.toRouteDetail(route);
    if (instructions) {
      routeDetail.instructions = instructions.routes[0].legs[0].steps;
    }
    return routeDetail;
  }
};

// ------------- HELPERS ------------- //

function convertCampus(campus: MazeMapCampus): Campus {
  return {
    type: "Feature",
    geometry: campus.geometry,
    properties: {
      id: campus.properties.id?.toString() ?? "CAMPUS_NO_ID",
      name: campus.properties.name ?? "Unnamed Campus",
      defaultZLevel: campus.properties.defaultZLevel ?? 0
    }
  };
}

async function convertSlimPOI(poi: SlimMazeMapPOI): Promise<PointOfInterest> {
  if (poi.properties.type === "poi" && poi.properties.poiId) {
    const mazeMapPoi = await Mazemap.Data.getPoi(poi.properties.poiId);
    return convertPOI(mazeMapPoi);
  }

  if (poi.properties.type === "building" && poi.properties.id) {
    const building = await Mazemap.Data.getBuildingPoiJSON(poi.properties.id);
    if (building.poiId) {
      const mazeMapPoi = await Mazemap.Data.getPoi(building.poiId);
      return convertPOI(mazeMapPoi);
    }
  }

  throw new Error(`Unhandled POI Type: "${poi.properties.type}". Cannot get rich information`);
}

async function getMazeMapPOI(id: string): Promise<MazeMapPOI> {
  const getPoiAssumingIdentifier = async (): Promise<MazeMapPOI> => {
    const [poi] = await Mazemap.Data.getPois({ identifier: id });
    if (poi as MazeMapPOI | undefined) return poi;
    throw new Error("No POI found assuming the given ID was an identifier");
  };
  const getPoiAssumingLegacyPoiId = async (): Promise<MazeMapPOI> => {
    const poiIdNum = stringToNumber(id);
    return await Mazemap.Data.getPoi(poiIdNum);
  };

  try {
    return await Promise.any([getPoiAssumingIdentifier(), getPoiAssumingLegacyPoiId()]);
  } catch (e) {
    // give up.
    throw new Error(`Cannot find POI with ID: "${id}"`);
  }
}

function convertPOI(poi: MazeMapPOI): PointOfInterest {
  const commonProps: CommonPOIProperties = {
    id: poi.properties.identifier ?? poi.properties.poiId?.toString() ?? "NO_ID",
    campusId: poi.properties.campusId?.toString() ?? "NO_CAMPUS_ID",
    title: poi.properties.title ?? "",
    otherNames: poi.properties.names,
    description: poi.properties.description,
    infoUrl: poi.properties.infoUrl,
    infoUrlLabel: poi.properties.infoUrlText,
    categories: poi.properties.types?.map(cat => convertCategory(cat))
  };

  switch (poi.properties.kind) {
    case "room":
    case "generic":
      if (poi.properties.buildingId && poi.properties.floorName) {
        return {
          type: "Feature",
          geometry: poi.geometry,
          properties: {
            type: INDOOR_POI_PROPERTIES,
            floorLabel: poi.properties.floorName,
            floorZLevel: poi.properties.zLevel ?? 0,
            buildingName: poi.properties.buildingName ?? "",
            buildingId: poi.properties.buildingId.toString(),
            ...commonProps
          }
        };
      }
      return {
        type: "Feature",
        geometry: poi.geometry,
        properties: {
          type: OUTDOOR_POI_PROPERTIES,
          ...commonProps
        }
      };
    case "building":
      return {
        type: "Feature",
        geometry: poi.geometry,
        properties: {
          type: BUILDING_POI_PROPERTIES,
          ...commonProps
        }
      };
    default:
      throw new Error(`Unknown POI type: ${poi.properties.kind}`);
  }
}

function convertCategory(category: MazeMapPOIType): Category {
  return {
    id: category.poiTypeId?.toString() ?? "NO_CATEGORY_ID",
    title: category.name ?? "Unnamed Category",
    type: "Category"
  };
}

async function convertRouteArgument(arg: RouteArgumemt): Promise<MazeMapRouteArgument> {
  switch (arg.type) {
    case "LatLngRouteArgument":
      return {
        lngLat: {
          lat: arg.lngLat.lat,
          lng: arg.lngLat.lng
        },
        zLevel: arg.zLevel
      };

    case "POIIdRouteArgument": {
      const poi = await getMazeMapPOI(arg.id);
      return {
        poiId: poi.properties.poiId as number
      };
    }
    default:
      throw new Error("Unknown route argument type.");
  }
}

function stringToNumber(input: string): number {
  const num = Number(input);
  if (isNaN(num)) {
    throw new Error(`Cannot convert "${input}" to a number`);
  }
  return num;
}

export default MazeMapDataService;
