import { LiveParking } from "./LiveParking";
import { LiveBuildings } from "./LiveBuildings";
import { LiveComputers } from "./LiveComputers";
import Api from "./ApiService";
import { IProvideLiveData } from "./IProvideLiveData";
import { LiveDataCategoryType } from "./LiveDataCategoryType";

const providers: Record<string, IProvideLiveData> = {
  [LiveDataCategoryType.PARKING]: new LiveParking(),
  [LiveDataCategoryType.COMPUTERS]: new LiveComputers()
};

const liveDataProviderFactory = {
  async getProvider(type: LiveDataCategoryType): Promise<IProvideLiveData | undefined> {
    const category = await Api.getCategory(type);
    if (category?.hasLiveData) {
      return providers[category.categoryId];
    }
    console.log("No provider available for category");
  }
};

export default liveDataProviderFactory;
