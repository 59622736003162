import React, { ReactElement, useState } from "react";
import { ReactComponent as Star } from "../../../src/assets/Icons/star.svg";
import styles from "./ratingFeedback.module.scss";
import { setFeedbackVal } from "../../store/map/mapSlice";
import IconButton from "../../components/IconButton";
import classnames from "classnames";
import { useDispatch } from "react-redux";

export default function RatingFeedback(): ReactElement {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const starsNumber = 5;
  function onMouseOver(currentStarIndex: number): void {
    setValue(0);
    setValue(currentStarIndex + 1);
  }
  function onStarClick(currentStarIndex: number): void {
    onMouseOver(currentStarIndex);
    dispatch(setFeedbackVal({ rating: value }));
  }
  return (
    <div>
      {[...Array(starsNumber).keys()].map((index) => {
        return (
          <IconButton
            onClick={(): void => onStarClick(index)}
            onMouseOver={(): void => onMouseOver(index)}
            ariaLabel={"Star icon"}
            key={index}
            className={classnames(styles.starIcon, index < value ? styles.filled : null)}
          >
            <Star />
          </IconButton>
        );
      })}
    </div>
  );
}
