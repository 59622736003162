import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import { SearchState } from "../../app/models/SearchState";
import { LatLng } from "../../app/models/LatLng";
import MazeMapDataService from "../../services/MazeMapDataService";
import { pushLayer } from "../layers/layerSlice";
import { GeoJsonUtil } from "../../services/GeoJsonUtil";
import { fitToBounds } from "../map/mapSlice";
import { PointOfInterest } from "../../types/UQMapsTypes";

export const SEARCH_PAGE_SIZE = 20;
export const CATEGORY_RESULTS_LAYER_NAME = "Category Results";
export const SEARCH_RESULTS_LAYER_NAME = "Search Results";

const initialState: SearchState = {
  isSearching: false,
  isLoading: false,
  searchTerm: "",
  searchResults: {
    currentPage: -1,
    hasMore: false,
    pageSize: SEARCH_PAGE_SIZE,
    items: []
  },
  hasNoResults: false,
  hasSelectedSearchResult: false,
  searchedCentre: { lat: 0, lng: 0 },
  isShowingCategoryResults: false,
  searchError: "",
  showMobileItemDetails: false
};

const clearSearchResults = (state: SearchState): void => {
  state.searchResults.currentPage = -1;
  state.searchResults.hasMore = false;
  state.searchResults.items = [];
  state.hasNoResults = false;
  state.hasSelectedSearchResult = false;
  state.isShowingCategoryResults = false;
  state.selectedCategory = undefined;
};

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    updateSearchTerm: (state, action: PayloadAction<string>) => {
      if (state.searchTerm === action.payload) return;
      state.searchTerm = action.payload;
      state.isLoading = Boolean(action.payload);
      clearSearchResults(state);
    },
    searchResults: (
      state,
      action: PayloadAction<{
        searchTerm: string;
        pageNumber: number;
        searchResult: PointOfInterest[];
        searchedCentre: LatLng;
      }>
    ) => {
      if (state.searchTerm !== action.payload.searchTerm) {
        //Text has been updated before this result returned
        return;
      }
      state.isLoading = false;
      const resultsToAdd = action.payload.searchResult.filter(feature => Boolean(feature.properties.title.trim()));
      if (action.payload.pageNumber === 0) {
        state.searchResults.items = resultsToAdd;
      } else {
        const currentItems = state.searchResults.items;
        state.searchResults.items = [...currentItems, ...resultsToAdd];
      }
      state.searchResults.hasMore = action.payload.searchResult.length === SEARCH_PAGE_SIZE;
      state.searchResults.currentPage = action.payload.pageNumber;
      state.hasNoResults = !state.searchResults.hasMore && state.searchResults.items.length === 0;
      state.searchedCentre = action.payload.searchedCentre;
    },
    beginSearching: state => {
      state.isSearching = true;
    },
    selectSearchResult: state => {
      state.hasSelectedSearchResult = true;
      state.selectedCategory = undefined;
    },
    deselectSearchResult: state => {
      state.hasSelectedSearchResult = false;
    },
    endSearching: state => {
      state.isSearching = false;
      state.searchTerm = "";
      clearSearchResults(state);
    },
    beginLoading: state => {
      state.isLoading = true;
    },
    endLoading: state => {
      state.isLoading = false;
    },
    clearSearchError: state => {
      state.searchError = "";
    },
    setSearchError: (state, action: PayloadAction<string>) => {
      state.searchError = action.payload;
    },
    toggleMobileItemDetails: (state, action: PayloadAction<boolean>) => {
      state.showMobileItemDetails = action.payload;
    },
    categorySearchResults: (
      state,
      action: PayloadAction<{
        results: PointOfInterest[];
        categoryTitle: string;
        categoryId: string;
        pageNumber: number;
      }>
    ) => {
      state.isLoading = false;
      const resultsToAdd = action.payload.results.filter(feature => Boolean(feature.properties.title.trim()));
      if (action.payload.pageNumber === 0) {
        state.searchResults.items = resultsToAdd;
      } else {
        const currentItems = state.searchResults.items;
        state.searchResults.items = [...currentItems, ...resultsToAdd];
      }
      state.searchResults.hasMore = action.payload.results.length === SEARCH_PAGE_SIZE;
      state.searchResults.currentPage = action.payload.pageNumber;
      state.hasNoResults = !state.searchResults.hasMore && state.searchResults.items.length === 0;
      state.isShowingCategoryResults = true;
      state.selectedCategory = action.payload.categoryId;
      state.searchTerm = action.payload.categoryTitle;
    }
  }
});

export const {
  updateSearchTerm,
  beginSearching,
  endSearching,
  searchResults,
  selectSearchResult,
  deselectSearchResult,
  beginLoading,
  endLoading,
  clearSearchError,
  setSearchError,
  categorySearchResults,
  toggleMobileItemDetails
} = searchSlice.actions;

export const fetchSearchResults
  = (campusId: string, newSearchTerm: string, pageNumber: number, nearTo: LatLng): AppThunk<Promise<void>> =>
    async (dispatch): Promise<void> => {
      try {
        const pois = await MazeMapDataService.getPoisBySearch(
          campusId,
          newSearchTerm,
          pageNumber * SEARCH_PAGE_SIZE,
          SEARCH_PAGE_SIZE,
          nearTo
        );
        dispatch(
          searchResults({
            searchTerm: newSearchTerm,
            pageNumber,
            searchResult: pois,
            searchedCentre: nearTo
          })
        );
        dispatch(clearSearchError());
      } catch (error) {
        dispatch(endLoading());
        dispatch(setSearchError("Error while performing search"));
        console.log(`Mazemap connection issues: ${error}`);
      }
    };

export const fetchCategoryResults
  = (campusId: string, categoryId: string, categoryTitle: string, pageNumber: number): AppThunk<Promise<void>> =>
    async (dispatch, getState): Promise<void> => {
      try {
        const pois = await MazeMapDataService.getPoisForCategory(
          categoryId,
          campusId,
          pageNumber * SEARCH_PAGE_SIZE,
          SEARCH_PAGE_SIZE
        );

        const orientation = getState().geolocator;
        if (orientation.watch && orientation.coordinates) {
          const coordinates = orientation.coordinates;
          const sortedPois = GeoJsonUtil.sortFeaturesByProximity(coordinates, pois);
          dispatch(categorySearchResults({ results: sortedPois, categoryTitle, categoryId, pageNumber }));
        } else {
          dispatch(categorySearchResults({ results: pois, categoryTitle, categoryId, pageNumber }));
        }

        dispatch(endLoading());
      } catch (error) {
        dispatch(endLoading());
        dispatch(setSearchError("Error while performing category search"));

        // bubble error back up to console for debugging purposes
        console.error(error);
      }
    };

export const fetchCategoryResultsForMap
  = (campusId: string, categoryId: string): AppThunk<Promise<void>> =>
    async (dispatch): Promise<void> => {
      try {
        const pois = await MazeMapDataService.getAllCategoryLocations(categoryId, campusId);
        dispatch(
          pushLayer({
            name: CATEGORY_RESULTS_LAYER_NAME,
            type: "selectable",
            layerDetails: {
              items: pois,
              cluster: true
            },
            includeInMapControl: false
          })
        );
        if (pois.length === 0) return;
        const poiBounds = GeoJsonUtil.getBounds(pois);
        dispatch(
          fitToBounds({
            ne: GeoJsonUtil.toLatLng(poiBounds.getNorthEast()),
            sw: GeoJsonUtil.toLatLng(poiBounds.getSouthWest())
          })
        );
      } catch (error) {
        throw new Error("Error while loading category location onto the map.");
      }
    };

export default searchSlice.reducer;
