import React, { ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import styles from "./copyrightPanel.module.scss";
import { showModal } from "../../store/map/mapSlice";
import { RootState } from "../../app/store";
import { TITLE_ABOUT, TITLE_OPENSTREETMAP, TITLE_PRIVACY } from "../../constants";
import { ModalType } from "../../app/models/MapState";

export default function CopyrightPanel(): ReactElement {

  const dispatch = useDispatch();
  const siteFeatures = useSelector((state: RootState) => state.settings.siteFeatures);
  const footer = siteFeatures.footer;

  return !footer ? <></> : (
    <div className={classnames(styles.copyrightPanel)}>
      {siteFeatures.aboutDialog && (
        <>
          <button
            title={TITLE_ABOUT}
            className={classnames("uq-button uq-button--link", styles.link)}
            onClick={(): unknown => dispatch(showModal(ModalType.ABOUT))}
            aria-label={TITLE_ABOUT}
          >
            {TITLE_ABOUT}
          </button>
          <span className={styles.sep}>{"|"}</span>
        </>
      )}
      <a
        className={styles.link}
        target={"_blank"}
        rel={"noopener noreferrer"}
        href={"https://www.uq.edu.au/terms-of-use/"}
        aria-label={TITLE_PRIVACY}
      >
        {TITLE_PRIVACY}
      </a>
      <span className={styles.sep}>{"|"}</span>
      <a
        className={styles.link}
        target={"_blank"}
        rel={"noopener noreferrer"}
        href={"https://www.openstreetmap.org/copyright"}
        aria-label={TITLE_OPENSTREETMAP}
      >
        {TITLE_OPENSTREETMAP}
      </a>
    </div>
  );
}
