import React, { ReactElement, useEffect, useState } from "react";
import classnames from "classnames";
import { ReactComponent as Close } from "../../../assets/Icons/close.svg";
import "./ModalCloseButton.scss";

interface ModalCloseButtonProps {
  closeModal(): void;
  className?: string;
}

export default function ModalCloseButton(props: ModalCloseButtonProps): ReactElement {
  // Props
  const { className: classNameProp, closeModal } = props;

  // State
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    function onKeyDown(event: KeyboardEvent): void {
      event.key === "Enter" && focused && closeModal();
    }
    document.addEventListener("keydown", onKeyDown);
    return (): void => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [focused, closeModal]);

  // Events
  const onFocus = (): void => {
    setFocused(true);
  };

  const onBlur = (): void => {
    setFocused(false);
  };

  return (
    <div
      className={classnames("uq-modal-close-button", classNameProp)}
      onFocus={onFocus}
      onBlur={onBlur}
      tabIndex={0}
      title={"Close Dialog"}
    >
      <button onClick={closeModal} tabIndex={-1}>
        <Close />
      </button>
    </div>
  );
}
