import React, { forwardRef, useCallback, useRef } from "react";
import styles from "./wayFindingField.module.scss";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSearchResults,
  fetchWayFindingSearchResults,
  selectDestination,
  selectOrigin,
  updateSearchTerm
} from "../../../store/wayFinding/wayFindingSlice";
import IconButton from "../../../components/IconButton";
import { SEARCH_STOP, PROGRESS, ORIGIN, DESTINATION } from "../../../constants";
import { ReactComponent as Close } from "../../../assets/Icons/close.svg";
import { RootState } from "../../../app/store";

interface InputFieldProps {
  kind: string;
  autoFocus?: boolean;
  value: string;
  setValue(value: string): void;
}

const WayFindingField = forwardRef<HTMLInputElement, InputFieldProps>((props, ref) => {
  const dispatch = useDispatch();
  const {
    kind,
    autoFocus,
    value: valueProps,
    setValue: setValueProp
  } = props;
  const wayFinding = useSelector((state: RootState) => state.wayFinding);
  const currentRoute = wayFinding.currentRoute;
  const isLandscape = useSelector((state: RootState) => state.settings.useLandscapeLayout);
  const isCurrentKind = (wayFinding.isSelectingOrigin ? "origin" : "destination") === kind;
  const isOriginKind = kind === "origin";
  const isLoading = useSelector((state: RootState) =>
    isCurrentKind
    && state.wayFinding.pointSelection.isLoading);
  const campusId = useSelector((state: RootState) => state.map.campusId);
  const centre = useSelector((state: RootState) => state.map.centre);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const setRef = useCallback((x: HTMLInputElement | null) => {
    inputRef.current = x;
    if (typeof ref === "function") {
      ref(x);
    } else if (ref) {
      ref.current = x;
    }
  }, [ref]);

  function focusInputField(): void {
    inputRef.current?.focus();
  }
  function onInputChanged(searchTerm: string): void {
    setValueProp(searchTerm);
    dispatch(isOriginKind ? selectOrigin(): selectDestination());
    dispatch(updateSearchTerm(searchTerm));
    dispatch(fetchWayFindingSearchResults(String(campusId ?? 0), searchTerm, 0, centre));
  }

  const impulseClass = classnames(
    styles.searchWayImpulse,
    isCurrentKind && (wayFinding.isSelectingOrigin || wayFinding.isSelectingDestination)
      ? styles.active
      : null,
    !isOriginKind ? styles.searchWayImpulseBottom : null
  );

  return (
    <div className={classnames(styles.searchFieldWrap)}>
      <em className={impulseClass} />
      <>
        <input
          autoFocus={autoFocus}
          placeholder={isOriginKind ? ORIGIN : DESTINATION}
          type={"text"}
          className={styles.searchWayField}
          value={valueProps}
          ref={setRef}
          onFocus={(event): void => {
            dispatch(isOriginKind ? selectOrigin() : selectDestination());
            if (!currentRoute || isLandscape) {
              onInputChanged(event.target.value);
            }
          }}
          onChange={(event): void => onInputChanged(event.target.value)}
        />
        <div className={styles.fieldIcons} tabIndex={1}>
          {valueProps ? (
            isLoading ? (
              <IconButton
                ariaLabel={PROGRESS}
                className={classnames("uq-loading-spinner uq-loading-spinner--small", styles.progressIcon)}
              />
            ) : (
              <IconButton
                onClick={(): void => {
                  setValueProp("");
                  focusInputField();
                  onInputChanged("");
                }}
                icon={<Close />}
                ariaLabel={SEARCH_STOP}
                className={styles.closeIcon}
                onFocus={(): void => {
                  dispatch(clearSearchResults());
                }}
              />
            )
          ) : (
            <></>
          )}
        </div>
      </>
    </div>
  );
});

WayFindingField.displayName = "WayFindingField";

export default WayFindingField;