import React, { createRef, ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { setZLevel } from "../../store/map/mapSlice";
import Dropdown from "../dropdown";
import styles from "./floorDropdown.module.scss";

export default function FloorDropdown(): ReactElement {
  const dispatch = useDispatch();
  const ref = createRef<HTMLDivElement>();


  // Global State
  const zLevel = useSelector((state: RootState) => state.map.zLevel);

  // Computed
  const currentHeader = options.find(option => Number(option.zLevel) === Number(zLevel))?.name;

  return (
    <Dropdown
      onClick={(option): unknown => dispatch(setZLevel(Number(option.value)))}
      header={`Floor ${currentHeader}`}
      options={options.map(floor => ({
        value: floor.zLevel,
        label: floor.name
      }))}
      ref={ref}
      classList={styles.w100}
    />
  );
}

interface Floor {
  zLevel: string;
  name: string;
}

const options: Floor[] = [
  { zLevel: "-1", name: "0" },
  { zLevel: "1", name: "1" },
  { zLevel: "2", name: "2" },
  { zLevel: "2.5", name: "2A" },
  { zLevel: "3", name: "3" },
  { zLevel: "3.5", name: "3A" },
  { zLevel: "4", name: "4" },
  { zLevel: "5", name: "5" },
  { zLevel: "6", name: "6" },
  { zLevel: "7", name: "7" },
  { zLevel: "8", name: "8" },
  { zLevel: "9", name: "9" },
  { zLevel: "10", name: "10" },
  { zLevel: "11", name: "11" },
  { zLevel: "12", name: "12" },
  { zLevel: "13", name: "13" },
  { zLevel: "14", name: "14" }
];