import { Feature, FeatureCollection, Geometry, Polygon } from "geojson";
import { PathMetrics } from "MazeMapTypes";

export type Campus = Feature<Polygon, CampusProperties>;
export interface CampusProperties {
  id: string;
  name: string;
  defaultZLevel: number;
}

export type PointOfInterest = Feature<Geometry, POIProperties>;
export type POIProperties = BuildingPOIProperties | OutdoorPOIProperties | IndoorPOIProperties;

export const BUILDING_POI_PROPERTIES = "BuildingPOIProperties";
export interface BuildingPOIProperties extends CommonPOIProperties {
  type: typeof BUILDING_POI_PROPERTIES;
}

export const OUTDOOR_POI_PROPERTIES = "OutdoorPOIProperties";
export interface OutdoorPOIProperties extends CommonPOIProperties {
  type: typeof OUTDOOR_POI_PROPERTIES;
}

export const INDOOR_POI_PROPERTIES = "IndoorPOIProperties";
export interface IndoorPOIProperties extends CommonPOIProperties {
  buildingId: string;
  buildingName: string;
  floorLabel: string;
  floorZLevel: number;
  type: typeof INDOOR_POI_PROPERTIES;
}

export interface CommonPOIProperties {
  id: string;
  campusId: string;
  title: string;
  otherNames?: string[];
  description?: string;
  imageUrls?: string[];
  infoUrl?: string;
  infoUrlLabel?: string;
  categories?: Category[];

  propertiesJson?: string;
}

export interface Category {
  id: string;
  title: string;
  type: "Category";
}


// Simple Category POI is just a super cut down POI used for rendering a marker
// on the map.
export type SimpleCategoryPOI = Feature<Geometry, SimpleCategoryPOIProperties>;
export const SIMPLE_CATEGORY_POI_PROPERTIES = "SimpleCategoryPOIProperties";
export interface SimpleCategoryPOIProperties {
  id: string;
  category: Category;
  type: typeof SIMPLE_CATEGORY_POI_PROPERTIES;
}

export type MixedPOIList = (PointOfInterest | SimpleCategoryPOI)[];

export interface RouteDetail {
  route: FeatureCollection;
  metrics: PathMetrics;
  mode: string; // walk, car etc...
  steps: RouteDetailStep[];
  instructions: {
    instruction: string;
    geometry: Geometry;
  }[];
}

export interface RouteDetailStep {
  step: string;
  geometry: Geometry;
  timeSeconds?: number;
  routePoint?: string;
  zLevel?: number;
}
