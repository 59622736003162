import React, { ReactElement } from "react";
import styles from "./wayFindingLightView.module.scss";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsNavigatingState
} from "../../../store/wayFinding/wayFindingSlice";
import { getLocationLabel } from "../../../MapsApp";
import { ReactComponent as SwapIconWhite } from "../../../assets/Swap_white.svg";
import { RootState } from "../../../app/store";
import SwapIconButton from "../swapIconButton";
import IconButton from "../../../components/IconButton";
import { PROGRESS } from "../../../constants";
import AvoidStairsButton from "../avoidStairsButton";

interface WayFindingLightViewProps {
  setLightView(value: boolean): void;
  setPanelOpen(value: boolean): void;
  lightView: boolean;
}

export default function WayFindingLightView(props: WayFindingLightViewProps): ReactElement {
  const dispatch = useDispatch();
  const { setLightView, setPanelOpen, lightView: lightViewProps } = props;
  const wayFinding = useSelector((state: RootState) => state.wayFinding);

  return (
    <div className={styles.searchWay}>
      <div
        className={styles.fieldResults}
        onClick={(): void => {
          setLightView(false);
          setPanelOpen(true);
          dispatch(setIsNavigatingState(false));
        }}
      >
        <p>
          <em className={styles.searchWayImpulse} />
          <span>{getLocationLabel(wayFinding.origin)}</span>
        </p>
        <p>
          <em className={classnames(styles.searchWayImpulse, styles.searchWayImpulseBottom)} />
          <span>{getLocationLabel(wayFinding.destination)}</span>
        </p>
      </div>
      <AvoidStairsButton lightView={lightViewProps} />
      {!wayFinding.isRouteLoading ? (
        <SwapIconButton className={styles.swapIcon} icon={<SwapIconWhite />} />
      ) : (
        <IconButton
          ariaLabel={PROGRESS}
          className={classnames("uq-loading-spinner uq-loading-spinner--small", styles.progressIcon)}
        />
      )}
    </div>
  );
}
