import locationIcon from "../assets/Icons/BoldWhite/location_icon_white.svg";
import buildingsIcon from "../assets/Icons/BoldWhite/bold_white_building.svg";
import locationIconPrimary from "../assets/Icons/LightPurple/location_icon_large.svg";
import buildingsIconPrimary from "../assets/Icons/LightPurple/buildings-2.svg";
import { MapEx } from "MazeMapTypes";
import { BUILDING_POI_PROPERTIES } from "../types/UQMapsTypes";
import { categoryIconPrefix, getPrimaryCategoryIcon } from "./categoryIcons";

export const TYPE_ICON_PREFIX = "type-";
export const TYPE_ICON_PRIMARY_PREFIX = "type-primary-";

const typeIcons: { [id: string]: string } = {
  poi: locationIcon, // Marker Point - General
  building: buildingsIcon // Building - General
};

const typeIconsPrimary: { [id: string]: string } = {
  poi: locationIconPrimary, // Marker Point - General
  building: buildingsIconPrimary // Building - General
};

export const getTypeIcon = (type: string): string => {
  if (type === BUILDING_POI_PROPERTIES) {
    return typeIcons.building;
  }
  return typeIcons.poi;
};

export const getPrimaryTypeIcon = (type: string): string => {
  if (type === BUILDING_POI_PROPERTIES) {
    return typeIconsPrimary.building;
  }
  return typeIconsPrimary.poi;
};

export const ensureTypeIconsAddedToMap = async (map: MapEx): Promise<void> => {
  for (const key in typeIcons) {
    const imageKey = TYPE_ICON_PREFIX + key;
    const element = typeIcons[key];
    await loadImage(map, imageKey, element);
  }
  for (const key in typeIconsPrimary) {
    const imageKey = TYPE_ICON_PRIMARY_PREFIX + key;
    const element = typeIconsPrimary[key];
    await loadImage(map, imageKey, element);
  }
};

export const getIconImageKey = (iconPrefix: string, key: string): string => {
  return iconPrefix + key;
};

export const loadImage = (map: MapEx, imageKey: string, element: string): Promise<void> => {
  if (!map.hasImage(imageKey)) {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.onload = (): void => {
        if (!map.hasImage(imageKey))
          //double check because async
          try {
            map.addImage(imageKey, img);
            resolve();
          } catch (e) {
            console.log(`### loadImage imageKey ${imageKey} img: ${img} err: `, e);
            reject(e);
          }
      };
      img.onerror = reject;
      img.src = element;
    });
  }
  return Promise.resolve();
};

export function missingImageLoader(map: MapEx, e: { id: string }): void {
  if (e.id.startsWith(categoryIconPrefix)) {
    const element = getPrimaryCategoryIcon("");
    loadImage(map, e.id, element);
  } else if (e.id.startsWith(TYPE_ICON_PREFIX)) {
    const element = getTypeIcon("poi");
    loadImage(map, e.id, element);
  }
}
