import { MapEx, ClickDispatcher } from "MazeMapTypes";

/**
 * Mapbox click event handlers fire in any order, this class is to handle the clicks in the layers first
 */
export class MapClickDispatcher implements ClickDispatcher {
  private readonly layerHandlers: { layer: string; handler(e: mapboxgl.MapMouseEvent): void }[] = [];

  private readonly mapHandlers: { handler(e: mapboxgl.MapMouseEvent): void }[] = [];

  private readonly map: MapEx;

  constructor(map: MapEx) {
    this.map = map;
    map._clickDispatcher = this;
    map.on("click", this.mapClickHandler);
  }

  public onClick(handler: (e: mapboxgl.MapMouseEvent) => void): void {
    this.mapHandlers.push({ handler });
  }

  public offClick(handler: (e: mapboxgl.MapMouseEvent) => void): void {
    const index = this.mapHandlers.findIndex(h => h.handler === handler);
    if (index > -1) this.mapHandlers.splice(index, 1);
  }

  public onLayerClick(layer: string, handler: (e: mapboxgl.MapMouseEvent) => void): void {
    this.layerHandlers.push({ layer, handler });
  }

  public offLayerClick(layer: string, handler: (e: mapboxgl.MapMouseEvent) => void): void {
    const index = this.layerHandlers.findIndex(h => h.layer === layer && h.handler === handler);
    if (index > -1) this.layerHandlers.splice(index, 1);
  }

  private readonly mapClickHandler = (e: mapboxgl.MapMouseEvent): void => {
    for (const item of this.layerHandlers) {
      if (e.defaultPrevented) continue;
      try {
        const lh = item;
        const f = this.map.queryRenderedFeatures(e.point, { layers: [lh.layer] });
        if (f.length) {
          lh.handler(e);
        }
      } catch (e) {
        console.error(e);
      }
    }

    for (const item of this.mapHandlers) {
      if (e.defaultPrevented) continue;
      try {
        const mh = item;
        const f = this.map.queryRenderedFeatures(e.point);
        if (f.length) {
          mh.handler(e);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };
}
