import React, { ReactElement } from "react";
import styles from "./itemDescriptionButtons.module.scss";
import classnames from "classnames";
import { ItemDetail } from "../../../app/models/ItemDetail";
import { ReactComponent as NavigationNext } from "../../../assets/navigation_next.svg";
import { ReactComponent as RoadSignTurnRight } from "../../../assets/road-sign-turn-right-1.svg";
import { ReactComponent as ShareIcon } from "../../../assets/share_link.svg";
import { cleanNavigateClicked, initiateWayfinding, navigateClicked } from "../../../store/wayFinding/wayFindingSlice";
import { useDispatch, useSelector } from "react-redux";
import { UrlProcessing } from "../../../services/UrlProcessing";
import copy from "clipboard-copy";
import { openNotification, setUrlCopyOpen } from "../../../store/map/mapSlice";
import { ReactComponent as EmbedIcon } from "../../../assets/share_icon.svg";
import { RootState } from "../../../app/store";

interface itemTitleProps {
  setPanelOpen(value: boolean): void;
  itemDetail?: ItemDetail;
}

export default function ItemDescriptionButtons(props: itemTitleProps): ReactElement {
  const { itemDetail: itemDetail, setPanelOpen } = props;
  const dispatch = useDispatch();
  const isLandscape = useSelector((state: RootState) => state.settings.useLandscapeLayout);
  const embed = useSelector((state: RootState) => state.settings.siteFeatures.embed);
  const showMobileItemDetails = useSelector((state: RootState) => state.search.showMobileItemDetails);

  const clickNavigateButton = (): void => {
    dispatch(initiateWayfinding(itemDetail));
    dispatch(navigateClicked());
    dispatch(cleanNavigateClicked());
    setPanelOpen(true);
  };

  const handleShareClicked = (): void => {
    const sharedURL = UrlProcessing.getItemShareUrl(window.location, itemDetail);
    if (!embed) {
      copy(sharedURL).then(() => dispatch(setUrlCopyOpen(true)));
      dispatch(openNotification("Link copied to clipboard!"));
    } else {
      window.open(sharedURL, "_blank");
    }
  };

  const handleEmbedClick = (): void => {
    const url = `${window.location.href}&embed=true`;
    const iframe = `<iframe src="${url}" width="420" height="600" style="border: 1px solid grey; margin: 0;"></iframe>`;
    copy(iframe).then(() => dispatch(setUrlCopyOpen(true)));
    dispatch(openNotification("Embed code copied to clipboard!"));
  };

  const navigateButtonClass = classnames(
    isLandscape && showMobileItemDetails ? "uq-button uq-button--small uq-button--purple" : styles.directionButton
  );
  
  const commonButtonClass = classnames(
    isLandscape && showMobileItemDetails ? "uq-button uq-button--small uq-button--text" : styles.descriptionButton
  );

  const circleClass = classnames(
    !isLandscape || !showMobileItemDetails ? styles.outlinedButton : styles.hidden
  );

  return (
    <p className={!isLandscape || !showMobileItemDetails ? styles.itemDescriptionButtons : ""}>
      <button className={navigateButtonClass} onClick={clickNavigateButton}>
        <span className={circleClass}>
          <RoadSignTurnRight />
        </span>
        <span>{"Navigate"}</span>
      </button>

      {itemDetail?.properties?.infoUrl && (
        <a
          className={commonButtonClass}
          target={"_blank"}
          rel={"noopener noreferrer"}
          href={itemDetail.properties.infoUrl}
          aria-label={itemDetail.properties.infoUrlLabel ?? "Website"}
        >
          <span className={classnames(circleClass, styles.purpleSvg)}>
            <NavigationNext />
          </span>
          {itemDetail.properties.infoUrlLabel ?? "Website"}
        </a>
      )}
      <button className={commonButtonClass} onClick={handleShareClicked}>
        <span className={classnames(circleClass, styles.shareLink)}>
          <ShareIcon />
        </span>
        <span>{"Share"}</span>
      </button>
      <button className={commonButtonClass} onClick={handleEmbedClick}>
        <span className={circleClass}>
          <EmbedIcon />
        </span>
        <span>{"Embed"}</span>
      </button>
    </p>
  );
}
