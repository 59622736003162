import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LayerState } from "../../app/models/LayerState";
import { Layer } from "../../app/models/Layer";

export const _LAYER_WELL_LIT = "Well-lit Paths";
export const _LAYER_BUSYNESS = "Floor Busyness";
export const _LAYER_ACCESSIBLE = "Accessible Paths";

export const defaultLayerName = "Default";
const initialState: LayerState = {
  layers: [],
  // TODO: remove
  selectingLayer: defaultLayerName,
  selectedLayer: defaultLayerName
};

export const layerSlice = createSlice({
  name: "layer",
  initialState,
  reducers: {
    pushLayer: (state, action: PayloadAction<Layer>) => {
      state.layers = [...state.layers, action.payload];
    },
    clearLayers: state => {
      state.layers = [];
    },
    removeLayer: (state, action: PayloadAction<string>) => {
      const index = state.layers.findIndex(x => x.name === action.payload);
      if (index !== -1) {
        state.layers.splice(index, 1);
      }
    },
    replaceLayer: (state, action: PayloadAction<Layer>) => {
      const existingLayer = state.layers.find(l => l.name === action.payload.name);
      if (existingLayer) {
        for (const key in action.payload) {
          const layerKey = key as keyof Layer;
          if (Object.prototype.hasOwnProperty.call(action.payload, key)) {
            existingLayer[layerKey] = action.payload[layerKey] as never;
          }
        }
      } else {
        state.layers = state.layers.concat(action.payload);
      }
    },
    hideAllLayers: state => {
      state.layers
        .filter(l => !l.includeInMapControl)
        .forEach(layer => {
          layer.hidden = true;
        });
    },
    showAllLayers: state => {
      state.layers
        .filter(l => !l.includeInMapControl)
        .forEach(layer => {
          layer.hidden = false;
        });
    },
    showLayer: (state, action: PayloadAction<string>) => {
      const layerName = action.payload;
      const layer = state.layers.find(l => l.name === layerName);
      if (layer) layer.hidden = false;
    },
    changeSelectedLayer: (state, action: PayloadAction<string>) => {
      state.selectedLayer = action.payload;
    }
  }
});

export const {
  pushLayer,
  clearLayers,
  removeLayer,
  replaceLayer,
  hideAllLayers,
  showLayer,
  showAllLayers,
  changeSelectedLayer
} = layerSlice.actions;

export default layerSlice.reducer;
