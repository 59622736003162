import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import GeoLocationPositionTracker from "./GeoLocationPositionTracker";
import { newAngle, newLocation, selectWatch, updateError } from "./geolocateSlice";

export function TrackingService(): null {
  const tracker = useMemo(() => new GeoLocationPositionTracker(true), []);
  const watching = useSelector(selectWatch);
  const dispatch = useDispatch();

  useEffect(() => {
    if (watching) {
      tracker.watchPosition(
        pos =>
          dispatch(
            newLocation({
              accuracy: pos.accuracy,
              altitude: pos.altitude,
              altitudeAccuracy: pos.altitudeAccuracy,
              heading: pos.heading,
              latitude: pos.latitude,
              longitude: pos.longitude,
              speed: pos.speed
            })
          ),
        err =>
          dispatch(
            updateError({
              code: err.code,
              message: err.message,
              PERMISSION_DENIED: err.PERMISSION_DENIED,
              POSITION_UNAVAILABLE: err.POSITION_UNAVAILABLE,
              TIMEOUT: err.TIMEOUT
            })
          )
      );
      tracker.watchOrientation(angle => dispatch(newAngle(angle)));
    } else {
      tracker.stopWatchingGeolocation();
      tracker.stopWatchingOrientation();
      dispatch(newAngle(null));
    }
  }, [watching, dispatch, tracker]);

  return null;
}
