import React, { ReactElement } from "react";
import classnames from "classnames";
import "./ModalOverlay.scss";

export enum Palettes {
  dark = "dark",
  light = "light",
  transparent = "transparent"
}

export type PalettesType = keyof typeof Palettes;

interface ModalOverlayProps {
  className?: string;
  onClick?(): void;
  palette?: PalettesType;
}

export default function ModalOverlay(props: ModalOverlayProps): ReactElement {
  // Props
  const { className: classNameProp, onClick: onClickProp, palette } = props;

  // Computed
  const className = classnames(
    "uq-modal-overlay",
    { [`uq-modal-overlay--${palette}`]: palette === Palettes.dark || palette === Palettes.light },
    classNameProp
  );

  // Events
  const onClick = (): void => {
    onClickProp?.();
  };

  return <div className={className} onClick={onClick} />;
}
