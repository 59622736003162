import { configureStore, ThunkAction, Action, AnyAction, getDefaultMiddleware } from "@reduxjs/toolkit";
import searchReducer from "../store/search/searchSlice";
import mapReducer from "../store/map/mapSlice";
import layerReducer, {
  replaceLayer,
  _LAYER_WELL_LIT,
  _LAYER_ACCESSIBLE
} from "../store/layers/layerSlice";
import geolocatorReducer from "../features/tracking/geolocateSlice";
import wayFindingReducer from "../store/wayFinding/wayFindingSlice";
import settingsReducer, { SettingsState } from "../features/settings/settingsSlice";
import liveDataReducer, { updateLiveData } from "../features/live/liveDataSlice";
import liveDataProviderFactory from "../services/LiveDataProviderFactory";
import colours from "../colours";
import { Busyness } from "../services/Busyness";
import { SafetyAndSecurity } from "../services/SafetyAndSecurity";
import { mapPersistconfig } from "./persistReducerConfig";
import { MapState } from "./models/MapState";
import { persistReducer } from "redux-persist";
import { TypedUseSelectorHook, useSelector } from "react-redux";

import { Layer } from "./models/Layer";
import { LiveDataCategoryType } from "../services/LiveDataCategoryType";

export const store = configureStore({
  reducer: {
    search: searchReducer,
    map: persistReducer<MapState, AnyAction>(mapPersistconfig, mapReducer),
    geolocator: geolocatorReducer,
    layers: layerReducer,
    wayFinding: wayFindingReducer,
    settings: settingsReducer,
    live: liveDataReducer
  },
  middleware: getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false
    // serializableCheck: {
    //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    // },
  })
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type AppDispatch = typeof store.dispatch;

let scheduled: number | undefined = undefined;
let scheduledCampusId = "";
const liveDataCheck = async (settings: SettingsState): Promise<void> => {
  if (settings.siteFeatures.liveParking) {
    const data = await liveDataProviderFactory.getProvider(LiveDataCategoryType.PARKING);
    if (data) store.dispatch(updateLiveData(data));
  }
  if (settings.siteFeatures.liveBuilding) {
    const data = await liveDataProviderFactory.getProvider(LiveDataCategoryType.BUILDINGS);
    if (data) store.dispatch(updateLiveData(data));
  }

  if (settings.siteFeatures.liveComputers) {
    const data = await liveDataProviderFactory.getProvider(LiveDataCategoryType.COMPUTERS);
    if (data) store.dispatch(updateLiveData(data));
  }

  const campusId = store.getState().map.campusId;
  if (settings.siteFeatures.busyness && campusId) {
    new Busyness()
      .getHeatmapData(campusId)
      .then(result => {
        const newLayer: Layer = {
          includeInMapControl: true,
          name: "Busyness",
          type: "heatmap",
          hidden: true,
          layerDetails: result
        };
        store.dispatch(replaceLayer(newLayer));
      })
      .catch(ex => {
        console.info(ex);
      });
  }

  if (campusId) {
    new SafetyAndSecurity().getSafetyPaths().then(result => {
      const newLayer: Layer = {
        includeInMapControl: true,
        name: _LAYER_WELL_LIT,
        type: "paths",
        hidden: true,
        layerDetails: {
          path: result,
          linePaint: { "line-color": colours.safetyPath, "line-width": 6, "line-dasharray": [1, 1] },
          lineLayout: {}
        }
      };
      store.dispatch(replaceLayer(newLayer));
    });
  }

  if (campusId) {
    new SafetyAndSecurity().getAccessiblePaths().then(result => {
      const newLayer: Layer = {
        includeInMapControl: true,
        name: _LAYER_ACCESSIBLE,
        type: "paths",
        hidden: true,
        layerDetails: {
          path: result,
          linePaint: { "line-color": colours.accessiblePath, "line-width": 6, "line-dasharray": [1, 1] },
          lineLayout: {}
        }
      };
      store.dispatch(replaceLayer(newLayer));
    });
  }
};

const TIMER_INTERVAL = 60000;
store.subscribe(() => {
  const state = store.getState();
  const notYetScheduled = !scheduled;
  const scheduledForDifferentCampus = scheduled && state.map.campusId !== scheduledCampusId;
  if (state.map.hasProcessedUrl && state.map.campusId && (notYetScheduled || scheduledForDifferentCampus)) {
    liveDataCheck(state.settings);
    scheduledCampusId = state.map.campusId;
    if (scheduled) clearInterval(scheduled);
    scheduled = window.setInterval(() => {
      const settings = store.getState().settings;
      return liveDataCheck(settings);
    }, TIMER_INTERVAL);
  }
});

export const useRootSelector: TypedUseSelectorHook<RootState> = useSelector;
