import PIN_CONTAINER from "../../assets/Icons/Pin-Container.svg";
import BUILDING_EXIT from "../../assets/wayfinding/bold_green_wayfinding-building-exit.svg";
import BUILDING_ENTER from "../../assets/wayfinding/bold_green_wayfinding-building-enter.svg";
import STAIR_DOWN from "../../assets/wayfinding/bold_green_wayfinding-stairs-descend.svg";
import STAIR_UP from "../../assets/wayfinding/bold_green_wayfinding-stairs-ascend.svg";
import ELEVATOR from "../../assets/wayfinding/bold_green_wayfinding-elevator.svg";
import TRANSIT from "../../assets/wayfinding/bold_green_wayfinding-transit.svg";
import CAR from "../../assets/wayfinding/bold_green_wayfinding-car.svg";
import PEDESTRIAN from "../../assets/wayfinding/bold_green_wayfinding-walk.svg";
import ROUTE_END from "../../assets/wayfinding/route-end.svg";
import { MapEx } from "MazeMapTypes";
import { loadImage } from "../../services/typeIcons";

export const routeFeatureIconPrefix = "routefeature-";

export const routeFeatureIcons: { [routepoint: string]: string } = {
  "ROUTE-END": ROUTE_END,
  "PIN-CONTAINER": PIN_CONTAINER,
  BUILDING_EXIT: BUILDING_EXIT,
  STAIR: STAIR_UP,
  BUILDING_ENTER: BUILDING_ENTER,
  "STAIR-DOWN": STAIR_DOWN,
  "STAIR-UP": STAIR_UP,
  "ELEVATOR-DOWN": ELEVATOR,
  "ELEVATOR-UP": ELEVATOR,
  TRANSIT: TRANSIT,
  PEDESTRIAN: PEDESTRIAN,
  DRIVE: CAR
  // "NO_HC"
};

export const ensureRouteFeatureIconsAddedToMap = (map: MapEx): Promise<unknown> => {
  return Promise.all(Object.keys(routeFeatureIcons).map(key => {
    const imageKey = routeFeatureIconPrefix + key;
    const element = routeFeatureIcons[key];
    return loadImage(map, imageKey, element);
  }));
};
