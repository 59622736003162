import React, { ReactElement } from "react";
import styles from "./itemDescriptionMobile.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import {
  deselectSearchResult,
  SEARCH_RESULTS_LAYER_NAME,
  toggleMobileItemDetails
} from "../../../store/search/searchSlice";
import { SEARCH_BACK } from "../../../constants";
import ItemDescriptionTitle from "../itemDescriptionTitle";
import Modal from "../../../components/Modal";
import ItemDescriptionButtons from "../itemDescriptionButtons";
import ItemDescriptionDesktop from "../itemDescriptionDesktop";
import IconButton from "../../../components/IconButton";
import { closeItemDetails } from "../../../store/map/mapSlice";
import { dropPinLayerName } from "../../map/dropPin";
import { closeItemDetailsWayfinding } from "../../../store/wayFinding/wayFindingSlice";
import { removeLayer } from "../../../store/layers/layerSlice";
import { ReactComponent as ArrowBack } from "../../../assets/Icons/arrow-left.svg";

interface SimpleButtonProps {
  onClick(): void;
}

interface ItemDescriptionMobileProps {
  setPanelOpen(value: boolean): void;
}

function ArrowBackButton ({ onClick }: SimpleButtonProps): ReactElement {
  return (
    <IconButton icon={<ArrowBack />} ariaLabel={SEARCH_BACK} className={styles.arrowLeftIcon} onClick={onClick} />
  );
}

export default function ItemDescriptionMobile(props: ItemDescriptionMobileProps): ReactElement {
  const dispatch = useDispatch();
  const { setPanelOpen: setPanelOpenProps } = props;
  const itemDetail = useSelector((state: RootState) => state.map.itemDetail);
  const showMobileItemDetails = useSelector((state: RootState) => state.search.showMobileItemDetails);

  const handleCloseDetails = (): void => {
    dispatch(closeItemDetails());
    dispatch(closeItemDetailsWayfinding());
    dispatch(deselectSearchResult());
    dispatch(removeLayer(dropPinLayerName));
    dispatch(removeLayer(SEARCH_RESULTS_LAYER_NAME));
  };

  return (
    <Modal
      show={Boolean(true)}
      onClose={handleCloseDetails}
      portalContainer={document.getElementById("root")}
      showOverlay={false}
      className={showMobileItemDetails ? styles.itemDescriptionModal : styles.itemDescriptionFullHeightModal}
      classNameCloseButton={showMobileItemDetails ? styles.modalCloseRoute : styles.hidden}
    >
      {!showMobileItemDetails && (
        <ArrowBackButton
          onClick={(): void => {
            dispatch(toggleMobileItemDetails(!showMobileItemDetails));
          }}
        />
      )}
      <em
        className={styles.expandButton}
        onClick={(): void => {
          dispatch(toggleMobileItemDetails(!showMobileItemDetails));
        }}
      />
      <div className={showMobileItemDetails ? styles.pointSelectionModalBody : ""}>
        {showMobileItemDetails ? (
          <>
            <ItemDescriptionTitle itemDetail={itemDetail} />
            <ItemDescriptionButtons
              itemDetail={itemDetail}
              setPanelOpen={(value: boolean): void => setPanelOpenProps(value)}
            />
          </>
        ) : (
          <></>
        )}
        {!showMobileItemDetails ? (
          <ItemDescriptionDesktop setPanelOpen={(value: boolean): void => setPanelOpenProps(value)} />
        ) : (
          <></>
        )}
      </div>
    </Modal>
  );
}
