import React, { ReactElement } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch, bindActionCreators } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import SelectableLayer from "./SelectableLayer";
import { MapEx } from "MazeMapTypes";
import { RouteLayer } from "./RouteLayer";
import {
  SelectableLayerDetails,
  RouteLayerDetails,
  PathLayerDetails,
  HeatmapLayerDetails,
  PolygonHeatmapLayerDetails,
  Layer
} from "../../app/models/Layer";
import { PathsLayer } from "./PathsLayer";
import { HeatmapLayer } from "./HeatmapLayer";
import { PolygonHeatmapLayer } from "./PolygonHeatmapLayer";

interface LayersProps extends PropsFromRedux {
  map: MapEx;
}

function Layers(props: LayersProps): ReactElement {
  const { layers, map, zLevel, selectedLayer, use3DBusyness } = props;
  const mapZLevel = !zLevel || isNaN(zLevel) || zLevel < 0 ? 0 : zLevel; //Map level 0 on UI is -1?

  return <>{layers.map(item => {
    const userHasChosenTheLayer = item.includeInMapControl && item.name === selectedLayer;
    const showTheLayer = userHasChosenTheLayer || Boolean(!item.hidden);

    switch (item.type) {
      case "selectable": {
        const details = item.layerDetails as SelectableLayerDetails;
        return (
          <SelectableLayer
            map={map}
            key={item.name}
            name={item.name}
            {...details}
            visible={showTheLayer}
          />
        );
      }
      case "route": {
        const rDetails = item.layerDetails as RouteLayerDetails;
        return (
          rDetails.route && (
            <RouteLayer
              map={map}
              key={item.name}
              layerName={item.name}
              geojson={rDetails.route}
              visible={showTheLayer}
              mapzLevel={mapZLevel}
            />
          )
        );
      }
      case "paths": {
        const pDetails = item.layerDetails as PathLayerDetails;
        return (
          <PathsLayer
            map={map}
            key={item.name}
            layerName={item.name}
            geojson={pDetails.path}
            visible={showTheLayer}
            linePaint={pDetails.linePaint}
            lineLayout={pDetails.lineLayout}
            minZoom={14}
          />
        );
      }
      case "heatmap": {
        const hDetails = item.layerDetails as HeatmapLayerDetails;
        return (
          <HeatmapLayer
            map={map}
            key={item.name}
            layerName={item.name}
            geojson={hDetails.heat}
            visible={showTheLayer}
            minZoom={0}
            maxZoom={24}
            mapzLevel={mapZLevel}
            maximumValue={hDetails.maximumValue}
          />
        );
      }
      case "polygonheatmap": {
        const phDetails = item.layerDetails as PolygonHeatmapLayerDetails;
        return (
          <PolygonHeatmapLayer
            map={map}
            key={item.name}
            layerName={item.name}
            geojson={phDetails.heat}
            visible={showTheLayer}
            minZoom={12}
            maxZoom={24}
            mapzLevel={mapZLevel}
            use3d={use3DBusyness}
            maxValue={phDetails.maximumValue}
          />
        );
      }
      default:
        return null;
    }
  })}</>;
}

const mapStateToProps = (
  state: RootState
): {
  layers: Layer[];
  zLevel: number;
  selectedLayer: string;
  use3DBusyness: boolean;
} => {
  return {
    layers: state.layers.layers,
    zLevel: state.map.zLevel,
    selectedLayer: state.layers.selectedLayer,
    use3DBusyness: state.settings.siteFeatures.busyness3D
  };
};

function mapDispatchToProps(dispatch: Dispatch): {
  dispatch: Dispatch;
} {
  return {
    dispatch,
    ...bindActionCreators({}, dispatch)
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Layers);

type PropsFromRedux = ConnectedProps<typeof connector>;
