import { HeatmapData, PolygonHeatData } from "../util/types";
import Api from "./ApiService";

export class Busyness {
  public async getHeatmapData(campusId: string): Promise<HeatmapData> {
    return await Api.getBusynessPoints(campusId);
  }

  public async getHeatPolygons(campusId: string): Promise<PolygonHeatData> {
    return await Api.getBusynessPolygons(campusId);
  }
}
