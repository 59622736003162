import { LineString } from "geojson";
import Api from "./ApiService";

export class SafetyAndSecurity {
  public async getSafetyPaths(): Promise<GeoJSON.FeatureCollection<LineString>> {
    const category = await Api.getSafePath();
    if (category) {
      return category;
    }
    console.log("Safe path data unavailable");
    return { type: "FeatureCollection", features: [] };
  }

  public async getAccessiblePaths(): Promise<GeoJSON.FeatureCollection<LineString>> {
    const category = await Api.getAccessiblePath();
    if (category) {
      return category;
    }
    console.log("Accessible path data unavailable");
    return { type: "FeatureCollection", features: [] };
  }
}
