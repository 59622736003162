import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/Modal";
import ModalHeader from "../../components/Modal/ModalHeader";
import { _LAYER_WELL_LIT, _LAYER_ACCESSIBLE, changeSelectedLayer, defaultLayerName } from "../../store/layers/layerSlice";
import {
  DEFAULT_MAP_STYLE,
  SAFEPATH_MAP_STYLE,
  closeModal,
  setStyle
} from "../../store/map/mapSlice";
import { RootState } from "../../app/store";
import { AnalyticsHandler } from "../../services/analytics/google-analytics/AnalyticsHandler";
import { AnalyticsOptionsImpl } from "../../services/analytics/google-analytics/AnalyticsOptions";
import styles from "./LayersSelectionModal.module.scss";
import { ModalType } from "../../app/models/MapState";

export default function LayersSelectionModal(): ReactElement {
  const dispatch = useDispatch();

  // Global State
  const layersState = useSelector((state: RootState) => state.layers);
  const show = useSelector((state: RootState) => state.map.modal === ModalType.LAYERS);
  // Local State
  const [focusedLayer, setFocusedLayer] = useState("");

  // Computed
  const layers = layersState.layers.filter(l => l.includeInMapControl);
  const { selectedLayer } = layersState;

  // Events
  function onFocus(layerName: string): void {
    setFocusedLayer(layerName);
  }

  const onBlur = (): void => {
    setFocusedLayer("");
  };

  const onChange = useCallback(
    (layerName: string): void => {
      let mapStyle = DEFAULT_MAP_STYLE;
      if (layerName === _LAYER_WELL_LIT) {
        AnalyticsHandler.handleEvent(
          new AnalyticsOptionsImpl("Custom click", "LayerSelection", "Click", _LAYER_WELL_LIT)
        );
        mapStyle = SAFEPATH_MAP_STYLE;
      }

      dispatch(setStyle(mapStyle));
      dispatch(changeSelectedLayer(layerName));
      dispatch(closeModal());
    },
    [dispatch]
  );

  useEffect(() => {
    function onKeyDown(event: KeyboardEvent): void {
      event.key === "Enter" && focusedLayer && onChange(focusedLayer);
    }

    document.addEventListener("keydown", onKeyDown);
    return (): void => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [focusedLayer, onChange]);

  const radioButton = (layerName: string): ReactElement => (
    <div
      className={styles.mapLayerRadioLine}
      onFocus={(): void => onFocus(layerName)}
      onBlur={onBlur}
      tabIndex={0}
      key={layerName}
    >
      <input
        id={layerName}
        type={"radio"}
        checked={selectedLayer === layerName}
        onChange={(): void => onChange(layerName)}
        tabIndex={-1}
      />
      <label htmlFor={layerName}>{layerName}</label>
    </div>
  );

  return (
    <Modal
      show={show}
      onClose={(): unknown => dispatch(closeModal())}
      portalContainer={document.getElementById("root")}
      overlayPalette={"light"}
      className={styles.mapLayerModal}
    >
      <ModalHeader borderBottom>{"Map Layers"}</ModalHeader>
      <div className={styles.mapLayerModalBody}>
        {radioButton(defaultLayerName)}
        {layers.map(layer => radioButton(layer.name))}
      </div>
    </Modal>
  );
}
