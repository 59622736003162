import { AppThunk } from "../../app/store";
import { GeoJsonUtil } from "../../services/GeoJsonUtil";
import { pushLayer, removeLayer } from "../../store/layers/layerSlice";
import { setItemDetails } from "../../store/map/mapSlice";
import { endSelection, setSelectedPoint, selectedLocation } from "../../store/wayFinding/wayFindingSlice";
import { PoiUtil } from "../../services/PoiUtil";
import { AnalyticsOptionsImpl } from "../../services/analytics/google-analytics/AnalyticsOptions";
import { AnalyticsHandler } from "../../services/analytics/google-analytics/AnalyticsHandler";
import { PointOfInterest } from "../../types/UQMapsTypes";
import { SEARCH_RESULTS_LAYER_NAME, toggleMobileItemDetails } from "../../store/search/searchSlice";

export const dropPinLayerName = "DropPinItem";
export const setDropPin = (dropPin?: PointOfInterest): AppThunk<void> =>
  (dispatch, getState): void => {
    const wfState = getState().wayFinding;

    if (wfState.isWayFinding && dropPin) {
      dispatch(setSelectedPoint(PoiUtil.toItemDetail(dropPin)));
    }

    if (wfState.isChoosingPointOnTheMap) {
      if (dropPin) {
        dispatch(
          selectedLocation(
            PoiUtil.toItemDetail(dropPin),
            wfState.isSelectingOrigin ? "origin" : "destination"
          )
        );
      }
      dispatch(endSelection());
      return;
    }
    if (dropPin) {
      const feature = GeoJsonUtil.featureToPoint(dropPin);

      // attempt to parse JSON-encoded version if present, in case
      // passing through MapBox data sources has mangled the data.
      if (feature.properties.propertiesJson) {
        try {
          feature.properties = JSON.parse(feature.properties.propertiesJson);
        } catch (e) {
          console.error("unable to parse propertiesJson from", feature, e);
          // then fallback to original feature.properties
        }
      }

      let poiIdStr = "N/A";
      if (dropPin.properties.id) {
        poiIdStr = dropPin.properties.id.toString();
      }
      AnalyticsHandler.handleEvent(
        new AnalyticsOptionsImpl("Custom click", "DropPin", "Click", poiIdStr)
      );

      // remove any current dropPin Layer, only one shown at any other time
      dispatch(removeLayer(dropPinLayerName));
      dispatch(removeLayer(SEARCH_RESULTS_LAYER_NAME));

      dispatch(
        pushLayer({
          name: dropPinLayerName,
          type: "selectable",
          layerDetails: {
            cluster: false,
            items: [feature]
          },
          includeInMapControl: false
        })
      );
      dispatch(setItemDetails(dropPin));
      dispatch(toggleMobileItemDetails(true));
    } else {
      dispatch(removeLayer(dropPinLayerName));
    }
  };
