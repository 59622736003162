import { useEffect } from "react";

interface RefObject {
  readonly current: HTMLElement | null;
}

export default function useOnClickOutside(ref: RefObject | null, handler: (event: UIEvent) => void): void {
  useEffect(() => {
    function listener(event: UIEvent): void {
      const htmlElement = ref?.current;

      if (!htmlElement) return;

      if ((event as KeyboardEvent).key === "Escape") {
        handler(event);
        return;
      }

      // Do nothing if clicking ref's element or descendent elements
      if (htmlElement.contains(event.target as Node)) {
        return;
      }

      handler(event);
    }
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    document.addEventListener("keydown", listener);
    return (): void => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
      document.removeEventListener("keydown", listener);
    };
  }, [ref, handler]);
}
